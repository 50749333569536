import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PrimaryInput } from '../Input';
import { Category, Document, Notebook } from '../../types';
import { PrimaryDropdown } from '../Input/dropdown';
import { CategoryButton } from '../button';
import { fetchApi } from '../../utils/backendApi';
import {
  draftedDocumentErrorMessage,
  draftedDocumentSuccessMessage,
  sharedDocumentSuccessMessage,
  shareDocumentErrorMessage,
} from '../../constant';

const shareDurations = [1, 2, 3, 5, 9, 12];

const ShareDocumentPanel = () => {
  const [toWho, setToWho] = useState<string>('');
  const [documentIndex, setDocumentIndex] = useState<number>(0);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [notebookIndex, setNotebookIndex] = useState<number>(0);
  const [notebooks, setNotebooks] = useState<Notebook[]>([]);
  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [permanentShared, setPermanentShared] = useState<boolean>(false);
  const [shareDuration, setShareDuration] = useState<number>(0);

  const fetchDocuments = () => {
    const call = async () => {
      const res = await fetchApi(
        `document?notebookId=${notebooks[notebookIndex].id}&categoryId=${categories[categoryIndex].id}`,
        'GET',
        undefined,
        true,
      );
      setDocuments(res?.documents);
    };
    call();
  };
  const fetchNotebooks = () => {
    const call = async () => {
      const res = await fetchApi('notebook', 'GET', undefined, true);
      setNotebooks(res.notebooks);
    };
    call();
  };
  const fetchCategories = () => {
    const call = async () => {
      const res = await fetchApi('category', 'GET', undefined, true);
      setCategories(res.categories);
    };
    call();
  };
  const shareDocument = (isDraft: boolean) => {
    const duration = permanentShared ? -1 : shareDuration;
    const call = async () => {
      const res = await fetchApi(
        'share',
        'PUT',
        {
          documentId: documents[documentIndex].id,
          to: toWho,
          duration,
          isDraft,
        },
        true,
      );
      if (!res) {
        toast.error(
          isDraft ? draftedDocumentErrorMessage : shareDocumentErrorMessage,
        );
        return;
      }
      toast.success(
        isDraft ? draftedDocumentSuccessMessage : sharedDocumentSuccessMessage,
      );
    };
    call();
  };

  useEffect(() => {
    if (notebooks.length !== 0 && categories.length !== 0) fetchDocuments();
  }, [notebookIndex, categoryIndex, notebooks, categories]);
  useEffect(() => {
    fetchNotebooks();
    fetchCategories();
  }, []);

  return (
    <div className="sharePanel">
      <PrimaryInput
        label="Destinataire"
        required
        value={toWho}
        onChange={(value) => setToWho(value.toString())}
      />
      <div className="documentBlock">
        <div className="select">
          <PrimaryDropdown
            label="Carnet"
            values={notebooks.map((notebook) => notebook.address)}
            onSelect={(value) => setNotebookIndex(value)}
          />
          <PrimaryDropdown
            label="Catégorie"
            values={categories.map((category) => category.title)}
            onSelect={(value) => setCategoryIndex(value)}
          />
          <PrimaryDropdown
            label="Document"
            values={documents.map((document) => document.title)}
            onSelect={(value) => setDocumentIndex}
          />
        </div>
        <p style={{ fontSize: '32px', fontWeight: '500' }}>OU</p>
        <div className="download">
          <CategoryButton
            src="./images/svg/uncircled-plus.svg"
            title="Télécharger un fichier"
            onClick={() => {}}
          />
        </div>
      </div>
      <PrimaryInput
        label="Informations complémentaires"
        type="textarea"
        value={additionalInfo}
        onChange={(value) => setAdditionalInfo(value.toString())}
      />
      <div className="duration">
        <div className="col-sm-4 col-xs-12">
          <PrimaryInput
            label="Partage définitif"
            type="checkbox"
            value={permanentShared}
            onChange={(value) => setPermanentShared(!!value)}
          />
        </div>
        <div className="col-sm-4 col-xs-12">
          <PrimaryInput
            label="Temporaire"
            type="checkbox"
            value={!permanentShared}
            onChange={(value) => setPermanentShared(!value)}
          />
        </div>
        <div className="col-sm-4 col-xs-12">
          <PrimaryDropdown
            label="*Durée"
            values={shareDurations.map((item) => `${item.toString()} mois`)}
            onSelect={(value) => setShareDuration(shareDurations[value])}
          />
        </div>
      </div>
      <div className="buttonGroup">
        <div
          className="button"
          role="button"
          onClick={() => shareDocument(false)}
        >
          Valider
        </div>
        <p>OU</p>
        <div
          className="button"
          role="button"
          onClick={() => shareDocument(true)}
        >
          Enregistrer comme brouillon
        </div>
      </div>
    </div>
  );
};

export default ShareDocumentPanel;
