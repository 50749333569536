import { createContext, useState } from 'react';

interface UiProps {
  selectedHouseContentPanel: string;
  selectHouseContentPanel: (value: string) => void;
  selectedConsultBlock: string;
  selectConsultBlock: (value: string) => void;
  currentPath: string;
  defineCurrentPath: (value: string) => void;
}

export const UiContext = createContext<UiProps>({} as UiProps);

const UiProvider = ({ children }: any) => {
  const [selectedHouseContentPanel, setSelectedHouseContentPanel] =
    useState<string>('notebookPanel');
  const [selectedConsultBlock, setSelectedConsultBlock] =
    useState<string>('category');
  const [currentPath, setCurrentPath] = useState<string>('');

  const selectHouseContentPanel = (panel: string) => {
    setSelectedHouseContentPanel(panel);
  };

  const selectConsultBlock = (block: string) => {
    setSelectedConsultBlock(block);
  };

  const defineCurrentPath = (path: string) => {
    setCurrentPath(path);
  };

  return (
    <UiContext.Provider
      value={{
        selectedHouseContentPanel,
        selectedConsultBlock,
        currentPath,
        selectHouseContentPanel,
        selectConsultBlock,
        defineCurrentPath,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

export default UiProvider;
