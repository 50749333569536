import { useContext } from 'react';
import { DataContext } from '../../contexts/data';
import { UiContext } from '../../contexts/ui';

import './index.css';

type NotebookItemProps = {
  id: string;
  address: string;
  lat: number;
  lng: number;
};

const NotebookItem = (props: NotebookItemProps) => {
  const { selectHouseContentPanel, selectConsultBlock, defineCurrentPath } =
    useContext(UiContext);
  const { selectCenter, selectNotebookId, saveGMapTitle } =
    useContext(DataContext);

  return (
    <div className="notebookItem">
      <div
        onClick={() => {
          selectHouseContentPanel('consultPanel');
          selectConsultBlock('category');
          defineCurrentPath(`notebook:${props.address}`);
          selectNotebookId(props.id);
        }}
      >
        <img src="./images/svg/notebook.svg" alt="Notebook SVG" />
        <span>{props.address}</span>
      </div>
      <div>
        <img
          src="./images/svg/pin.svg"
          alt="Google Map Pin SVG"
          onClick={() => {
            selectCenter(props.lat, props.lng);
            saveGMapTitle(props.address);
            selectHouseContentPanel('gMapPanel');
          }}
        />
        <img
          src="./images/svg/send.svg"
          alt="Paper Plane SVG"
          onClick={() => {
            selectHouseContentPanel('shareNotebookPanel');
          }}
        />
      </div>
    </div>
  );
};

export default NotebookItem;
