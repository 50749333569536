import Autocomplete from 'react-google-autocomplete';
import { Location } from '../../types';

import './index.css';

type InputBasicProps = {
  label?: string;
  icon?: string;
  description?: string;
  descriptionType?: 'inform' | 'warn' | 'error';
  disable?: boolean;
  placeholder?: string;
  required: boolean;
  type: string;
  value: string | boolean | File;
  onChange: (value: string | boolean | FileList | Location) => void;
};
type InputAdvancedProps = {
  detailText: string;
} & InputBasicProps;

const defaultProps = {
  required: false,
  type: 'text',
};

export const PrimaryInput = (props: InputBasicProps) => {
  const {
    label,
    icon,
    description,
    descriptionType,
    disable,
    placeholder,
    required,
    type,
    value,
    onChange,
  } = props;

  const inputElements: { [key: string]: any } = {
    textarea: (
      <textarea
        rows={4}
        value={value.toString()}
        onChange={(e) => onChange(e.target.value)}
      />
    ),
    checkbox: (
      <input
        type={type}
        checked={value.toString() === 'true' ? true : false}
        onChange={(e) => onChange(e.target.checked)}
      />
    ),
    file: (
      <input type={type} onChange={(e) => onChange(e.target.files || false)} />
    ),
    googleMapAutoComplete: (
      <Autocomplete
        apiKey="AIzaSyCLpdZCyYL9I4CkYnf-3qGJO5xyQqF_lYg"
        onPlaceSelected={(place) =>
          onChange({
            address: place.formatted_address || '',
            lat: place.geometry?.location?.lat() || 0,
            lng: place.geometry?.location?.lng() || 0,
          })
        }
        options={{
          componentRestrictions: { country: 'fr' },
          types: ['geocode'],
        }}
      />
    ),
    other: (
      <input
        type={type}
        value={value.toString()}
        onChange={(e) => onChange(e.target.value)}
        disabled={disable}
        placeholder={placeholder}
      />
    ),
  };

  return (
    <>
      <div
        className={`input-primary ${type === 'checkbox' ? 'w-content' : ''} ${
          disable ? 'disable' : ''
        }`}
      >
        {label && <span>{label + (required ? '*' : '') + ' :'}</span>}
        {icon && <img src={`${icon}`} alt="Input Icon SVG" />}
        {inputElements[type] ?? inputElements['other']}
      </div>
      {description && (
        <p className={`description ${descriptionType}`}>{description}</p>
      )}
    </>
  );
};

export const NormalInput = (props: InputBasicProps) => {
  const { label, icon, required, type, value, onChange } = props;
  const inputElements: { [key: string]: any } = {
    textarea: (
      <textarea
        rows={4}
        value={value.toString()}
        onChange={(e) => onChange(e.target.value)}
      />
    ),
    checkbox: (
      <input
        type={type}
        checked={value.toString() === 'true' ? true : false}
        onChange={(e) => onChange(e.target.checked)}
      />
    ),
    other: (
      <input
        type={type}
        value={value.toString()}
        onChange={(e) => onChange(e.target.value)}
      />
    ),
  };

  return (
    <div className={`input-normal ${type === 'checkbox' ? 'w-content' : ''}`}>
      {label && <span>{label + (required ? '*' : '') + ' :'}</span>}
      {icon && <img src={`${icon}`} alt="Input Icon SVG" />}
      {inputElements[type] ?? inputElements['other']}
    </div>
  );
};

export const DetailedInput = (props: InputAdvancedProps) => {
  const { label, required, type, value, detailText, onChange } = props;
  const inputElements: { [key: string]: any } = {
    textarea: (
      <textarea
        rows={4}
        value={value.toString()}
        onChange={(e) => onChange(e.target.value)}
      />
    ),
    checkbox: (
      <input
        type={type}
        checked={value.toString() === 'true' ? true : false}
        onChange={(e) => onChange(e.target.checked)}
      />
    ),
    other: (
      <input
        type={type}
        value={value.toString()}
        onChange={(e) => onChange(e.target.value)}
      />
    ),
  };

  return (
    <div className="d-flex flex-column input-detailed">
      {label && <span>{label + (required ? '*' : '') + ' :'}</span>}
      {inputElements[type] ?? inputElements['other']}
      {detailText && <span>{detailText}</span>}
    </div>
  );
};

export const SearchInput = () => {
  return (
    <div className="input-search">
      <img src="./images/svg/search-icon.svg" alt="Search Icon SVG" />
      <input type="search" placeholder="Rechercher dans Blocksto" />
    </div>
  );
};

PrimaryInput.defaultProps = defaultProps;
NormalInput.defaultProps = defaultProps;
DetailedInput.defaultProps = defaultProps;
