import { useEffect, useState } from 'react';
import './index.css';

type DropdownBasicProps = {
  label?: string;
  values: string[];
  disable?: boolean;
  onSelect: (index: number) => void;
};

export const PrimaryDropdown = (props: DropdownBasicProps) => {
  const { label, values, onSelect } = props;
  const [isDown, setIsDown] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    setIsDown(false);
  }, [props.disable]);

  return (
    <div className={`primary-dropdown ${props.disable ? 'disable' : ''}`}>
      <div className="dropdown-header">
        <span>{`${label} :`}</span>
        <span>
          {values.length === 0 ? 'No Data to Display' : values[selected]}
        </span>
        <img
          src="./images/svg/dropdown.svg"
          alt="Drop Icon"
          onClick={() => setIsDown(!isDown)}
        />
      </div>
      {!props.disable && isDown && (
        <div className="dropdown-content">
          {values.map((value, index) => (
            <p
              key={`dropdown-${index}`}
              onClick={() => {
                setSelected(index);
                setIsDown(false);
                onSelect(index);
              }}
            >
              {value}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
