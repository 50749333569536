import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../../contexts/data';
import { UiContext } from '../../../contexts/ui';
import { Category } from '../../../types';
import { fetchApi } from '../../../utils/backendApi';
import { CategoryButton } from '../../button';

import '../styles/consult.css';

const CategoryConsultBlock = () => {
  const { currentPath, selectConsultBlock, defineCurrentPath } =
    useContext(UiContext);
  const { selectedNotebookId, selectNotebook, selectCategoryId } =
    useContext(DataContext);
  const [categories, setCategories] = useState<{ id: string; title: string }[]>(
    [],
  );

  const handleClick = (category: Category) => {
    switch (category.id) {
      case 'general':
        selectConsultBlock('generalInfo');
        defineCurrentPath(`${currentPath}/category:${category.title}`);
        const fetchGeneralInfo = async () => {
          const data = await fetchApi(
            `notebook/${selectedNotebookId}`,
            'GET',
            undefined,
            true,
          );
          selectNotebook({
            ...data.notebook,
            constructDate: new Date(data.notebook.constructDate),
          });
        };
        fetchGeneralInfo();
        break;
      case 'add':
        console.log('add');
        break;
      default:
        selectConsultBlock('document');
        defineCurrentPath(`${currentPath}/category:${category.title}`);
        selectCategoryId(category.id);
        break;
    }
  };

  useEffect(() => {
    const call = async () => {
      const categoriesData = await fetchApi('category', 'GET', undefined, true);
      setCategories([
        { id: 'general', title: 'Informations générales' },
        ...categoriesData.categories,
      ]);
    };
    call();
  }, []);

  return (
    <div className="categoryBlock">
      {categories.map((category, index) => (
        <CategoryButton
          title={category.title}
          key={`category-${index}`}
          onClick={() => handleClick(category)}
        />
      ))}
      <CategoryButton
        src="./images/svg/uncircled-plus.svg"
        title="Créer une nouvelle catégorie"
        onClick={() => {
          handleClick({ id: 'add', title: 'Add' });
        }}
      />
    </div>
  );
};

export default CategoryConsultBlock;
