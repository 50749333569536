import { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { AccordionItem } from '../../types';

import './index.css';

type AccordionProps = {
  data: AccordionItem[];
};

const Accordion = ({ data }: AccordionProps) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);

  const openAnimation = useSpring({
    from: { opacity: '1', maxHeight: '60px' },
    to: { opactity: '1', maxHeight: '100%' },
    config: { duration: 300 },
  });
  const closeAnimation = useSpring({
    from: { opacity: '1', maxHeight: '60px' },
    to: { opactity: '1', maxHeight: '60px' },
    config: { duration: 300 },
  });
  const rotateForwardAnimation = useSpring({
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(180deg)',
    },
    config: { duration: 120 },
  });
  const rotateBackwardAnimation = useSpring({
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
    config: { duration: 120 },
  });

  const handleAccordionItemClick = (index: number) => {
    setSelectedItemIndex(index === selectedItemIndex ? -1 : index);
  };

  return (
    <div className="accordionGroup">
      {data.map((item, index) => (
        <animated.div
          className="item"
          key={`item-${index}`}
          style={index === selectedItemIndex ? openAnimation : closeAnimation}
        >
          <div
            className="title"
            onClick={() => handleAccordionItemClick(index)}
          >
            <span>{item.title}</span>
            <animated.img
              src="./images/svg/dropdown.svg"
              style={
                index === selectedItemIndex
                  ? rotateForwardAnimation
                  : rotateBackwardAnimation
              }
            />
          </div>
          <div className="content">{item.content}</div>
        </animated.div>
      ))}
    </div>
  );
};

export default Accordion;
