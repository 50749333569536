import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Share } from '../../../types';
import { fetchApi } from '../../../utils/backendApi';
import Table from '../../table';

const SentSharedBlock = () => {
  const [data, setData] = useState<Share[]>([]);
  const columns = [
    { id: 'title', name: 'Nom du fichier', width: 35 },
    { id: 'to', name: 'À', width: 25 },
    { id: 'date', name: 'Date', width: 25 },
    { id: 'actions', name: 'Actions', width: 15 },
  ];
  const actions = [
    { id: 'info', func: (id: string) => {} },
    {
      id: 'delete',
      func: (id: string) => {
        const call = async () => {
          let result = await fetchApi(`share/${id}`, 'DELETE', undefined, true);
          if (!result.removedShare) {
            console.log('Remove Share Failed!');
            return;
          }
          result = await fetchApi(`share/sents`, 'GET', undefined, true);
          const sentData: [] = result.sents;

          const sentModifiedData: Share[] = modifyData(sentData);
          setData(sentModifiedData);
        };
        call();
      },
    },
  ];

  const modifyData = (sentData: any[]): Share[] => {
    return sentData.map((sent) => ({
      id: _.get(sent, 'id'),
      type: _.get(sent, 'document.type'),
      title: _.get(sent, 'document.filename'),
      to: `${_.get(sent, 'to.firstname')} ${_.get(sent, 'to.lastname')}`,
      from: `${_.get(sent, 'from.firstname')} ${_.get(sent, 'from.lastname')}`,
      date: _.toString(_.get(sent, 'sharedDate')).substring(0, 10),
      additionalInfo: _.get(sent, 'addtionalInfo'),
      documentId: _.get(sent, 'document.id'),
    }));
  };

  useEffect(() => {
    const call = async () => {
      let res = await fetchApi(`share/sents`, 'GET', undefined, true);
      let sentData: [] = res.sents;

      const sentDocumentData: Share[] = sentData.map((sent) => ({
        id: _.get(sent, 'id'),
        type: _.get(sent, 'document.type'),
        title: _.get(sent, 'document.filename'),
        to: `${_.get(sent, 'to.firstname')} ${_.get(sent, 'to.lastname')}`,
        from: `${_.get(sent, 'from.firstname')} ${_.get(
          sent,
          'from.lastname',
        )}`,
        date: _.toString(_.get(sent, 'sharedDate')).substring(0, 10),
        additionalInfo: _.get(sent, 'addtionalInfo'),
        documentId: _.get(sent, 'document.id'),
      }));

      res = await fetchApi(`sharenotebook/sents`, 'GET', undefined, true);
      sentData = res.sents;

      const sentNotebookData: Share[] = sentData.map((sent) => ({
        id: _.get(sent, 'id'),
        type: 'notebook',
        title: _.get(sent, 'notebook.address'),
        to: `${_.get(sent, 'to.firstname')} ${_.get(sent, 'to.lastname')}`,
        from: `${_.get(sent, 'from.firstname')} ${_.get(
          sent,
          'from.lastname',
        )}`,
        date: _.toString(_.get(sent, 'sharedDate')).substring(0, 10),
        additionalInfo: _.get(sent, 'addtionalInfo'),
        documentId: _.get(sent, 'notebook.id'),
      }));

      setData([...sentDocumentData, ...sentNotebookData]);
    };
    call();
  }, []);

  return (
    <div className="sentBlock">
      <Table
        forWhat="sent"
        data={data}
        columns={columns}
        actions={actions}
        additionalShowId=""
      />
    </div>
  );
};

export default SentSharedBlock;
