import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  sharedNotebookSuccessMessage,
  shareNotebookErrorMessage,
} from '../../constant';
import { Notebook } from '../../types';
import { fetchApi } from '../../utils/backendApi';
import { PrimaryInput } from '../Input';
import { PrimaryDropdown } from '../Input/dropdown';

const ShareNotebookPanel = () => {
  const shareDurations = [1, 2, 3, 5, 9, 12];

  const [toWho, setToWho] = useState<string>('');
  const [notebookIndex, setNotebookIndex] = useState<number>(0);
  const [notebooks, setNotebooks] = useState<Notebook[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [permanentShared, setPermanentShared] = useState<boolean>(false);
  const [shareDuration, setShareDuration] = useState<number>(0);

  const fetchNotebooks = () => {
    const call = async () => {
      const res = await fetchApi('notebook', 'GET', undefined, true);
      setNotebooks(res.notebooks);
    };
    call();
  };
  const shareNotebook = () => {
    const duration = permanentShared ? -1 : shareDuration;
    const call = async () => {
      const res = await fetchApi(
        'sharenotebook',
        'POST',
        {
          notebookId: notebooks[notebookIndex].id,
          to: toWho,
          duration,
          additionalInfo,
        },
        true,
      );
      if (!res) {
        toast.error(shareNotebookErrorMessage);
        return;
      }
      toast.success(sharedNotebookSuccessMessage);
    };
    call();
  };

  useEffect(() => {
    fetchNotebooks();
  }, []);

  return (
    <div className="sharePanel">
      <PrimaryDropdown
        label="Carnet"
        values={notebooks.map((notebook) => notebook.address)}
        onSelect={(value) => setNotebookIndex(value)}
      />
      <PrimaryInput
        label="Destinataire"
        placeholder="Email du destinataire"
        required
        value={toWho}
        onChange={(value) => setToWho(value.toString())}
      />
      <PrimaryInput
        label="Informations complémentaires"
        type="textarea"
        value={additionalInfo}
        onChange={(value) => setAdditionalInfo(value.toString())}
      />
      <div className="duration">
        <div className="col-sm-4 col-xs-12">
          <PrimaryInput
            label="Partage définitif"
            type="checkbox"
            value={permanentShared}
            onChange={(value) => setPermanentShared(!!value)}
          />
        </div>
        <div className="col-sm-4 col-xs-12">
          <PrimaryInput
            label="Temporaire"
            type="checkbox"
            value={!permanentShared}
            onChange={(value) => setPermanentShared(!value)}
          />
        </div>
        <div className="col-sm-4 col-xs-12">
          <PrimaryDropdown
            label="*Durée"
            values={shareDurations.map((item) => `${item.toString()} mois`)}
            onSelect={(value) => setShareDuration(shareDurations[value])}
          />
        </div>
      </div>
      <div className="buttonGroup">
        <div className="button" role="button" onClick={() => shareNotebook()}>
          Valider
        </div>
      </div>
    </div>
  );
};

export default ShareNotebookPanel;
