import { createContext, useState } from 'react';
import { Notebook, NotebookInput } from '../types';

interface DataProps {
  notebookInput: NotebookInput | null;
  saveNotebookInput: (value: NotebookInput) => void;
  center: { lat: number; lng: number };
  selectCenter: (lat: number, lng: number) => void;
  selectedNotebookId: string;
  selectNotebookId: (value: string) => void;
  selectedNotebook: Notebook | null;
  selectNotebook: (value: Notebook) => void;
  selectedCategoryId: string;
  selectCategoryId: (value: string) => void;
  gMapTitle: string;
  saveGMapTitle: (value: string) => void;
}

export const DataContext = createContext<DataProps>({} as DataProps);

const DataProvider = ({ children }: any) => {
  const [notebookInput, setNotebookInput] = useState<NotebookInput | null>(
    null,
  );
  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [selectedNotebookId, setSelectedNotebookId] = useState<string>('');
  const [selectedNotebook, setSelectedNotebook] = useState<Notebook | null>(
    null,
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
  const [gMapTitle, setGMapTitle] = useState<string>('');

  const saveNotebookInput = (notebookInfo: NotebookInput) => {
    setNotebookInput(notebookInfo);
  };

  const selectCenter = (lat: number, lng: number) => {
    setCenter({ lat, lng });
  };

  const selectNotebookId = (notebookId: string) => {
    setSelectedNotebookId(notebookId);
  };

  const selectNotebook = (notebook: Notebook) => {
    setSelectedNotebook({ ...notebook });
  };

  const selectCategoryId = (categoryId: string) => {
    setSelectedCategoryId(categoryId);
  };

  const saveGMapTitle = (gMapTitle: string) => {
    setGMapTitle(gMapTitle);
  };

  return (
    <DataContext.Provider
      value={{
        notebookInput,
        saveNotebookInput,
        center,
        selectCenter,
        selectedNotebookId,
        selectNotebookId,
        selectedNotebook,
        selectNotebook,
        selectedCategoryId,
        selectCategoryId,
        gMapTitle,
        saveGMapTitle,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
