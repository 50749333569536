import _ from 'lodash';
import { useContext } from 'react';
import { UiContext } from '../../contexts/ui';
import PathBar from '../bar';
import CategoryConsultBlock from './consult/category';
import DocumentConsultBlock from './consult/document';
import GeneralInfoConsultBlock from './consult/generalInfo';

import './styles/consult.css';

const ConsultPanel = () => {
  const { selectedConsultBlock, currentPath } = useContext(UiContext);

  const contents = {
    category: <CategoryConsultBlock />,
    generalInfo: <GeneralInfoConsultBlock />,
    document: <DocumentConsultBlock />,
  };

  return (
    <div className="consultPanel">
      <PathBar path={currentPath} />
      <div className="content">{_.get(contents, selectedConsultBlock)}</div>
    </div>
  );
};

export default ConsultPanel;
