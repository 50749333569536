import { useContext, useEffect, useState } from 'react';
import { MiniIconButton, PrimaryIconButton } from '../../components/button';
import House from '../../components/tabContent/house';
import Profile from '../../components/tabContent/profile';
import { DataContext } from '../../contexts/data';
import { UiContext } from '../../contexts/ui';
import { useLocation } from 'react-router-dom';
import './styles/index.css';

type MainPageProps = {
  tab?: string;
};

const Main = (props: MainPageProps) => {
  const { tab } = props;
  const location = useLocation();
  const { selectHouseContentPanel, defineCurrentPath } = useContext(UiContext);
  const { selectNotebookId } = useContext(DataContext);
  const [selectedTab, setSelectedTab] = useState<string>(tab ?? 'profile');

  useEffect(() => {
    if (location?.state?.tab) setSelectedTab(location?.state?.tab);
  }, []);

  const handleTab = (tab: string) => {
    setSelectedTab(tab);
    selectHouseContentPanel('notebookPanel');
    defineCurrentPath('');
    selectNotebookId('');
  };

  const tabContents: { [key: string]: any } = {
    profile: <Profile />,
    house: <House />,
    envelope: <House />,
  };

  return (
    <div className="row main">
      <div className="col-sm-2">
        <PrimaryIconButton
          title="Mon Profil"
          selected={selectedTab === 'profile'}
          src="./images/svg/profile.svg"
          onClick={() => handleTab('profile')}
        />
        <PrimaryIconButton
          title="Mes carnets"
          selected={selectedTab === 'house'}
          src="./images/svg/house.svg"
          onClick={() => handleTab('house')}
        />
        <PrimaryIconButton
          title="Mes échanges"
          selected={selectedTab === 'envelope'}
          src="./images/svg/envelope.svg"
          onClick={() => {
            setSelectedTab('envelope');
            selectHouseContentPanel('sharedPanel');
          }}
        />
      </div>
      <div className="col-8">{tabContents[selectedTab]}</div>
      <div className="col-2">
        <MiniIconButton
          title="Ajouter un document"
          selected={false}
          src="./images/svg/plus.svg"
          onClick={() => {
            setSelectedTab('house');
            selectHouseContentPanel('addPanel');
          }}
        />
        <MiniIconButton
          title="Partager un document"
          selected={selectedTab === 'send'}
          src="./images/svg/send.svg"
          onClick={() => {
            setSelectedTab('house');
            selectHouseContentPanel('shareDocumentPanel');
          }}
        />
      </div>
    </div>
  );
};

export default Main;
