import { useContext, useEffect, useState } from 'react';
import { UiContext } from '../../contexts/ui';
import { Notebook } from '../../types';
import { fetchApi } from '../../utils/backendApi';
import NotebookItem from '../list/notebookItem';
import './styles/notebook.css';

const NotebookPanel = () => {
  const { selectHouseContentPanel } = useContext(UiContext);

  const [notebooks, setNotebooks] = useState<Notebook[]>([]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const call = async () => {
      const data = await fetchApi('notebook', 'GET', undefined, true);
      setNotebooks(data.notebooks);
      setCount(data.count);
    };
    call();
  }, []);

  return (
    <div className="notebookPanel">
      <button
        className="create"
        onClick={() => selectHouseContentPanel('createPanel')}
      >
        <img
          src="./images/svg/uncircled-plus.svg"
          alt="Uncircled Plus SVG"
        ></img>
        <span>Créer un carnet</span>
      </button>
      <div className="notebookList">
        {count !== 0 ? (
          notebooks.map((notebook) => (
            <NotebookItem
              key={notebook.id}
              id={notebook.id}
              address={notebook.address}
              lat={notebook.lat}
              lng={notebook.lng}
            />
          ))
        ) : (
          <span>No Notebook</span>
        )}
      </div>
    </div>
  );
};

export default NotebookPanel;
