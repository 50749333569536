import _ from 'lodash';
import { useState } from 'react';
import DraftShareBlock from './shared/draft';
import ReceiptSharedBlock from './shared/receipt';
import SentSharedBlock from './shared/sent';

import './styles/share.css';

const tabHeaders = [
  { id: 'sent', title: 'Envoyés' },
  { id: 'receipts', title: 'Reçus' },
  { id: 'drafts', title: 'Brouillons' },
];
const tabContents = {
  sent: <SentSharedBlock />,
  receipts: <ReceiptSharedBlock />,
  drafts: <DraftShareBlock />,
};

const SharedPanel = () => {
  const [selectedTabId, setSelectedTabId] = useState<string>('sent');

  return (
    <div className="share">
      <div className="tabHeader">
        {tabHeaders.map((tabHeader, index) => (
          <span
            className={tabHeader.id === selectedTabId ? 'selected' : ''}
            key={`tabHeader-${index}`}
            onClick={() => setSelectedTabId(tabHeader.id)}
          >
            {tabHeader.title}
          </span>
        ))}
      </div>
      <div className="tabContent">{_.get(tabContents, selectedTabId)}</div>
    </div>
  );
};

export default SharedPanel;
