export const sirenNumberRegexp = new RegExp(/^(\d{9}|\d{3}[ ]\d{3}[ ]\d{3})$/g);
export const emailAddressRegexp = new RegExp(
  /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm,
);
export const blockstoPasswordRegexp = new RegExp(
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
);

const validate = (value: string, regexp: RegExp): boolean => {
  const matchable: RegExpMatchArray | null = value.match(regexp);
  return matchable !== null ? true : false;
};

export const ValidatorUtils = { validate };
