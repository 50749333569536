import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { PrimaryInput } from '../../components/Input';
import { PrimaryDropdown } from '../../components/Input/dropdown';
import { buildingTypes, sentEmailMessage } from '../../constant';
import { UserType } from '../../types';
import { fetchApi } from '../../utils/backendApi';
import {
  blockstoPasswordRegexp,
  emailAddressRegexp,
  sirenNumberRegexp,
  ValidatorUtils,
} from '../../utils/validators';
import './index.css';

const Register = () => {
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [userType, setUserType] = useState<UserType>('particular');
  const [buildingType, setBuildingType] = useState<number>(0);
  const [companyName, setCompanyName] = useState<string>('');
  const [sirenNumber, setSirenNumber] = useState<string>('');
  const [sirenNumberError, setSirenNumberError] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [blockstoId, setBlockstoId] = useState<string>('');
  const [blockstoIdError, setBlockstoIdError] = useState<string>('');
  const [blockstoPassword, setBlockstoPassword] = useState<string>('');
  const [blockstoPasswordError, setBlockstoPasswordError] =
    useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    if (blockstoId.length > 0) checkBlockstoId(blockstoId);
  }, [blockstoId]);

  const typeData = useMemo(() => {
    if (userType === 'particular') {
      return { buildingType };
    } else if (userType === 'professional') {
      return { companyName, sirenNumber };
    }
    return {};
  }, [userType, buildingType, companyName, sirenNumber]);

  const onRegister = async () => {
    setSirenNumberError('');
    setEmailError('');
    setBlockstoPasswordError('');
    if (
      userType === 'professional' &&
      !ValidatorUtils.validate(sirenNumber, sirenNumberRegexp)
    ) {
      setSirenNumberError('Suivez ce format ! xxx xxx xxx');
      return;
    }
    if (!ValidatorUtils.validate(email, emailAddressRegexp)) {
      setEmailError(
        "Format d'adresse e-mail incorrect ! Entrez la bonne adresse e-mail !",
      );
      return;
    }
    if (!ValidatorUtils.validate(blockstoPassword, blockstoPasswordRegexp)) {
      setBlockstoPasswordError(
        '8-16 lettres, au moins un chiffre, au moins un caractère spécial.',
      );
      return;
    }
    if (!!blockstoIdError) {
      return;
    }
    const res = await fetchApi('user/register', 'POST', {
      firstname,
      lastname,
      userType,
      ...typeData,
      address,
      email,
      blockstoId,
      blockstoPassword,
    });
    if (res) {
      navigate('/connection');
      toast.success(sentEmailMessage);
    }
  };

  const checkBlockstoId = async (value: string) => {
    const res = await fetchApi(`user`, 'POST', { id: value }, false);
    const isExisted = _.get(res, 'data.isExisted', null);
    if (isExisted) {
      setBlockstoIdError('This ID was already used by the other user!');
      return;
    }
    setBlockstoIdError('');
  };

  return (
    <div className="d-flex flex-column align-items-start register">
      <p>Formulaire d'inscription</p>
      <p>*Champs à remplir obligatoirement:</p>
      <PrimaryInput
        label="Nom"
        required
        value={firstname}
        onChange={(value) =>
          setFirstname(typeof value === 'string' ? value : '')
        }
      />
      <PrimaryInput
        label="Prénom"
        required
        value={lastname}
        onChange={(value) =>
          setLastname(typeof value === 'string' ? value : '')
        }
      />
      <div className="d-flex flex-row align-items-start w-100">
        <div className="col-sm-6 col-xs-12">
          <PrimaryInput
            label="Particulier"
            required
            type="checkbox"
            value={userType === 'particular'}
            onChange={(value) => setUserType(value ? 'particular' : '')}
          />
        </div>
        <div className="col-sm-6 col-xs-12">
          <PrimaryInput
            label="Professionel"
            required
            type="checkbox"
            value={userType === 'professional'}
            onChange={(value) => setUserType(value ? 'professional' : '')}
          />
        </div>
      </div>
      <div className="d-flex flex-row align-items-start w-100">
        <div className="col-sm-5 col-xs-12">
          <PrimaryDropdown
            label="Type de logement"
            values={buildingTypes}
            onSelect={(value) =>
              setBuildingType(typeof value === 'number' ? value : 0)
            }
            disable={userType !== 'particular'}
          />
        </div>
        <span className="col-sm-1 col-xs-12">OU</span>
        <div className="col-sm-6 col-xs-12">
          <PrimaryInput
            label="Nom de l'entreprise"
            required
            value={companyName}
            onChange={(value) =>
              setCompanyName(typeof value === 'string' ? value : '')
            }
            disable={userType !== 'professional'}
          />
        </div>
      </div>
      <div className="col-sm-6 col-xs-12 align-self-end">
        <PrimaryInput
          label="Numéro de Siren"
          required
          value={sirenNumber}
          onChange={(value) =>
            setSirenNumber(typeof value === 'string' ? value : '')
          }
          description={sirenNumberError}
          descriptionType="error"
          disable={userType !== 'professional'}
        />
      </div>
      <PrimaryInput
        label="Adresse"
        required
        value={address}
        onChange={(value) => setAddress(typeof value === 'string' ? value : '')}
      />
      <PrimaryInput
        label="Email"
        type="email"
        required
        value={email}
        onChange={(value) => setEmail(typeof value === 'string' ? value : '')}
        description={emailError}
        descriptionType="error"
      />
      <PrimaryInput
        label="Identifiant Blocksto"
        required
        value={blockstoId}
        onChange={(value) =>
          setBlockstoId(typeof value === 'string' ? value : '')
        }
        description={blockstoIdError}
        descriptionType="error"
      />
      <PrimaryInput
        label="Mot de passe Blocksto"
        type="password"
        required
        value={blockstoPassword}
        onChange={(value) =>
          setBlockstoPassword(typeof value === 'string' ? value : '')
        }
        description={blockstoPasswordError}
        descriptionType="error"
      />
      <div
        className="button"
        role="button"
        onClick={() => {
          onRegister();
        }}
      >
        Valider
      </div>
    </div>
  );
};

export default Register;
