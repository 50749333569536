import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import './index.css';

type FeatureProps = {
  imgSrc: string;
  title: string;
  content: string;
};

const Home = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleGoToDiagramButtonClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    navigate('/about');
  };

  const handleCreateDocumentButtonClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    isAuthenticated
      ? navigate('/main', { state: { tab: 'house' } })
      : navigate('/connection');
  };

  const GoToDiagramButton = (props: { text?: string }) => {
    return (
      <>
        <a
          className="button nobk"
          onClick={(e) => handleGoToDiagramButtonClick(e)}
        >
          <span>{props.text || 'Je veux voir le schéma complet'}</span>
        </a>
      </>
    );
  };

  const CreateDocumentButton = (props: { text?: string }) => {
    return (
      <>
        <a
          className="button"
          onClick={(e) => handleCreateDocumentButtonClick(e)}
        >
          <span className="bold">{props.text || 'Créez votre carnet'}</span>
        </a>
      </>
    );
  };

  const FeatureComponent = (props: FeatureProps) => {
    const { imgSrc, title, content } = props;
    return (
      <div className="feature">
        <div>
          <img src={imgSrc} alt="Feature PNG" />
          <span>{title}</span>
        </div>
        <p>{content}</p>
      </div>
    );
  };

  return (
    <div className="homePage">
      <div className="welcomePart">
        <div className="about">
          <p className="large">
            <span className="bold">L'historique du logement</span>
          </p>
          <p className="large">à portée de main.</p>
          <span className="space" />
          <p className="small">
            <span className="bold">Vous êtes un professionnel</span> ?
            Choisissez une solution simple et rapide pour créer un carnet
            d'information du logement.
          </p>
          <span className="space" />
          <div>
            <CreateDocumentButton />
          </div>
        </div>
        <div className="house">
          <img src="./images/house.png" alt="Blocksto House PNG" />
        </div>
      </div>
      <div className="missionPart">
        <p className="title">Notre mission</p>
        <p className="content">
          Rendre les travaux de rénovation
          <br /> inoubliables pour vos clients.
        </p>
      </div>
      <div className="aboutPart">
        <p className="title">
          <span className="bold">Ce qu'on fait pour vous</span>
        </p>
        <p className="content">
          Il vous faut un outil qui respecte ce que vous avez de plus précieux :
          votre temps.
          <br /> Blocksto c'est :
        </p>
        <div className="features">
          <FeatureComponent
            imgSrc="./images/png/tap.png"
            title="Simplicité"
            content="Aucune connaissance technique n'est
              nécessaire pour utiliser Blocksto. Notre
              logiciel de stockage et de partage est
              intuitif."
          />
          <FeatureComponent
            imgSrc="./images/png/chronometre.png"
            title="Rapidité"
            content="Blocksto fluidifie les échanges
              d'information avec vos clients. Vous
              alimentez son carnet avec les informations
              les plus utiles en moins de 10 min."
          />
          <FeatureComponent
            imgSrc="./images/png/booster.png"
            title="Différenciation"
            content="Avec Blocksto, vous offrez à vos clients
              un accompagnement particulier. Ils ont
              une vision à 360° sur les travaux réalisés
              dans leurs logements."
          />
        </div>
      </div>
      <div className="servicesPart">
        <div className="titleBlock">
          <p>Notre plan d'attaque</p>
        </div>
        <div className="contentBlock">
          <div className="roadmap">
            <div className="road" />
            <div className="point" />
            <div className="point" />
            <div className="point" />
          </div>
          <div className="content">
            <div className="service">
              <p className="subtitle">
                <span className="number">1.</span>Créez votre compte
              </p>
              <p className="subcontent">
                Pour commencer à utiliser Blocksto, il vous faut un compte. Ca
                tombe bien, il est gratuit.
              </p>
            </div>
            <div className="service">
              <p className="subtitle">
                <span className="number">2.</span>Créez le carnet
              </p>
              <p className="subcontent">
                Besoin d'un carnet ? Super, il suffit de renseigner l'adresse et
                puis de régler l'unique paiement de 100 euros TTC.
              </p>
            </div>
            <div className="service">
              <p className="subtitle">
                <span className="number">3.</span>Stockez puis partagez vos
                documents
              </p>
              <p className="subcontent">
                Dans ce carnet vous pouvez stocker les informations utiles sur
                le logement et ensuite le partager au propriétaire à la fin de
                votre intervention.
              </p>
            </div>
          </div>
        </div>
        <div className="serviceButtonGroup">
          <GoToDiagramButton text="Je veux voir le schéma complet" />
          <CreateDocumentButton text="Je commence !" />
        </div>
      </div>
      <div className="techsPart">
        <div className="titleBlock">
          <div />
          <p>Notre technologie</p>
          <div />
        </div>
        <div className="word">
          <p>" Data that is loved tends to survive "</p>
          <p>Kurt Bollacker</p>
        </div>
        <div className="description">
          <p>
            Chez Blocksto, on croit en la conservation des informations sur le
            long terme. C’est pourquoi, nous avons fait{' '}
            <span className="bold">
              le choix de s’appuyer sur la technologie du stockage décentralisé.
            </span>
          </p>
          <span className="bold">Qu'est-ce-que c'est ?</span>
          <p>
            Le stockage décentralisé signifie que les fichiers sont stockés sur
            plusieurs ordinateurs (appelés nœuds) sur un réseau décentralisé.
            Comme avec le stockage centralisé, lorsque vous avez besoin du
            fichier, vous pouvez le demander et le consulter.
          </p>
          <img
            src="./images/png/decentalized_architecture.png"
            alt="Decenteralized Architecture"
          />
          <span className="bold">Quels sont les avantages ?</span>
          <div className="features">
            <FeatureComponent
              imgSrc="./images/png/security.png"
              title="Sécurité"
              content="Non seulement les données sont cryptées,
                mais elles sont aussi réparties entre
                différents fournisseurs de stockage.,
                Personne ne pourra reconstituer vos
                données pour les consulter."
            />
            <FeatureComponent
              imgSrc="./images/png/key.png"
              title="Retour de la propriété"
              content="Le stockage décentralisé ne signifie pas que
                ceux qui détiennent vos fichiers peuvent les
                lire. Au contraire, avec le stockage
                décentralisé vous êtes le seul à détenir la
                clé de cryptage, ce qui garantit que vos
                fichiers ne peuvent être lus que par vous."
            />
            <FeatureComponent
              imgSrc="./images/png/resilience.png"
              title="Résilience"
              content="Avec la multiplication des copies de vos
                données sur le réseau, les risques qu’elles
                ne soient plus disponibles ou détruites
                sont très faibles."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
