import './index.css';

const Formula = () => {
  return (
    <div className="formulaPage">
      <h1>Formulaire vie privée</h1>
      <p>
        Blocksto respecte vos données personnelles et votre vie privée. Ce
        formulaire est fait pour vous permettre de nous envoyer vos demandes
        relatives aux données que nous détenons à votre sujet.
      </p>
      <div className="button" role="button">
        Télécharger le formulaire vie privé de Blocksto
      </div>
    </div>
  );
};

export default Formula;
