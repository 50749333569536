import { Link } from 'react-router-dom';
import Accordion from '../../components/accordion';
import data from './data';
import './index.css';

const Condition = () => {
  return (
    <div className="conditionPage">
      <h1>CGUS - Conditions générales d'utilisation des services</h1>
      <p>
        - Les présentes « Conditions Générales d’Utilisation des Services » («
        CGUS ») regroupent les informations légales relatives aux activités
        éditoriales et commerciales de l’ensemble du site et de l’application
        www.blocksto.fr, tous sous-domaines éventuels compris, et constituent la
        base contractuelle des relations y compris commerciales entre vous et la
        société BLOCKSTO SAS, éditrice, propriétaire et représentante unique du
        site précité, identifiée dans les Mentions Légales.
      </p>
      <p>
        - Les présentes CGUS ont pour objet de définir les conditions et
        modalités d’accès et d’utilisation du site précité www.blocksto.fr.
        <br /> La version actuellement en ligne des présentes CGUS, accessibles
        à tout moment, est réputée complète et à jour, et est la seule opposable
        pendant toute la durée d'utilisation du site et jusqu'à ce qu'une
        nouvelle version la remplace.
      </p>
      <p>
        - Votre acceptation explicite des présentes CGUS est obligatoire pour
        utiliser le site précité, et emporte votre adhésion expresse et sans
        réserve à leur application. <br /> La société BLOCKSTO SAS atteste sa
        bonne foi et met en œuvre tous les moyens nécessaires à la bonne
        exécution de ses obligations contractuelles. Tout contrat particulier
        s’ajoutant éventuellement aux présentes CGUS sera notifié pour
        acceptation expresse ou pour notification selon les cas.
      </p>
      <p>
        - Vous pouvez télécharger les présentes CGUS au format PDF en cliquant
        sur <Link to="/">ce lien</Link>
      </p>
      <Accordion data={data} />
    </div>
  );
};

export default Condition;
