import { Link } from 'react-router-dom';
import Accordion from '../../components/accordion';
import data from './data';
import './index.css';

const Policy = () => {
  return (
    <div className="policyPage">
      <h1>Politique vie privée</h1>
      <div className="description">
        <p>
          <b>
            Chez Blocksto, vos données sont stockées en sécurité et sur le long
            terme, grâce à la Blockchain.
          </b>
          <br />
          La technologie du stockage décentralisé signifie que vos données sont
          stockées sur plusieurs ordinateurs (appelés nœuds) sur un réseau
          décentralisé.
          <br /> Cette technologie permet d’offrir de nombreux avantages. En
          voici les principaux :
        </p>
        <p>
          <b>1 &gt Retour de la propriété :</b> le stockage décentralisé crypte
          automatiquement les fichiers et vous seul détenez la clé de cryptage,
          ce qui garantit que vos fichiers ne peuvent être lus que par vous.
        </p>
        <p>
          <b>2 &gt Sécurité :</b> Non seulement les données sont cryptées, mais
          comme les données cryptées sont réparties entre différents
          fournisseurs de stockage, personne ne pourra reconstituer vos données
          pour les consulter.
        </p>
        <p>
          <b>3 &gt Immuabilité :</b> Avec la multiplication des copies de vos
          données sur le réseau, les risques qu’elles ne soient plus disponibles
          ou détruites sont très faibles.
        </p>
      </div>
      <p className="contactForLegal">
        Sur www.blocksto.fr, le responsable du traitement des données à
        caractère personnel est : <br /> M. Pierre-Yves Fontaine - voir les{' '}
        <Link to="/mentions-legales">Mentions Légales.</Link>
        <br /> Toutes les demandes relatives à vos données personnelles peuvent
        être faites sur l’adresse mail suivante : legal@blocksto.fr
      </p>
      <h2>PRÉAMBULE</h2>
      <p className="inform">
        La présente Politique Vie Privée a pour objet d’informer l’ensemble des
        Utilisateurs du site www.blocksto.fr (tous sousdomaines éventuels
        compris) sur les données collectées à l’occasion de leur utilisation du
        site, et sur les obligations de l’éditeur en la matière (ci-après, «
        BLOCKSTO »). La présente Politique Vie Privée est réputée être à jour,
        être la seule opposable concernant l’utilisation du site précité, et
        applicable à toutes les catégories d’Utilisateurs. Elle est accessible
        sur le site précité à tout moment, et il est recommandé de la lire
        attentivement. BLOCKSTO atteste mettre en œuvre de bonne foi les moyens
        nécessaires pour respecter votre vie privée, effectuer la collecte et le
        traitement des données de façon licite, loyale et transparente, et agir
        en conformité avec l’ensemble des réglementations applicables.
        <br />
        <br />- Vous pouvez télécharger la présente politique vie privée au
        format PDF en cliquant sur <Link to="/">ce lien.</Link>
      </p>
      <Accordion data={data} />
    </div>
  );
};

export default Policy;
