import { Link } from 'react-router-dom';
import './index.css';

const contents = [
  {
    title: 'Propriétaire et',
    descriptions: 'La société Blocksto SASU, immatriculée au Registre du',
  },
  {
    title: 'éditeur du site',
    descriptions:
      'Commerce et des Sociétés de Paris, sous le numéro 921 <br/> 792 743, au capital de 1000 euros.',
  },
  {
    title: 'Responsable de la',
    descriptions: 'Pierre-Yves Fontaine',
  },
  {
    title: 'publication et de la',
    descriptions: '',
  },
  {
    title: 'collecte des données:',
    descriptions: '',
  },
  {
    title: 'Siret :',
    descriptions: '921 792 743 00012',
  },
  {
    title: 'APE :',
    descriptions: '82.99Z (Autres activités de soutien aux entreprises)',
  },
  {
    title: 'TVA Intracom :',
    descriptions: 'FR03921792743',
  },
  {
    title: 'Adresse du siège',
    descriptions: 'Blocksto SASU - M. Pierre-Yves Fontaine',
  },
  {
    title: 'social :',
    descriptions: '63 bis, rue Damrémont 75018 Paris, France',
  },
  {
    title: 'Adresses e-mail :',
    descriptions: 'Informations générales : pyfontaine@blocksto.fr',
  },
  {
    title: 'Horaires d’ouverture',
    descriptions: 'Questions juridiques et vie privée: legal@blocksto.fr',
  },
  {
    title: 'pour les',
    descriptions: 'Du lundi au vendredi de 10h à 18h',
  },
  {
    title: 'réclamations :',
    descriptions:
      '> Le service utilisateur est accessible sur le site en <br/> permanence via le rubrique « Contact ». <br/> > Le support client est accessible depuis l’espace membre <br/> sur Blocksto via les tickets de support.',
  },
  {
    title: 'Hébergement du site :',
    descriptions:
      'Le site est hébergé sur AWS – Amazon Web Services <br/> Amazon Web Services, Inc. <br/> P.O. Box 81226 <br/> Seattle, WA 98108-1226 <br/> USA <br/> DPO > aws-EU-privacy@amazon.com <br/> Page contact : https://aws.amazon.com/fr/contact-us/? nc2=h_header',
  },
  {
    title: 'Réalisation du site :',
    descriptions: 'Le site a été réalisé par l’éditeur.',
  },
  {
    title: 'Crédits :',
    descriptions:
      'Les photos illustrant le site ont été soit réalisées directement <br/> par l’éditeur soit téléchargées sur des plateformes <br> garantissant la liberté d’utiliser ces photos. <br/> Les textes et les vidéos du site ont été réalisés par l’éditeur <br/> ou par des auteurs divers mentionnés sur le site. Les <br/> conditions de réalisation des témoignages clients sont <br/> précisées dans les <a href="/conditions-generales" target="_blank">CGUS</a>.',
  },
];

const partitions = [
  'éditeur du site',
  'collecte des données:',
  'TVA Intracom :',
  'social :',
  'Adresses e-mail :',
  'réclamations :',
  'Hébergement du site :',
  'Réalisation du site :',
  'Crédits :',
];

const Legal = () => {
  const renderRow = (title: string, descriptions: string, index: number) => {
    return (
      <div
        className={`legalRow ${partitions.includes(title) ? 'partition' : ''}`}
        key={index}
      >
        <p className="title">{title}</p>
        <p
          className="descriptions"
          dangerouslySetInnerHTML={{
            __html: descriptions,
          }}
        ></p>
      </div>
    );
  };

  return (
    <div className="legalPage">
      <h1>Mentions légales</h1>
      {contents.map((content, index) =>
        renderRow(content.title, content.descriptions, index),
      )}
    </div>
  );
};

export default Legal;
