import _ from 'lodash';
import React, { useContext } from 'react';
import { UiContext } from '../../contexts/ui';

import './index.css';

type PathBarPropsType = {
  path: string; // path text xxxx:xxxxxxx/xxxx:xxxxxx/xxxx:xxxxxxx
};

const icons = {
  notebook: './images/svg/notebook.svg',
  document: './images/svg/notebook.svg',
};

const PathBar = (props: PathBarPropsType) => {
  const {
    selectHouseContentPanel,
    selectConsultBlock,
    defineCurrentPath,
    currentPath,
  } = useContext(UiContext);
  const { path } = props;

  return (
    <div className="pathBar">
      {path.split('/').map((item, index) => (
        <div className="block" key={`block-${index}`}>
          {_.get(icons, item.split(':')[0], null) && (
            <img src={_.get(icons, item.split(':')[0])} alt="PathBar SVG" />
          )}
          <span
            onClick={() => {
              if (index === 0) {
                selectHouseContentPanel('consultPanel');
                selectConsultBlock('category');
                defineCurrentPath(currentPath.split('/')[0]);
              }
            }}
          >
            {item.split(':')[1]}
          </span>
          {index !== path.split('/').length - 1 ? (
            <img src="./images/svg/forward.svg" alt="PathBar Split SVG" />
          ) : (
            ''
          )}
        </div>
      ))}
    </div>
  );
};

export default PathBar;
