import React, { useContext, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { fetchApi } from '../../utils/backendApi';
import { DataContext } from '../../contexts/data';
import { UiContext } from '../../contexts/ui';

import './index.css';
import {
  createNotebookSuccessMessage,
  missingCardNumberMessage,
  paymentErrorMessage,
} from '../../constant';

const Payment = () => {
  const { notebookInput } = useContext(DataContext);
  const { selectHouseContentPanel } = useContext(UiContext);
  const navigatge = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '24px',
          color: 'black',
          '::placeholder': {
            color: '#C0C0C0',
          },
        },
      },
    }),
    [],
  );

  const [agreed, setAgreed] = useState<boolean>(false);

  const onPay = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!agreed) {
      toast.error("Veuillez accepter les conditions d'utilisation!");
      return;
    }
    if (!stripe || !elements) {
      console.log('no stripe');
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      console.log('no card element');
      toast.warn(missingCardNumberMessage);
      return;
    }
    const { error, token } = await stripe.createToken(cardElement);
    if (!error) {
      const data = await fetchApi(
        'notebook',
        'PUT',
        { ...notebookInput, token: token.id },
        true,
      );
      if (data.message) {
        toast.warn(data.message);
        return;
      }
      selectHouseContentPanel('notebookPanel');
      navigatge('/main');
      toast.success(createNotebookSuccessMessage);
    } else {
      toast.warn(paymentErrorMessage);
      console.log('error:', error);
    }
  };

  return (
    <div className="payment">
      <p>Payement de votre carnet Blocksto</p>
      <div className="panel">
        <div className="d-flex flex-column align-items-start">
          <div className="d-flex align-items-center">
            <img
              className="credit-card-icon"
              src="./images/svg/credit-card.svg"
              alt=""
            />
            <span>Carte bancaire</span>
          </div>
          <p>*Tous les champs doivent être remplis</p>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-6">
              <span>Numéro de carte bancaire</span>
              <CardNumberElement options={options} />
              <span className="description">
                Exemple : 5071 0032 2000 8301 (10 à 19 chiffres)
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <span>Date d'expiration</span>
              <CardExpiryElement options={options} />
              <span className="description">Exemple : 12/02 (MM/AA)</span>
            </div>
            <div className="col-4">
              <span>Code de sécurité</span>
              <CardCvcElement options={options} />
              <span className="description">
                Exemple : 236 (3 ou 4 chiffres)
              </span>
            </div>
          </div>
          <div className="d-flex agreement">
            <input
              type="checkbox"
              checked={agreed}
              onChange={() => setAgreed(!agreed)}
            />
            <span>
              J'ai pris connaissance des conditions ci-après et je les accepte
            </span>
          </div>
          <p>
            <Link to="/conditions-generales">
              Conditions générales de vente Blocksto
            </Link>
          </p>
          <div
            className={`button ${agreed ? '' : 'disable'}`}
            role="button"
            onClick={(e) => onPay(e)}
          >
            Payer 100€
          </div>
          <p>
            Votre payement est sécurisé, vous pouvez effectuer votre achat en
            toute sécurité
          </p>
        </div>
      </div>
    </div>
  );
};

export default Payment;
