import { Link } from 'react-router-dom';
import { AccordionItem } from '../../types';

const data: AccordionItem[] = [
  {
    title: 'Article 1 : Définitions',
    content: (
      <>
        <p>
          . Il est admis que les définitions fournies à l’article 4 du RGPD sont
          applicables à la présente Politique Vie Privée, et que les termes qui
          y sont utilisés doivent être compris en fonction des définitions
          précisées ci-dessous, pour la bonne compréhension de leurs engagements
          mutuels :
        </p>
        <p>
          <b>Le Site :</b> le site internet et l’application web accessibles à
          l’adresse www.blocksto.fr, tous sousdomaine(s) éventuel(s) compris,
          tant dans sa partie consultative que dans sa partie applicative.{' '}
          <br />
          <b>BLOCKSTO :</b> désigne la société <b>BLOCKSTO SAS,</b>{' '}
          propriétaire, éditrice et représentante unique du Site, de l’enseigne
          commerciale et de la marque « <b>« BLOCKSTO</b> », telle qu’elle est
          identifiée dans les Mentions Légales.
          <br />
          <b>Les Utilisateurs :</b> désigne toute personne physique ou morale
          qui consulte le Site sans nécessairement s’inscrire ou souscrire aux
          Services de <b>BLOCKSTO,</b> et/ou les Utilisateurs Abonnés lorsqu’il
          n’est pas nécessaire de distinguer entre les catégories
          d’Utilisateurs. <br />
          <b>Les Abonnés :</b> désigne tout Utilisateur, détenant la pleine
          capacité juridique, qui s’inscrit sur le Site et utilise ses
          fonctionnalités, pour ouvrir et gérer un espace en ligne sécurisé
          dénommé « Carnet ». <br />
          <b>Le Carnet :</b> désigne le carnet d’information du logement exigé
          et spécifié par la loi Climat et Résilience du 22 août 2021, prenant
          la forme d’un espace en ligne loué à l’Abonné par <b>BLOCKSTO.</b>{' '}
          <br />
          <b>Les Parties :</b> désigne ensemble BLOCKSTO et les Utilisateurs
          et/ou BLOCKSTO et les Abonnés, ayant consenti aux présentes CGUS.{' '}
          <br />
          <b>Tiers :</b> toute personne qui n’est pas partie aux CGUS.
          <br />
          <b>Les CGUS :</b> : l’ensemble du contrat indivisible régissant
          entièrement et exclusivement l’inscription et l’utilisation du Site
          dans son ensemble, par tous les Utilisateurs. Les CGUS sont ici
          également dénommées « Conditions Générales d’Utilisation des Services
          » ou « Contrat », accessible ici. <br />
          <b>La Politique Vie Privée :</b> le présent document indivisible,
          constituant l’ensemble des engagements de <b>BLOCKSTO</b> relatifs à
          la collecte et au traitement de données à caractère personnel
          effectués via le Site.
          <br />
          <b>Les Service(s) :</b> désigne l’ensemble des services proposés par
          <b>BLOCKSTO</b> par l’intermédiaire du Site.
          <br />
          <b>Les Informations :</b> l’ensemble des informations publiées sur le
          Site par<b>BLOCKSTO</b>
          <br />
          <b>Les Contenus :</b> : l’ensemble des contenus renseignés par les
          Utilisateurs, qu’ils soient destinés à remplir leur(s) Carnet(s) et/ou
          à publier sur le Site (témoignages, commentaires, etc.).
          <br />
          <b>Les Données :</b> l’ensemble des données de gestion renseignées par
          l’Utilisateur pour utiliser le Site et ses fonctionnalités. <br />
        </p>
      </>
    ),
  },
  {
    title: 'Article 2. Attestation de responsabilité',
    content: (
      <>
        <p>
          . La collecte et le traitement de données personnelles réalisés dans
          le cadre de l’utilisation du Site, nécessaire à la réalisation des
          objectifs contractuels communs, et au respect de ses obligations par
          <b>BLOCKSTO</b> dans le cadre de ses activités commerciales à distance
          sont réalisés conformément aux règles en vigueur dans l’Union
          Européenne (RGPD) ainsi qu’à la Loi Informatique et Libertés modifiée,
          sous la responsabilité de M. Pierre-Yves Fontaine, désignée dans les{' '}
          <Link to="/mentions-legales">Mentions Légales</Link>.
        </p>
      </>
    ),
  },
  {
    title: 'Article 3 : Objets et finalités de la collecte',
    content: (
      <>
        <p>
          <b>Article 3.1. Objet de la Politique Vie Privée</b>
          <br />. La présente Politique Vie Privée a pour objet d’informer les
          Utilisateurs sur les données collectées pendant leur navigation et
          leur utilisation des fonctionnalités du Site, de les informer
          également sur le respect de ses obligations par BLOCKSTO, qui s’engage
          à prendre les précautions légales et d’usage, et enfin de répartir les
          droits et obligations des Parties dans le cadre de la collecte et du
          traitement de données ainsi réalisés.
        </p>
        <p>
          <b>
            Article 3.2. Objet et finalités des collectes effectuées par
            BLOCKSTO
          </b>
          <br />. La collecte des données effectuée sur ou via le Site se limite
          au nécessaire, conformément au principe de minimisation des données.
          <br />. La collecte et le traitement des données effectuées via le
          Site sont réalisés pour : <br />
          <b>- assurer l’efficacité technique de l’utilisation du Site,</b> ce
          qui peut notamment inclure la consultation, la souscription et/ou
          l’inscription (ouverture et gestion des comptes personnels, webinaires
          et autres événements en ligne ou non, etc.), les échanges écrits, la
          publication d’avis et commentaires, l’exécution des commandes, la
          prise de contacts, la réalisation des Services, le téléchargement et
          l’archivage de contenus spécifiques, les notifications, invitations,
          sollicitations et communications diverses de <b>BLOCKSTO</b> ;<br />
          <b>
            permettre à BLOCKSTO de mieux connaître son audience pour optimiser
            la gestion de ses relations avec ses Utilisateurs,{' '}
          </b>
          via l’analyse et la mesure de l’audience du Site, ainsi que
          l’élaboration de <b>statistiques anonymes ou anonymisées</b> ; <br />
          <b>
            - permettre à BLOCKSTO d’envoyer aux Utilisateurs et Clients des
            lettres d’actualités,{' '}
          </b>
          visant notamment à les informer sur l'utilisation et l’évolution du
          Site, les prochaines sorties de Produits et de Services, les conseils
          spécifiques de <b>BLOCKSTO</b>, etc. ;<br />
          <b>
            permettre à BLOCKSTO d’envoyer aux Utilisateurs et Clients des
            sollicitations, commerciales ou non ;{' '}
          </b>
          concernant les offres commerciales, celles-ci concernent uniquement
          des offres analogues à celles proposées sur le Site ; et enfin
          permettre à <b>BLOCKSTO</b> d’améliorer tant le Site que ses offres
          commerciales.
          <br />
          <br />
          Sont comprises dans cette finalité, la gestion de la relation de
          <b>BLOCKSTO</b> avec les prospects et clients ; le recueil et gestion
          des avis des personnes sur des produits, services ou contenus ;
          l’éventuelle participation à des événements spéciaux (tels que
          concours, jeux, tirages au sort, offres) et participation au programme
          de fidélité à l'exclusion des jeux d'argent et de hasard en ligne
          soumis à l'agrément de l'Autorité de Régulation des Jeux en Ligne ; la
          prospection directe, la relance des prospects, la gestion des
          opérations techniques de prospection, la sélection des personnes pour
          réaliser des actions de fidélisation et de promotion ; les sondages,
          tests produit ;<br />
          <b>
            - assurer la bonne exécution de ses obligations à l’égard des
            Clients par BLOCKSTO,{' '}
          </b>
          via des Services et Produits divers qui impliquent par nature de
          fournir volontairement des informations à caractère personnel, ce qui
          peut comprendre : identifier les personnes utilisant le Site pour
          commander les Produits et/ou Services ; effectuer les opérations
          relatives à la gestion des fichiers concernant les contrats, les
          commandes, les livraisons, les factures, la comptabilité et le suivi
          de la relation commerciale ; permettre la bonne exécution des
          paiements, mais également la prévention et la lutte contre la fraude
          et aux moyens de paiement et notamment contre la fraude à la carte
          bancaire ; gérer les impayés et les contentieux ; traiter les
          questions et les éventuelles réclamations des personnes et la gestion
          des demandes de droit d'accès, de rectification et d'opposition.{' '}
          <br />
          <br />
          <b>
            - A NOTER : BLOCKSTO ne transfert aucune de vos données personnelles
            à aucun partenaire commercial que ce soit.
          </b>
        </p>
      </>
    ),
  },
  {
    title: 'Article 4. Consentement de l’Utilisateur',
    content: (
      <>
        <p>
          . Conformément à l’article 6 du RGPD, <b>BLOCKSTO</b> peut
          parfaitement procéder à la collecte et au traitement des données, pour
          exécuter ses obligations dans le cadre de l’achat de ses Produits et
          Services conformément à ses CGUS ainsi que pour protéger ses intérêts
          légitimes.
          <br />. Si la loi le prévoit, ou à défaut d’être nécessaire dans un
          des deux cas précédents, <b>BLOCKSTO</b> demandera le consentement
          exprès des Utilisateurs. . En cas de modification de la présente
          Politique Vie Privée, <b>BLOCKSTO</b> s’engage à ne pas baisser le
          niveau de confidentialité de manière substantielle sans en informer
          préalablement les Utilisateurs et Clients.
        </p>
        <p>
          <b>
            Article 4.1. Bandeau informatif et absence de consentement relatif à
            certains cookies
          </b>
          <br />. Dans une optique de transparence et de respect des droits des
          personnes sur leurs données personnelles, <b>BLOCKSTO</b> a mis en
          place un processus de conformité relatif à l’utilisation automatique
          de cookies de mesures d’audience basiques dès l’entrée de
          l’Utilisateur sur le Site, de sorte que ni ce dernier ni{' '}
          <b>BLOCKSTO</b>
          n’aie besoin d’intervenir pour garantir la conformité de ce procédé.
          Aucun cookie de mesure d’audience ne permet la collecte de donnée
          personnelles et identifiables.
          <br />
          . Un bandeau informatif apparaissant à l’entrée du Site rappelle aux
          Utilisateurs que le dépôt de cookies nécessaires et la collecte de
          certaines informations en résultant lors de leur utilisation du Site
          est automatique, ce dont l’Utilisateur est conscient, en lisant le
          bandeau et en cliquant sur le bouton « OK ! » situé sur ce bandeau.
          <br />. Les Utilisateurs peuvent également prendre connaissance de
          l’ensemble des conditions de la collecte en cliquant sur « Savoir plus
          » pour consulter la présente Politique Vie Privée.
          <br />. Il est entendu que les noms des boutons du Site peuvent être
          remplacés par d’autres noms équivalents et revêtant le même sens.
        </p>
        <p>
          <b>
            Article 4.2. Consentement explicite pour le recueil de certains
            cookies spécifiques et pour utiliser les fonctionnalités du Site
            (optin)
          </b>
          <br />
          . Le consentement des Utilisateurs est systématiquement et
          explicitement recueilli par une signature physique ou numérique, et/ou
          le remplissage d’une case à cocher et/ou tout autre moyen de récolter
          le consentement, entendu comme la manifestation d’une volonté claire
          et dénuée d’ambiguïté (optin), lors de l’utilisation de toutes
          fonctionnalités qui nécessitent par nature d’utiliser des données
          renseignées et/ou collectées.
          <br />. Dans tous les cas pour lesquels un consentement a été fourni
          par l’Utilisateur, <b>BLOCKSTO</b> laisse aux Utilisateurs la
          possibilité effective de retirer leur consentement aux traitements
          actuels et/ou futurs, par tous moyens adéquats (tels que notamment
          «optout» et désabonnement dans les courriels, demande écrite formelle,
          etc.). . L’ensemble des conditions d’exercice des droits des
          Utilisateurs concernant leurs données personnelles sont définies dans
          l’article 11 de la présente Politique Vie Privée.
          <br />
        </p>
      </>
    ),
  },
  {
    title: 'Article 5. Contenu de la collecte',
    content: (
      <>
        <p>
          <b>Article 5.1. Données collectées via des cookies</b> <br />. Les
          données collectées et éventuellement traitées par <b>BLOCKSTO</b> du
          fait des cookies sont régies par l’article 6 des présentes (Charte
          Cookies).
        </p>
        <p>
          <b>
            Article 5.2. Données collectées qui sont renseignées explicitement
            par les Utilisateurs
          </b>
          <br />. Le contenu du traitement de données effectué via le Site en
          cas d’utilisation de ses fonctionnalités et/ou des Services varie
          selon les utilisations de ce dernier, et peut rassembler les
          informations suivantes :<br />
          <span style={{ textDecoration: 'underline' }}>
            État civil et données de contact pour les commandes/inscriptions :
          </span>
          <br />- Adresse de courriel valide, nom et prénom ; <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            Données d’identification professionnelle éventuelle :
          </span>
          <br />- Dénomination sociale ;
          <br />- Objet social ;
          <br />- Adresse de siège social et/ou locaux d’activité ;
          <br />- Numéro de TVA ;
          <br />- N° d’agrément éventuel, diplômes, accréditations, labels ;
          <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            Données de profil/de site :
          </span>
          <br />- Descriptifs plus ou moins complets des produits ;
          <br />- Image de profil et/ou image de fond/background (photographie,
          logo, bannière, ou image quelconque) ;
          <br />- Adresses des divers profils de réseaux sociaux et/ou de site
          web le cas échéant ;
          <br />- Le sujet de message et un champ de texte libre en cas
          d’utilisation du formulaire de contact et/ou de la messagerie
          instantanée ;
          <br />- Les commentaires éventuellement laissés par les Utilisateurs
          sur le Site ;
          <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            Données de facturation et relation commerciale :
          </span>
          <br />- Adresses de facturation (courriel et postal) et de livraison ;
          <br />- Logs d’acceptation ou de rejet des notifications push
          éventuelles ; logo, bannière, ou image quelconque) ;
          <br />- Coordonnées bancaires pour réaliser les paiements et le
          prélèvement des commissions, via le prestataire tiers{' '}
          <Link to="https://stripe.com/fr" target="_blank">
            Stripe
          </Link>{' '}
          (numéro de carte bleue + date d’expiration + cryptogramme de sécurité)
          ;
        </p>
        <p className="note">
          <b>A NOTER :</b> <br />
          <b>BLOCKSTO</b> ne peut pas techniquement accéder à ces données de
          paiement, qui sont intégralement gérées par Stripe. <br />
          En cas de remboursement éventuel, et si le Client n’exige pas
          l’utilisation de nouvelles coordonnées bancaires, <b>BLOCKSTO</b>{' '}
          utilise la fonctionnalité correspondante via son espace
          administrateur, sans pouvoir visualiser les données bancaires. <br />{' '}
          Lorsque le Client exige l’utilisation de nouvelles coordonnées
          bancaires, celles-ci sont collectées par <b>BLOCKSTO</b> dans le cadre
          strict du remboursement concerné, et supprimées une fois la
          transaction pleinement effective.
        </p>
        <p>
          <span style={{ textDecoration: 'underline' }}>
            Données de gestion des clients :
          </span>
          <br />- Identifiants et mots de passe (les mots de passe des
          Utilisateurs ne sont jamais conservés en clair, mais font l’objet d’un
          hashage destiné au chiffrement afin d’en assurer la sécurité) ;
          <br />- Données et pièces justificatives complémentaires lorsque
          BLOCKSTO procède à une vérification de sécurité légitime exigeant de
          les fournir ;
          <br />- Logs de connexion complets (avec adresse IP, date et heure de
          connexion, information de connexion – dont mot de passe « brouillé »,
          informations basiques de la boutique concernée ;
          <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            Données relatives aux biens et aux Carnets :
          </span>
          <br />- adresse du bien ;
          <br />- type de bien ;
          <br />- surface ;
          <br />- copropriété ou non ;
          <br />- date de construction ;
          <br />- étiquette DPE le cas échéant ;
          <br />- champ d’informations complémentaires libre ;
          <br />- toutes données relatives aux biens concernés, à la libre
          appréciation du Client (plans du logement, schémas des réseaux d’eau
          et de gaz, facture et devis, etc.) ;
          <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            Données de sondage :
          </span>
          <br />- Données renseignées volontairement par les utilisateurs dans
          les sondages et enquêtes qui leurs sont proposées, à des fins de
          connaissance de leur degré de satisfaction, de leurs besoins, de leurs
          parcours personnel et/ou professionnel, de leurs moyens et leurs
          habitudes d’achat, de leurs objectifs, de leurs obstacles éventuels,
          et de leurs suggestions d’amélioration ;
          <br />- Informations fournies explicitement par les
          Utilisateurs/Clients lors des événements en direct, en ligne ou non ;
          <br />- surface ;
          <br />- copropriété ou non ;
          <br />- date de construction
          <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            Données pour recevoir la newsletter :
          </span>
          <br />- Prénom et adresse e-mail valide.
        </p>
        <p>
          <b>
            Article 5.3. Défaillances des Utilisateurs dans le remplissage des
            données demandées
          </b>
          <br />. Le caractère obligatoire ou facultatif du renseignement d’une
          information varie selon l’utilisation du Site, et est indiquée
          directement en ligne le cas échéant, ou dans le(s) document(s)
          concerné(s).
          <br />. Les Utilisateurs s’engagent à ne renseigner que des
          informations complètes, exactes et valides, et admettent dégager la
          responsabilité de <b>BLOCKSTO</b> en cas de dommage(s) résultant de
          leur propre défaillance en la matière.
          <br />. De même, les Utilisateurs admettent que leur défaillance dans
          le remplissage des informations à caractère obligatoire permet
          valablement à <b>BLOCKSTO</b> de refuser, suspendre ou cesser tout
          Service et/ou commande de Produit éventuellement fourni à ce dernier
          sur la base desdites informations.
          <br />. En vertu de son pouvoir de modération et de contrôle sur
          l’ensemble des activités du Site, <b>BLOCKSTO</b> se réserve le droit
          de procéder à toutes vérifications utiles concernant les données
          renseignées par les Utilisateurs, y compris de demander des pièces
          justificatives supplémentaires.
        </p>
      </>
    ),
  },
  {
    title: 'Article 6. Charte Cookies',
    content: (
      <>
        <p>
          L’utilisation opérationnelle du Site rend nécessaire l’utilisation de
          certains cookies. Les cookies sont des technologies de tracking
          (suivi/traçage) informatique, implantés sur le terminal des
          Utilisateurs, et qui donnent accès à <b>BLOCKSTO</b> à des
          informations de différentes catégories.
        </p>
        <p>
          <b>6.1 - Utilité des cookies</b>
          <br />. Les cookies peuvent être utilisés pour des fins statistiques
          pour optimiser le site et les services rendus, à partir du traitement
          des informations concernant potentiellement la fréquence d'accès, les
          informations consultées ainsi que les opérations réalisées.
          <br />. Les informations collectées dans ce cas ne seront utilisées
          que pour suivre les habitudes d’utilisation, le volume, le type et la
          configuration du trafic transitant par le Site, pour en développer la
          conception et l'agencement et plus généralement pour améliorer la
          navigation et l’utilisation du Site.
          <br />. Les cookies ne servent jamais à prospecter les Utilisateurs.
        </p>
        <p>
          <b>6.2 - Acceptation des cookies et qualité de la navigation</b>
          <br />. <b>BLOCKSTO</b> tient à informer ses Utilisateurs sur le fait
          qu’aucun des cookies utilisés sur le Site ne représente d’aucune
          manière un potentiel risque de sécurité informatique. De même,
          <b>BLOCKSTO</b> tient à préciser que la plupart du temps l’utilisation
          des cookies rend la navigation sur le Site plus agréable et plus
          personnalisée.
          <br />. Toutefois, les cookies non strictement nécessaires sont
          précisés par <b>BLOCKSTO</b>.
          <br />. Par conséquent, les Utilisateurs ne sont pas fondés à
          rechercher la responsabilité de <b>BLOCKSTO</b> s’ils refusent les
          cookies dans leur navigateur ou par tous procédés, et si leur
          utilisation du Site est de ce fait rendue moins agréable ou moins
          opérationnelle, voir impossible (dans le cas des cookies nécessaires).
        </p>
        <p>
          <b>6.3 - Contenus accessibles via l’utilisation des cookies</b>
          <br />. Les cookies de mesure d’audience ne recueillent aucune donnée
          personnelle permettant d’identifier les Utilisateurs, ni sur disque
          dur ni en ligne, et les informations collectées sont anonymes ou
          anonymisées.
          <br />. Ces cookies donnent accès à <b>BLOCKSTO</b> à des informations
          standards (heure de navigation, lieu approximatif, etc.) au sujet des
          Utilisateurs. Les Utilisateurs comprennent que leur adresse IP est
          collectée en partie seulement par Google Analytics (c’est-à-dire sans
          les deux derniers caractères), qui ne la divulgue pas pour des raisons
          de confidentialité. <b>BLOCKSTO</b> ne peut donc pas via cette
          collecte, à proprement parler, lire l’adresse IP, et ne peut donc pas
          s’en servir en tant que telle.
          <br />. Les cookies nécessaires sont destinés à l’exécution légitime
          par <b>BLOCKSTO</b> de ses obligations, et donnent accès aux
          informations de connexion et d’authentification nécessaires.
        </p>
        <p>
          <b>6.4 - Liste des cookies utilisés par BLOCKSTO</b>
          <br />
          <b>
            1. Cookies impératifs pour le fonctionnement du site dans tous les
            cas :
          </b>
          <br />
          <span
            style={{
              textDecoration: 'underline',
            }}
          >
            Cookies de connexion et d’authentification
          </span>{' '}
          : permettant de connaître l’adresse IP de l’Utilisateur, la date et
          l’heure de la connexion, le système d’exploitation, le navigateur et
          le type d’appareil utilisés par l’Utilisateur.
          <br />
          Cookie spécifique pour vérifier que le bandeau informatif a été
          affiché et lu (gestion de l’affichage et des consentements éventuels
          délivrés sur le bandeau lui-même, permettant de ne plus l’afficher à
          chaque chargement de pages).
          <b>
            2. Cookies impératifs pour le fonctionnement du site en cas de
            paiement :
          </b>
          <br />
          Cookies Stripe pour faire fonctionner le service si besoin (consulter
          cette page pour en savoir plus).
          <br /> <br />
          RÉCAPITULATIF[Auteur in1] DES COOKIES IMPÉRATIFS
          <table>
            <tr>
              <td>
                <b>NOM</b>
              </td>
              <td>
                <b>FOURNISSEUR</b>
              </td>
              <td>
                <b>FINALITÉ</b>
              </td>
              <td>
                <b>EXPIRATION</b>
              </td>
              <td>
                <b>TYPE</b>
              </td>
            </tr>
            <tr>
              <td>solution de bandeau cookies</td>
              <td>
                <Link to="https://www.axeptio.eu/fr">Axeptio </Link>
              </td>
              <td>
                Stocke l'autorisation d'utilisation de cookies pour le domaine
                actuel par l'utilisateur
              </td>
              <td>1 année</td>
              <td>HTTP cookie HTML cookie local storage PIXEL tracker</td>
            </tr>
            <tr>
              <td>AWS pour l’authentification et la traçabilité</td>
              <td>
                <Link to="https://aws.amazon.com/fr/">AWS </Link>
              </td>
              <td>Authentification de la connexion</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Stripe</td>
              <td>Stripe</td>
              <td>Procéder aux paiements</td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </p>
        <p>
          <b>3. Cookies non impératifs pour le fonctionnement du site :</b>{' '}
          <br />
          <b>° Google Analytics pour mesurer l’audience</b>
          <br />
          Ces cookies sont automatiquement supprimés au bout de 14 mois, et ne
          permettent pas l’identification des personnes.
          <br />
          Aucune des données accessibles à BLOCKSTO via cette collecte n’est
          recoupée avec d’autres données issues d’autres collectes.
          <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            Précisions sur les cookies de Google Analytics :
          </span>
          <br />
          Lors de la navigation sur le site, BLOCKSTO collecte par défaut
          uniquement les données permettant l’analyse et la mesure de l’audience
          du Site : données relatives à l’adresse IP (identification de la
          connexion internet et du terminal), aux pages consultées, ainsi que
          tous types de données accessibles via un{' '}
          <Link
            to="https://support.google.com/analytics/answer/6004245?hl=fr"
            target="_blank"
          ></Link>
          Google Analytics basique, concernant les interactions possibles entre
          les Utilisateurs et le Site :
          <br />- Nombres de pages vues ;
          <br />- Origines du trafic ;
          <br />- Dates et heures ;
          <br />- Lieux de visite (géolocalisation pas plus précise que la
          ville) ;- Durée des visites.
          <br /> . Aucune de ces données, traitées isolément ou ensemble, ne
          peuvent permettre d’identifier une personne, et sont toutes à ce
          titre, considérées comme des données anonymes ou anonymisées.
          <br /> <br />
          RÉCAPITULATIF[Auteur in1] DES COOKIES IMPÉRATIFS
          <table>
            <tr>
              <td>
                <b>NOM</b>
              </td>
              <td>
                <b>FOURNISSEUR</b>
              </td>
              <td>
                <b>FINALITÉ</b>
              </td>
              <td>
                <b>EXPIRATION</b>
              </td>
              <td>
                <b>TYPE</b>
              </td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>
                <Link to="https://policies.google.com/privacy">Google </Link>
              </td>
              <td>
                Enregistre un identifiant unique utilisé pour générer des
                données statistiques sur la façon dont le visiteur utilise le
                site.
              </td>
              <td>399 jours</td>
              <td>HTTP cookie</td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>
                <Link to="https://policies.google.com/privacy">Google </Link>
              </td>
              <td>
                Utilisé par Google Analytics pour diminuer radicalement le taux
                de requêtes.
              </td>
              <td>1 jour</td>
              <td>HTTP cookie</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>
                <Link to="https://policies.google.com/privacy">Google </Link>
              </td>
              <td>
                Enregistre un identifiant unique utilisé pour générer des
                données statistiques sur la façon dont le visiteur utilise le
                site.
              </td>
              <td>1 jour</td>
              <td>HTTP cookie</td>
            </tr>
            <tr>
              <td>rc::a </td>
              <td>
                <Link to="https://policies.google.com/privacy">Google </Link>
              </td>
              <td>
                Ce cookie est utilisé pour distinguer les humains des robots.
              </td>
              <td>Persistent</td>
              <td>HTML Local Storage</td>
            </tr>
            <tr>
              <td>rc::c</td>
              <td>
                <Link to="https://policies.google.com/privacy">Google </Link>
              </td>
              <td>
                Ce cookie est utilisé pour distinguer les humains des robots.
              </td>
              <td>Session</td>
              <td>HTML Local Storage</td>
            </tr>
          </table>
        </p>
      </>
    ),
  },
  {
    title:
      'Article 7. Interactivité avec des sites tiers et des applications tierces',
    content: (
      <>
        <p>
          . Les Utilisateurs peuvent interagir avec le Site en cliquant sur des
          boutons représentant des sites et applications tierces (notamment via
          les boutons de réseaux sociaux, les divers sites appartenant à
          <b>BLOCKSTO</b>, ainsi que ceux des Utilisateurs valablement inscrits
          sur le Site). <br />. En particulier, si les Utilisateurs connectent
          leur compte à un compte d’un autre service, tel qu’un réseau social,
          ledit service pourra communiquer à <b>BLOCKSTO</b> leurs informations
          de profil, de connexion, ainsi que toute autre information dont la
          divulgation aura été ainsi autorisée.
          <br />. Les Utilisateurs admettent que l’utilisation de ces boutons a
          pour effet de transférer des informations à <b>BLOCKSTO</b>, ainsi
          qu’aux sites tiers concernés, dans les conditions de la présente
          Politique Vie Privée, notamment dans son article 9.
          <br />. Toutefois, les Utilisateurs restent intégralement responsables
          de leurs relations contractuelles avec les sites et réseaux précités
          qui éditent leur propre Politique Vie Privée (ou Charte de
          Confidentialité, ou tout autre nom équivalent) concernant les données
          transférées, collectées et traitées à cette occasion (profil,
          paramètres, etc.).
          <br />. En aucun cas <b>BLOCKSTO</b> ne saurait supporter la
          responsabilité de quelconque dommage résultant de l’utilisation de ce
          procédé à l’égard des Utilisateurs et Clients ainsi que desdits tiers,
          et répond exclusivement des traitements de données dont il assume la
          charge.
          <br />. <b>BLOCKSTO</b> exclue toute responsabilité du fait de
          l’utilisation par les Utilisateurs des fichiers et documents ainsi
          échangés entre eux et à tout moment en dehors de <b>BLOCKSTO</b>, et
          de tous dommages qui pourraient en résulter (notamment, perte,
          détournement, destruction, etc.).
        </p>
      </>
    ),
  },
  {
    title: 'Article 8. Sécurité du traitement',
    content: (
      <>
        <p>
          <b>Article 8.1. Obligations générales de sécurité</b>
          <br />. <b>BLOCKSTO</b> s’engage à prendre toute précaution nécessaire
          et/ou utile pour préserver la sécurité du traitement et des données
          collectées, en respectant les standards de sécurisation physiques et
          logiques (protection des locaux et des appareils utilisés, protection
          des serveurs, etc.) qui sont de son ressort, concernant la navigation,
          l’inscription, la souscription et l’utilisation du Site en général, et
          sans préjudice de toute obligation de sécurisation placée sous la
          responsabilité du prestataire en charge de l’hébergement du Site.
          <br />. Les mesures de sécurité sont adaptées au regard des risques
          d'accès accidentels, non autorisés ou illégaux, de divulgation,
          d'altération, de perte ou encore de destruction des données
          personnelles des Utilisateurs.
          <br />. Toute information accessible sur Internet via un lien sortant
          du Site, à l’exception du ou des autre(s) site(s) de BLOCKSTO, n'est
          pas placée sous le contrôle de cette dernière, qui décline toute
          responsabilité quant aux dits contenus et aux éventuelles failles de
          sécurité informatiques, ainsi qu’aux conséquences qui en résulteraient
          (les conditions de sécurité informatique relatives au Site en général
          sont détaillées dans les{' '}
          <Link
            to="https://www.blocksto.com/conditions-generales"
            target="_blank"
          >
            CGUS
          </Link>{' '}
          de BLOCKSTO).
        </p>
        <p>
          <b>Article 8.2. Mesures de sécurité</b>
          <br />
          <b>
            Dans tous les cas, BLOCKSTO est un système de stockage décentralisé
            sur la blockchain (protocole IPFS), à ce titre sécurisé par
            l’impossibilité d’accéder à quelconque donnée relative aux
            entreprises ou aux personnes utilisatrices sans posséder le compte
            et la clé qui lui est assortie.
          </b>
          <Link
            to="https://www.blocksto.com/conditions-generales"
            target="_blank"
          >
            CGUS
          </Link>{' '}
          de <b>BLOCKSTO</b>).
          <br />
          <span style={{ textDecoration: 'underline' }}>
            - Sécurité informatique assurée directement par <b>BLOCKSTO</b>:
          </span>
          <br />
          <br />° Contrôle de l’accès des Utilisateurs `&gt;` Identifiant
          (e-mail unique) et « hashage » des mots de passe (jamais affichés en
          clair et non visibles pour les administrateurs du Site) ; procédure de
          récupération des mots de passe perdus ou volés
          <br />° Séparation des tables de données
          <br />° Limitation des connexions et filtrage des adresses IP
          <br />° Plugin de sécurité dédié (pare feu et protection contre les
          piratages informatiques)
          <br />° Mise en place de protections pour les appareils professionnels
          (cryptage et politiques de mots de passe)
          <br />° Sauvegardes régulières (effectuées en interne par{' '}
          <b>BLOCKSTO</b> de façon régulière et sécurisée)
          <br />° Encodage des données transitant entre le serveur et le
          client/Utilisateur.
          <br />° Encodage du code et des cookies.
          <br />° Journalisation des logs et des évènements.
          <br />
          <br />. <b>BLOCKSTO</b> met en œuvre les mesures qui permettent
          d’empêcher que les données traitées ne soient déformées, endommagées
          ou que des tiers non autorisés y aient accès, notamment en contrôlant
          les accès aux données et en sécurisant leurs communications
          éventuelles.
          <br />. Les Utilisateurs admettent être responsables de leurs
          identifiants et mots de passe, qu’ils peuvent créer et modifier en cas
          d’oubli et/ou de perte, via les procédures indiquées directement sur
          le Site. Ils renoncent à rechercher la responsabilité de{' '}
          <b>BLOCKSTO</b> en cas d’oubli, de perte, vol, détournement de ces
          identifiants et mots de passe. <b>BLOCKSTO</b> atteste qu’il n’a même
          pas accès aux mots de passe en clair et ne peut pas les lire.
          <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            - Sécurité informatique minimale assurée par l’hébergeur :
          </span>
          <br />
          <b>
            BLOCKSTO a choisi un hébergement mutualisé en nuage (Cloud) chez
            Amazon Web Services.
          </b>
          <br />
          <br />° Journalisation des logs et des évènements
          <br />° Cryptage par protocole HTTPS sur toutes les pages du Site
          <br />° Gestion des accès des administrateurs
          <br />° Accès aux certifications techniques
          <br />° Audits de conformité légale et technique, et revues des audits
          en temps réel
          <br />° Certificats SSL et TLS pour assurer la sécurité des paiements
          et des échanges de données
          <br />° Gestion affinée des accès des Utilisateurs
          <br />° Détection et analyse en temps réel des menaces de sécurité
          <br />° Gestion et classement des répertoires des bases de données
          <br />° Gestion des fichiers de stockage
          <br />° Système de récupération d’urgence le cas échéant
          <br />° Pare feu
          <br />° Possibilité de générer des clés de cryptage en cas de besoin
          <br />° Création de coffre-fort numérique destiné à protéger les
          données sensibles
          <br />° Gestion des certificats et des autorités de certification
          <br />° Modification régulière des mots de passe administrateurs
          <br />° Signature, certification et protection des codes sources
          <br />° Automatisation des sauvegardes du site (afin d’assurer
          l’éventuelle restauration du Site en cas de menace sur la sécurité,
          l’intégrité ou l’existence du Site)
          <br />° Gestion des archives en plusieurs couches
          <br />° Mise en commun de ressources et de connaissances en matière de
          sécurité informatique Les engagements de l’hébergeur sur la sécurité
          des données sont accessibles en{' '}
          <Link to="https://aws.amazon.com/fr/security/" target="_blank">
            cliquant ici.
          </Link>
          <br />
          <br />
          <span style={{ textDecoration: 'underline' }}>
            - Sécurité informatique assurée par Stripe :
          </span>
          <br />. Le prestataire tiers en charge de la gestion des paiements sur
          le Site (Stripe) assurent intégralement le chiffrement des données
          bancaires à l’occasion de tout paiement sur le Site.
          <br />
          <br />
          Les engagements de Stripe en matière de protection des données sont
          accessibles ici.
        </p>
        <p>
          <b>
            Article 8.3. Obligations d’information en cas de failles de sécurité
          </b>
          <br /> . BLOCKSTO s’engage à la plus grande transparence dans sa
          communication concernant la sécurité des données personnelles de ses
          Utilisateurs et Clients.
          <br /> . Dans le cas où BLOCKSTO prendrait éventuellement connaissance
          d'un accès non autorisé ou illégal aux données personnelles de ses
          Utilisateurs, et notamment si cet accès entraîne des conséquences sur
          la réalisation de risques de sécurité, BLOCKSTO s’engage à :
          <ul>
            <li>
              Notifier l'incident aux Utilisateurs dans les plus brefs délais,
              en particulier si cela répond à une exigence légale ;
            </li>
            <li>Examiner les causes de l'incident ;</li>
            <li>
              Prendre toutes mesures nécessaires dans la limite du raisonnable
              afin de réduire autant que possible les effets négatifs et
              préjudices pouvant résulter de cet incident.
            </li>
          </ul>
          . L’engagement de BLOCKSTO à informer promptement ses Utilisateurs
          dans ces cas spécifiques ne peut en aucun cas être assimilé à une
          quelconque reconnaissance de faute ou de responsabilité quant à la
          survenance dudit incident.
        </p>
      </>
    ),
  },
  {
    title: 'Article 9. Confidentialité du traitement',
    content: (
      <>
        <p>
          <b>Article 9.1. Communications légitimes de données personnelles</b>
          <br />. <b>BLOCKSTO</b> ne communique les données à caractère
          personnel confidentielles collectées lors de l’utilisation du Site, à
          aucun tiers que ce soit et sous aucune forme que ce soit, à
          l’exception légitime des personnes mentionnées ci-dessous :
          <br />
          <br />- Le personnel salarié éventuel de <b>BLOCKSTO</b> (stagiaires
          compris) ;
          <br />- Le ou les prestataire(s) technique(s) responsable(s) de
          création et de la maintenance du Site d’une part, et de l’hébergement
          des données d’autre part ;
          <br />- La ou les personne(s) éventuellement en charge de la
          comptabilité, de l’administratif, et/ou des aspects juridiques de
          l’entreprise de <b>BLOCKSTO</b>, y compris en tant que prestataire
          externe éventuel ;
          <br />- Les éventuels fournisseurs, sous-traitants et/ou filiales pour
          le traitement des commandes impliquant ces derniers, qui n’ont accès
          qu’aux données nécessaires à ’exécution du contrat qui les lie à
          <b>BLOCKSTO</b>, et sous réserve du respect des obligations
          contractuelles les liant à <b>BLOCKSTO</b>.
          <br />- Les tiers autorisés par la loi (notamment sur demande expresse
          et motivée des autorités publiques, réglementaires ou judiciaires,
          etc.).
          <br />- Les éventuels cessionnaires en cas de participation de
          <b>BLOCKSTO</b> à une opération de fusion, d’acquisition, sous réserve
          de garantir le même niveau de protection et de confidentialité des
          données personnelles des Utilisateurs, qui seront informés avant ledit
          transfert ou la soumission à de nouvelles règles de confidentialité.
          <br />. Dans ces cas de communication de vos données personnelles à un
          tiers, <b>BLOCKSTO</b> assure que ce(s) dernier(s) est/sont tenu(s)
          d'appliquer des conditions de confidentialité identiques, équivalentes
          ou supérieures aux siennes.
          <br />
          <br />. Dans ces cas, <b>BLOCKSTO</b> s’engage à garantir que les
          tiers concernés présentent des garanties contractuelles suffisantes
          quant à la mise en œuvre de mesure techniques et organisationnelles
          appropriées, afin que le traitement réponde aux exigences du RGPD.
        </p>
        <p>
          <b>Article 9.2. Données recueillies pour les témoignages clients</b>
          <br />. <b>BLOCKSTO</b> s’engage à recueillir les éventuels
          témoignages anonymes laissés par les Clients, conformément aux
          stipulations des CGUS.
          <br />. Lorsque les témoignages laissés ne sont pas anonymes et
          incluent l’image de l’Utilisateur concerné, une autorisation de droit
          à l’image est systématiquement proposée à ce dernier, afin d’assurer à
          ce dernier le respect de ses droits et sa faculté de retirer son
          consentement s’il souhaite faire retirer son témoignage du Site ou
          faire cesser son exploitation par <b>BLOCKSTO</b>.
        </p>
        <p>
          <b>
            Article 9.3. Transfert de données personnelles dans et hors de
            l’Union Européenne
          </b>
          <br />. En vertu de la réglementation applicable aux transferts des
          données vers des pays situés en dehors de l’Union Européenne,{' '}
          <b>BLOCKSTO</b>
          s’engage à ne transférer, le cas échéant, des données personnelles
          collectées, que vers des pays reconnus comme offrant un niveau de
          protection équivalent.
          <br />. <b>BLOCKSTO</b> s’engage à s’abstenir de transférer les
          données personnelles en dehors des pays reconnus par la CNIL comme
          ayant un niveau de protection suffisant, à moins d’avoir obtenu une
          autorisation de la CNIL pour procéder à ce transfert.
          <br /> . L’utilisation du Site dans son ensemble peut avoir pour effet
          de transférer automatiquement, et sans intervention possible de
          <b>BLOCKSTO</b>, certaines données de connexion (pages visitées,
          systèmes d’exploitation, langues, pays, etc.), à ou aux prestataire(s)
          tiers responsable(s) des services permettant à <b>BLOCKSTO</b> de
          collecter et de traiter ses propres données (notamment Google
          Analytics), sans que ce dernier n’ait d’emprise sur ce transfert.
          L’Utilisateur accepte cette condition et peut consulter l’article 6
          des présentes pour en savoir plus sur les cookies déposés.
        </p>
      </>
    ),
  },
  {
    title: 'Article 10. Conservation des données et délais',
    content: (
      <>
        <p>
          <b>Article 10.1. Renouvellement du consentement des Utilisateurs</b>
          <br />. Les données récoltées sont conservées valablement pendant
          toute la durée de la relation commerciale entre les Parties, et tant
          qu’elles servent la finalité pour laquelle elles ont été légitimement
          collectées initialement et pour laquelle elles continuent d’être
          traitées, et tant que cette finalité perdure de façon légitime,
          proportionnée, et consentie par l’Utilisateur concerné (lorsque son
          consentement est exigé).
          <br />. Les délais de conservation indiqués dans l’article 10.2 de la
          présente démarrent donc à compter de la fin de la relation
          commerciale, ou de la dernière interaction entre les Parties,
          matérialisée par une manifestation non équivoque de l’Utilisateur de
          sa volonté d’interagir avec <b>BLOCKSTO</b>.
          <br />. Les délais de conservation des données collectées varient
          selon le type de données, soumises à des exigences légales et
          réglementaires différentes (autorisant une plus longue conservation ou
          au contraire imposant leur suppression).
          <br />. Une fois les délais de conservation dépassés, le consentement
          peut dans certains cas être renouvelé par l’Utilisateur. A défaut, les
          données concernées seront anonymisées et conservées à des fins
          statistiques ou de preuve des activités commerciales de{' '}
          <b>BLOCKSTO</b>.
          <br />. Par conséquent, les Utilisateurs admettent que la suppression
          éventuelle de leur compte sur le Site peut avoir pour effet la purge
          définitive de leurs données et contenus de profil, à l’exclusion des
          données contractuelles et de gestion destinées à l’archivage par
          <b>BLOCKSTO</b>.
        </p>
        <p>
          <b>
            Article 10.2. Délais de conservation applicables aux différentes
            données
          </b>
          <br />. La conservation des données stockées est effectuée
          légitimement à des fins de gestion de la relation client,
          d’amélioration de l’utilisation du Site, d’optimisation de la relation
          commerciale, mais aussi pour garantir la sécurité de la navigation, et
          enfin au titre de la preuve des activités commerciales de{' '}
          <b>BLOCKSTO</b>.
          <br />. Les délais de conservation des données, hors données de
          gestion, sont fixés à :
          <ul>
            <li>
              quatorze (14) mois concernant les données de connexion et de
              mesure d’audience (notamment, les cookies, programmés pour être
              automatiquement supprimés après ce délai) ; la durée de vie des
              cookies n’est pas prolongée à chaque visite. Les autres durée de
              conservation des cookies sont précisés dans l’article 6 de la
              présente.
            </li>
            <li>
              - quinze (15) mois pour les coordonnées bancaires, lorsqu’elles
              sont collectées et conservées par <b>BLOCKSTO</b> (par principe,
              les données bancaires nécessaires à la réalisation des commandes
              ne sont pas conservées par <b>BLOCKSTO</b> – elles sont gérées
              intégralement par les tiers de paiement – Stripe) ;
            </li>
            <li>
              cinq (5) ans pour les données de facturation et contractuelles
              concernant des montants inférieurs à 120 euros, et dix (10) ans
              pour des montants supérieurs à 120 euros ;
            </li>
            <li>
              au maximum trois (3) ans pour les autres types de données. Ce
              délai de trois années court à compter du terme initial du contrat
              les liant à <b>BLOCKSTO</b>, ou à compter du dernier contact actif
              par les Utilisateurs et Client.
            </li>
          </ul>
          . <b>BLOCKSTO</b> s’engage à renouveler le consentement des
          Utilisateurs avant la fin de ce délai pour continuer à exploiter les
          données le concernant (Optin), et à défaut de consentement explicite à
          cesser l’envoi éventuel de lettre d’actualité et de toutes
          sollicitations commerciales (Optout). <b>BLOCKSTO</b> s’engage
          également à cesser ces envois sur simple demande des Utilisateurs,
          dans les conditions de l’article 11 de la présente Politique Vie
          Privée.
        </p>
        <p>
          <b>Article 10.3. Archivage des données</b>
          <br />. Passé les délais précités dans l’article 10.2, les données
          collectées qui n’ont pas été valablement supprimées, peuvent faire
          l’objet d’un archivage sur un support informatique distinct, à des
          fins de preuve et en accès strictement limité, sauf en cas de
          procédure de demande d’effacement formelle des données personnelles
          collectées, susceptible d’engendrer la suppression définitive desdites
          données lorsque la Loi le prévoit.
          <br />. Dans ce cas, la suppression des données est effective dès lors
          que la durée de conservation ou d'archivage nécessaire à
          l'accomplissement des finalités déterminées ou imposées est atteinte,
          et/ou dès la requête formelle valable de l’Utilisateur/Client est
          reçue et traitée (voir article 11).
          <br />. Dans ce cas, les Utilisateurs admettent que leurs données
          personnelles peuvent être purgées du système informatique de{' '}
          <b>BLOCKSTO</b>
          sans possibilité de récupération.
        </p>
      </>
    ),
  },
  {
    title: 'Article 11. Droit des personnes sur leurs données collectées',
    content: (
      <>
        <p>
          <b>Article 11.1. Contenus des droits des personnes</b>
          <br />. Les Utilisateurs disposent des droits accordés par la Loi,
          relativement aux données qui les concernent : droit d'accès,
          d’interrogation, de rectification, de limitation, de portabilité,
          d’opposition, et/ou d’effacement.
          <br />
          mitation, de portabilité, d’opposition, et/ou d’effacement. . Les
          Utilisateurs disposent également du droit de s’opposer à tout moment,
          pour des raisons tenant à leur situation particulière, à un traitement
          des données à caractère personnel ayant comme base juridique l’intérêt
          légitime de <b>BLOCKSTO</b>, ainsi que d’un droit d’opposition à la
          prospection commerciale. Le consentement au traitement peut être
          retiré sans porter atteinte à la licéité de ce traitement, fondé sur
          le consentement effectué avant le retrait de celui-ci.
          <br />. Les Utilisateurs peuvent également définir des directives
          générales et particulières définissant la manière dont ils souhaitent
          que soient exercés, après leur décès, les droits mentionnés ci-dessus.
          <br />. Les Utilisateurs peuvent toujours introduire une réclamation
          auprès de la CNIL, si les réponses de <b>BLOCKSTO</b> leur paraissent
          insatisfaisantes.
        </p>
        <p>
          <b>Article 11.2. Exercice des droits des personnes</b>
          <br />. Les demandes des Utilisateurs relatives à l’exercice de leurs
          droits relatifs à leurs données à caractère personnel se font :
          <ul>
            <li>
              - soit par courriel à l’adresse suivante : legal@blocksto.fr
            </li>
            <li>
              - soit par courrier postal (coordonnées via les Mentions Légales)
            </li>
            <li>
              - soit en utilisant le formulaire dédié accessible en cliquant ici
            </li>
          </ul>
          . <b>BLOCKSTO</b> s’engage à rendre effective toute demande motivée
          relative auxdites données, en notifiant sans délai la réception de la
          demande, puis en répondant effectivement à ces demandes dans un délai
          de trente (30) jours ouvrés à compter de leur réception.
          <br />. Pour des raisons de sécurité et éviter toute demande
          frauduleuse, <b>BLOCKSTO</b> peut valablement exiger que cette demande
          soit accompagnée d'un justificatif d'identité, qu’il supprimera ou
          détruira après traitement de la demande, sous réserve de l’application
          d’une disposition légale imposant son archivage.
          <br />. Les Utilisateurs admettent sans réserve qu’en cas de demande
          de suppression motivée de leurs données à caractère personnel, ces
          dernières peuvent être purgées sans possibilité de récupération et que
          cette suppression peut empêcher la poursuite de leurs relations
          contractuelles ainsi que le bénéfice des Services, du fait de la
          nécessité de ces données pour utiliser le Site, en partie ou dans son
          ensemble. Par conséquent, si ces droits sont exercés au moment de la
          Commande de Produits ou Services, ladite Commande ne pourra être
          effectuée.
        </p>
      </>
    ),
  },
  {
    title: 'Article 12. Droit applicable, désaccords et litiges',
    content: (
      <>
        <p>
          . De convention expresse entre les Parties, la présente Politique Vie
          Privée est régie exclusivement par le droit français, et doit être
          interprétée au regard du droit français, y compris en matière de
          transposition du droit européen (le RGPD) en droit français.
          <br />. De convention expresse entre les Parties, les litiges n’ayant
          pu aboutir à un règlement amiable, relativement à la collecte et au
          traitement de données à caractère personnel des Utilisateurs et
          Clients, et à la présente Politique Vie Privée, concernant sa
          validité, son interprétation, son exécution, ses conséquences et ses
          suites,{' '}
          <b>
            seront de principe soumis à la compétence des tribunaux français,
            selon les règles procédurales applicables au cas d’espèce
          </b>
          .<br />. L’Utilisateur ne résidant pas en France est informé qu’il
          peut également saisir une juridiction de l’État Membre de l’Union
          Européenne dans lequel il a sa résidence principale.
        </p>
      </>
    ),
  },
];

export default data;
