import { useContext } from 'react';
import { condoms, ecdlevels, notebookTypes } from '../../../constant';
import { DataContext } from '../../../contexts/data';
import { NormalInput } from '../../Input';

const GeneralInfoConsultBlock = () => {
  const { selectedNotebook } = useContext(DataContext);

  return (
    <div className="infoBlock">
      <NormalInput
        label="Adresse"
        value={selectedNotebook?.address ?? ''}
        required={false}
        onChange={() => {}}
      />
      <NormalInput
        label="Type"
        value={notebookTypes[selectedNotebook?.type ?? 0]}
        required={false}
        onChange={() => {}}
      />
      <NormalInput
        label="Surface en &#13217;"
        value={selectedNotebook?.surface.toString() ?? ''}
        required={false}
        onChange={() => {}}
      />
      <NormalInput
        label="Copropriété"
        value={condoms[selectedNotebook?.condom ?? 0]}
        required={false}
        onChange={() => {}}
      />
      <NormalInput
        label="Date de construction"
        value={
          selectedNotebook?.constructDate.toISOString().substring(0, 10) ?? ''
        }
        required={false}
        onChange={() => {}}
      />
      <NormalInput
        label="Etiquette DPE"
        value={ecdlevels[selectedNotebook?.ecdlevel ?? 0]}
        required={false}
        onChange={() => {}}
      />
      <NormalInput
        label="Informations complémentaires"
        type="textarea"
        value={selectedNotebook?.additionalInfo ?? ''}
        onChange={() => {}}
      />
    </div>
  );
};

export default GeneralInfoConsultBlock;
