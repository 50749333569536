import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import './index.css';

const About = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleCreateDocumentButtonClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    isAuthenticated
      ? navigate('/main', { state: { tab: 'house' } })
      : navigate('/connection');
  };

  const CreateDocumentButton = () => {
    return (
      <>
        <a
          className="button"
          onClick={(e) => handleCreateDocumentButtonClick(e)}
        >
          <span className="bold">Créez votre carnet</span>
        </a>
      </>
    );
  };
  return (
    <div className="aboutPage">
      <div className="advPart">
        <p className="title">
          Construisez et partagez
          <br />
          l’historique du logement <br />
          de votre client.
        </p>
        <p className="content">
          Vous n’avez pas l’endroit où abriter vos informations ? Nous en avons
          un !
          <br />
          Avec Blocksto, gagnez en temps et en simplicité sur votre gestion des
          informations.
        </p>
        <div>
          <CreateDocumentButton />
        </div>
        <img src="./images/png/schema.png" alt="Schema PNG" />
      </div>
      <div className="offersPart">
        <p className="title">
          Le carnet d'information du logement est obligatoire
          <br /> après des travaux de rénovation énergétique
        </p>
        <p className="content">
          Depuis le 1er janvier 2023, tous les logements faisant l'objet de
          travaux de
          <br /> rénovation énergétique doivent avoir un carnet d'information.{' '}
          <a
            href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000046820979"
            target="_blank"
          >
            Voir le texte de loi
          </a>
        </p>
        <CreateDocumentButton />
      </div>
    </div>
  );
};

export default About;
