import React, { useContext, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { UiContext } from '../../contexts/ui';

import './styles/gmap.css';

const GMapPanel = (props: { lat: number; lng: number; title: string }) => {
  const { lat, lng, title } = props;
  const { selectHouseContentPanel } = useContext(UiContext);

  return (
    <div className="gMapPanel">
      <div className="returnBtn">
        <img
          src="./images/svg/left-arrow.svg"
          alt="Back to Notebook Page"
          onClick={() => selectHouseContentPanel('notebookPanel')}
        />
        <span>Localisation du {title}</span>
      </div>
      <div className="gMapContent">
        <GoogleMap
          clickableIcons
          mapContainerStyle={{ width: '100%', height: '60vh' }}
          zoom={10}
          center={{ lat, lng }}
        >
          <Marker position={{ lat, lng }} />
        </GoogleMap>
      </div>
    </div>
  );
};

export default GMapPanel;
