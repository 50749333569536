import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Share } from '../../../types';
import { fetchApi } from '../../../utils/backendApi';
import Table from '../../table';

const ReceiptSharedBlock = () => {
  const [data, setData] = useState<Share[]>([]);
  const [downloadableId, setDownloadableId] = useState<string>('');
  const columns = [
    { id: 'title', name: 'Nom du fichier', width: 35 },
    { id: 'from', name: 'De', width: 25 },
    { id: 'date', name: 'Date', width: 25 },
    { id: 'actions', name: 'Actions', width: 15 },
  ];
  const actions = [
    { id: 'info', func: (id: string) => {} },
    {
      id: 'download',
      func: (id: string) => {
        setDownloadableId(id);
      },
    },
  ];

  useEffect(() => {
    const call = async () => {
      let res = await fetchApi(`share/receipts`, 'GET', undefined, true);
      let receiptData: [] = res.receipts;

      const receiptedDocumentData: Share[] = receiptData.map((receipt) => ({
        id: _.get(receipt, 'id'),
        type: _.get(receipt, 'document.type'),
        title: _.get(receipt, 'document.filename'),
        to: `${_.get(receipt, 'to.firstname')} ${_.get(
          receipt,
          'to.lastname',
        )}`,
        from: `${_.get(receipt, 'from.firstname')} ${_.get(
          receipt,
          'from.lastname',
        )}`,
        date: _.toString(_.get(receipt, 'sharedDate')).substring(0, 10),
        additionalInfo: _.get(receipt, 'addtionalInfo'),
        documentId: _.get(receipt, 'document.id'),
      }));

      res = await fetchApi(`sharenotebook/receipts`, 'GET', undefined, true);
      receiptData = res.receipts;

      const receiptedNotebookData: Share[] = receiptData.map((receipt) => ({
        id: _.get(receipt, 'id'),
        type: 'notebook',
        title: _.get(receipt, 'notebook.address'),
        to: `${_.get(receipt, 'to.firstname')} ${_.get(
          receipt,
          'to.lastname',
        )}`,
        from: `${_.get(receipt, 'from.firstname')} ${_.get(
          receipt,
          'from.lastname',
        )}`,
        date: _.toString(_.get(receipt, 'sharedDate')).substring(0, 10),
        additionalInfo: _.get(receipt, 'addtionalInfo'),
        documentId: _.get(receipt, 'notebook.id'),
      }));

      setData([...receiptedDocumentData, ...receiptedNotebookData]);
    };
    call();
  }, []);

  return (
    <div className="sentBlock">
      <Table
        forWhat="receipt"
        data={data}
        columns={columns}
        actions={actions}
        additionalShowId={downloadableId}
      />
    </div>
  );
};

export default ReceiptSharedBlock;
