import { Link } from 'react-router-dom';

import './index.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="legal">
        <Link to="/mentions-legales">Mentions légales</Link>
        <Link to="/conditions-generales">
          Conditions générales d'utilisation
        </Link>
        <Link to="/formulaire-donnees-persos">Formulaire de rétraction</Link>
        <Link to="/politique-vie-privee">Politique vie privée</Link>
      </div>
      <div className="links">
        <span className="bold">Liens utiles</span>
        <Link to="">Comment créer mon compte ?</Link>
        <Link to="">Comment créer un carnet ?</Link>
        <Link to="">Comment partager des documents ?</Link>
        <Link to="">Comment supprimer des documents ?</Link>
      </div>
      <div className="networks">
        <span className="bold">Nos réseaux</span>
        <Link to="">Linkedin</Link>
        <Link to="">Youtube</Link>
      </div>
      <div className="contact">
        <span className="bold">Contact</span>
        <span>contact@blocksto.fr</span>
        <Link to="">Linkedin</Link>
      </div>
    </div>
  );
};

export default Footer;
