import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { condoms, ecdlevels, notebookTypes } from '../../constant';
import { DataContext } from '../../contexts/data';
import { NotebookInput } from '../../types';
import { PrimaryInput } from '../Input';
import { PrimaryDropdown } from '../Input/dropdown';

const NotebookCreatePanel = () => {
  const [address, setAddress] = useState<string>('');
  const [addressError, setAddressError] = useState<string>('');
  const [lat, setLat] = useState<number>(0.0);
  const [lng, setLng] = useState<number>(0.0);
  const [type, setType] = useState<number>(0);
  const [surface, setSurface] = useState<number>(0);
  const [surfaceError, setSurfaceError] = useState('');
  const [condom, setCondom] = useState<number>(0);
  const [constructDate, setConstructDate] = useState<Date>(new Date());
  const [ecdlevel, setEcdlevel] = useState<number>(0);
  const [additionalInfo, setAdditionalInfo] = useState<string>('');

  const { saveNotebookInput } = useContext(DataContext);
  const navigate = useNavigate();
  const onMoveToPayment = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setAddressError('');
    setSurfaceError('');
    if (!address) {
      setAddressError("Veuillez sélectionner l'adresse !");
      return;
    }
    if (!surface) {
      setSurfaceError('Surface value should be more than 0!');
      return;
    }
    const notebookInput: NotebookInput = {
      address,
      lat,
      lng,
      type,
      surface,
      condom,
      constructDate,
      ecdlevel,
      additionalInfo,
    };
    saveNotebookInput(notebookInput);
    navigate('/payment');
  };

  return (
    <div className="notebookCreatePanel">
      <p>Création de votre nouveau carnet</p>
      <div className="formGroup">
        <PrimaryInput
          label="Adresse"
          type="googleMapAutoComplete"
          value={address}
          onChange={(value) => {
            if (typeof value === 'object') {
              setAddress(_.get(value, 'address', ''));
              setLat(_.get(value, 'lat', 0));
              setLng(_.get(value, 'lng', 0));
            }
          }}
          description={addressError}
          descriptionType="error"
        />
        <PrimaryDropdown
          label="Type"
          values={notebookTypes}
          onSelect={(value) => {
            setType(value);
          }}
        />
        <PrimaryInput
          label="Surface en &#13217;"
          type="number"
          value={surface < 0 ? '' : surface.toString()}
          onChange={(value) => {
            setSurface(typeof value === 'string' ? parseFloat(value) : -1);
          }}
          description={surfaceError}
          descriptionType="error"
        />
        <PrimaryDropdown
          label="Copropriété"
          values={condoms}
          onSelect={(value) => {
            setCondom(value);
          }}
        />
        <PrimaryInput
          label="Date de construction"
          type="date"
          value={constructDate.toISOString().substring(0, 10)}
          onChange={(value) => {
            typeof value === 'string'
              ? setConstructDate(new Date(value))
              : new Date();
          }}
        />
        <PrimaryDropdown
          label="Etiquette DPE"
          values={ecdlevels}
          onSelect={(value) => setEcdlevel(value)}
        />
        <PrimaryInput
          label="Informations complémentaires"
          type="textarea"
          value={additionalInfo}
          onChange={(value) =>
            setAdditionalInfo(typeof value === 'string' ? value : '')
          }
        />
        <a className="button payment" onClick={(e) => onMoveToPayment(e)}>
          Paiement
        </a>
      </div>
    </div>
  );
};

export default NotebookCreatePanel;
