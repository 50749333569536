import React from 'react';
import { useLocation } from 'react-router-dom';
import { fetchApi } from '../../utils/backendApi';

import './index.css';

const EmailVerify = () => {
  const location = useLocation();

  const onSendEmail = async () => {
    await fetchApi(
      `user/email/send/${location.state.id}`,
      'GET',
      undefined,
      false,
    );
  };

  return (
    <div className="emailVerifyContainer">
      <h1>You didn't verify your account with your email.</h1>
      <p>Please verify your account!</p>
      <p>
        To send verification email, please click{' '}
        <span onClick={onSendEmail}>here</span>
      </p>
    </div>
  );
};

export default EmailVerify;
