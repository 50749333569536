import _ from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createNotebookSuccessMessage } from '../../constant';
import { Notebook } from '../../types';
import { fetchApi } from '../../utils/backendApi';
import { PrimaryDropdown } from '../Input/dropdown';

import './index.css';

type ColumnType = {
  id: string;
  name: string;
  width: number;
};

type ActionType = {
  id: string;
  func: (id: string) => void;
};

type TablePropsType = {
  forWhat: string;
  data: any[];
  columns: ColumnType[];
  actions: any[];
  additionalShowId: string;
};

const icons = {
  notebook: './images/svg/notebook.svg',
  pdf: './images/svg/pdf.svg',
  jpg: './images/svg/jpg.svg',
  jpeg: './images/svg/jpg.svg',
  png: './images/svg/png.svg',
  ppt: './images/svg/ppt.svg',
  zip: './images/svg/zip.svg',
  send: './images/svg/send.svg',
  delete: './images/svg/delete.svg',
  download: './images/png/download.png',
  info: './images/png/info.png',
};

const Table = ({
  forWhat,
  data,
  columns,
  actions,
  additionalShowId,
}: TablePropsType) => {
  const [selectedData, setSelectedData] = useState<any>({});
  const [selectedNotebookIndex, setSelectedNotebookIndex] = useState(0);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [notebooks, setNotebooks] = useState<Notebook[]>([]);
  const [categories, setCategories] = useState<{ id: string; title: string }[]>(
    [],
  );

  useEffect(() => {
    setSelectedCategoryIndex(0);
    setSelectedNotebookIndex(0);
  }, [additionalShowId]);

  useEffect(() => {
    const call = async () => {
      let res = await fetchApi('notebook', 'GET', undefined, true);
      setNotebooks(res.notebooks);
      res = await fetchApi('category', 'GET', undefined, true);
      setCategories([...res.categories]);
    };
    call();
  }, []);

  const handleSaveDocument = async () => {
    try {
      const datum = data.find((datum) => datum.id === additionalShowId);
      const shareId = datum.id;
      let res = await fetchApi(`share/${shareId}`, 'DELETE', undefined, true);
      if (!res) throw new Error("Can't delete share data");

      res = await fetchApi(
        `document/${datum.documentId}`,
        'GET',
        undefined,
        true,
      );

      if (!res) throw new Error("Can't get document data");
      let document = _.get(res, 'document');

      const body = {
        ...document,
        filesize: parseInt(document.filesize),
        notebookId: notebooks[selectedNotebookIndex].id,
        categoryId: categories[selectedCategoryIndex].id,
      };
      delete body['id'];
      delete body['createdDate'];

      res = await fetchApi('document', 'PUT', body, true);
      if (!res) throw new Error("Can't create the document");

      toast.success('Le document a été correctement enregistré !');
    } catch (error) {
      console.log(error);
      toast.error("Échec de l'enregistrement du document partagé !");
    }
  };

  const handleSaveNotebook = async () => {
    try {
      const datum = data.find((datum) => datum.id === additionalShowId);

      let res = await fetchApi(
        `sharenotebook/save`,
        'POST',
        { id: datum.id },
        true,
      );
      if (!res) throw new Error("Can't get notebook data");

      toast.success(createNotebookSuccessMessage);
    } catch (error) {
      console.log(error);
      toast.error("Échec de l'enregistrement du document partagé !");
    }
  };

  const additionalComponents = {
    draft: (
      <div className="additional">
        <div className="button">Continuer le partage</div>
      </div>
    ),
    receiptNotebook: (
      <div className="additional">
        <div className="button" role="button" onClick={handleSaveNotebook}>
          Enregistrer ce nouveau carnet
        </div>
      </div>
    ),
    receiptDocument: (
      <div className="additional">
        <div className="item">
          <PrimaryDropdown
            label="Dans quel carnet ?"
            values={notebooks.map((notebook) => notebook.address)}
            onSelect={(index) => setSelectedNotebookIndex(index)}
          />
        </div>
        <div className="item">
          <PrimaryDropdown
            label="Catégorie"
            values={categories.map((category) => category.title)}
            onSelect={(index) => setSelectedCategoryIndex(index)}
          />
        </div>
        <div className="item button" role="button" onClick={handleSaveDocument}>
          Télécharger
        </div>
      </div>
    ),
  };
  const renderCell = (column: ColumnType, item: any, index: number) => {
    if (column.id === 'title') {
      return (
        <div
          className="title"
          style={{ width: `${column.width}%` }}
          key={`title-${index}`}
        >
          {_.get(item, 'type') && (
            <img
              src={_.get(icons, _.get(item, 'type'), './images/svg/file.svg')}
              alt="Title SVG"
            />
          )}
          <span>{_.get(item, 'title', '')}</span>
        </div>
      );
    } else if (column.id === 'actions') {
      return (
        <div
          className="actions"
          style={{ width: `${column.width}%` }}
          key={`actions-${index}`}
        >
          {actions.map((action: ActionType, index: number) => (
            <img
              key={`actionButton-${index}`}
              src={_.get(icons, action.id)}
              onClick={() => action.func(item.id)}
              alt="Action SVG"
            />
          ))}
        </div>
      );
    } else if (column.id === 'createdDate') {
      return (
        <div
          className="createdDate"
          style={{ width: `${column.width}%` }}
          key={`createdDate-${index}`}
        >
          <span>{_.get(item, 'createdDate', '').substring(0, 10)}</span>
        </div>
      );
    } else if (column.id === 'filesize') {
      return (
        <div
          className="filesize"
          style={{ width: `${column.width}%` }}
          key={`filesize-${index}`}
        >
          <span>
            {`${(parseInt(_.get(item, 'filesize', '')) / 1024).toFixed(2)} KB`}
          </span>
        </div>
      );
    } else {
      return (
        <div
          className="other"
          style={{ width: `${column.width}%` }}
          key={`${column.id}-${index}`}
        >
          <span>{_.get(item, column.id, '')}</span>
        </div>
      );
    }
  };

  return (
    <div className="primaryTable">
      <div className="tableHeader">
        {columns.map((column, index) => (
          <span
            key={`tableHeaderColumn-${index}`}
            style={{ width: `${column.width}%` }}
          >
            {column.name}
          </span>
        ))}
      </div>
      <div className="tableBody">
        {data.length === 0 ? (
          <span>No Data to be displayed</span>
        ) : (
          data.map((datumn, index) => (
            <div className="tableRow" key={`row-${index}`}>
              <div className="rowBody">
                {columns.map((column, index) =>
                  renderCell(column, datumn, index),
                )}
              </div>
              {additionalShowId === datumn.id &&
                _.get(
                  additionalComponents,
                  forWhat === 'receipt'
                    ? datumn.type === 'notebook'
                      ? 'receiptNotebook'
                      : 'receiptDocument'
                    : '',
                )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Table;
