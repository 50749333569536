import _ from 'lodash';
import { useContext } from 'react';
import { SearchInput } from '../Input';
import NotebookPanel from '../panel/notebook';
import NotebookCreatePanel from '../panel/notebookCreate';
import GMapPanel from '../panel/gMap';
import { UiContext } from '../../contexts/ui';
import { DataContext } from '../../contexts/data';
import DocumentAddPanel from '../panel/documentAdd';
import ConsultPanel from '../panel/consult';
import ShareDocumentPanel from '../panel/shareDocument';
import ShareNotebookPanel from '../panel/shareNotebook';
import SharedPanel from '../panel/shared';

const House = () => {
  const { selectedHouseContentPanel } = useContext(UiContext);
  const { center, gMapTitle } = useContext(DataContext);

  const renderPanels = {
    notebookPanel: <NotebookPanel />,
    createPanel: <NotebookCreatePanel />,
    gMapPanel: (
      <GMapPanel lat={center.lat} lng={center.lng} title={gMapTitle} />
    ),
    addPanel: <DocumentAddPanel />,
    consultPanel: <ConsultPanel />,
    shareDocumentPanel: <ShareDocumentPanel />,
    shareNotebookPanel: <ShareNotebookPanel />,
    sharedPanel: <SharedPanel />,
  };

  return (
    <div className="house">
      <SearchInput />
      <div className="content-panel">
        {_.get(renderPanels, selectedHouseContentPanel)}
      </div>
    </div>
  );
};

export default House;
