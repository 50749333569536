import { Link } from 'react-router-dom';
import { AccordionItem } from '../../types';

const data: AccordionItem[] = [
  {
    title: 'Partie 1 : Application des CGUS et utilisation de l’application',
    content: (
      <>
        <p>
          <span className="article">Article 1. Définitions</span>
          <br />
          Les parties aux présentes Conditions Générales d’Utilisation des
          Services décident mutuellement de l’utilisation des termes suivants
          pour la bonne compréhension de leurs engagements mutuels :
          <br />
          <br />
          Le Site : le site internet et l’application web accessibles à
          l’adresse{' '}
          <Link to="https://www.blocksto.fr/" target="_blank">
            www.blocksto.fr
          </Link>
          , tous sousdomaine(s) éventuel(s) compris, tant dans sa partie
          consultative que dans sa partie applicative. BLOCKSTO : désigne la
          société BLOCKSTO SAS, propriétaire, éditrice et représentante unique
          du Site, de l’enseigne commerciale et de la marque « BLOCKSTO », telle
          qu’elle est identifiée dans les{' '}
          <Link to="/mentions-legales">Mentions Légales</Link>.<br />
          <b>Les Utilisateurs</b> : désigne toute personne physique ou morale
          qui consulte le Site sans nécessairement s’inscrire ou souscrire aux
          Services de BLOCKSTO, et/ou les Utilisateurs Abonnés lorsqu’il n’est
          pas nécessaire de distinguer entre les catégories d’Utilisateurs.
          <br />
          <b>Les Abonnés</b> : désigne tout Utilisateur, détenant la pleine
          capacité juridique, qui s’inscrit sur le Site et utilise ses
          fonctionnalités, pour ouvrir et gérer un espace en ligne sécurisé.
          <br />
          <b>Les Destinataires</b> : désigne tout Utilisateur, détenant la
          pleine capacité juridique, qui s’inscrit sur le Site et utilise ses
          fonctionnalités, pour consulter des contenus via un espace en ligne
          sécurisé.
          <br />
          <b>Le Carnet</b> : désigne l’espace en ligne loué à l’Abonné, qui
          matérialise le carnet d’information du logement exigé et spécifié par
          la loi Climat et Résilience du 22 août 2021, prenant la forme d’un
          espace en ligne loué à l’Abonné par BLOCKSTO.
          <br />
          <b>Les Parties</b> : désigne ensemble BLOCKSTO et les Utilisateurs
          et/ou BLOCKSTO et les Abonnés, ayant consenti aux présentes CGUS.
          <br />
          <b>Tiers</b> : toute personne qui n’est pas partie aux CGUS.
          <br />
          <b>Les CGUS</b> : l’ensemble du présent contrat indivisible régissant
          entièrement et exclusivement l’inscription et l’utilisation du Site
          dans son ensemble, par tous les Utilisateurs. Les CGUS sont ici
          également dénommées « Conditions Générales d’Utilisation des Services
          » ou « Contrat ».
          <br />
          <b>La Politique Vie Privée</b> : le document indivisible, constituant
          l’ensemble des engagements de BLOCKSTO relatifs à la collecte et au
          traitement de données à caractère personnel effectués via le Site, et
          accessible en cliquant ici.
          <br />
          <b>Les Service(s)</b> : désigne l’ensemble des services proposés par
          BLOCKSTO par l’intermédiaire du Site. Les Informations : l’ensemble
          des informations publiées sur le Site par BLOCKSTO.
          <br />
          <b>Les Informations</b> : l’ensemble des informations publiées sur le
          Site par BLOCKSTO.
          <br />
          <b>Les Contenus</b> : l’ensemble des contenus renseignés par les
          Utilisateurs, qu’ils soient destinés à remplir leur(s) Carnet(s) et/ou
          à publier sur le Site (témoignages, commentaires, etc.).
          <br />
          <b>Les Données</b> : l’ensemble des données de gestion renseignées par
          l’Utilisateur pour utiliser le Site et ses fonctionnalités.
        </p>
        <p>
          <span className="article">Article 2. Objet</span>
          <br />
          <b>Article 2.1. Objet du Site</b>
          <ul>
            <li>
              L’activité de BLOCKSTO via le Site consiste à permettre aux
              Utilisateurs Abonnés la création et la gestion d’un Carnet
              numérique dédié aux informations du logement préconisés par la loi
              la loi Climat et Résilience du 22 août 2021, hébergé par BLOCKSTO
              pour le compte des Utilisateurs, ainsi que la gestion sécurisée
              des Contenus qui y sont stockés.
            </li>
            <li>
              L’accès aux Services est réservé aux Utilisateurs valablement
              inscrits (ci-après « les Abonnés »).
            </li>
          </ul>
          <b>Article 2.2. Objet des CGUS</b>
          <ul>
            <li>
              {' '}
              Les présentes CGUS régissent entièrement et exclusivement les
              relations contractuelles entre les Utilisateurs et BLOCKSTO, à
              l’exclusion de tout autre accord ou convention, eu égard à la
              consultation et toutes les utilisations du Site.
            </li>
            <li>
              {' '}
              Les CGUS régissent la consultation du Site, l’inscription, la
              gestion des abonnements, et l’utilisation de la partie applicative
              du Site.
            </li>
            <li>
              Les présentes CGUS ont également pour objet de définir le rôle
              d’intermédiaire technique de BLOCKSTO, les droits et obligations
              respectives des Parties dans le cadre de l’inscription des
              Utilisateurs sur le Site, ainsi que de leurs diverses utilisations
              de ce dernier, tant dans sa partie informative que dans ses
              parties interactives incluant ses fonctionnalités.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">Article 3. Application des CGUS</span>
          <br />
          <b>Article 3.1. Capacité à agir</b>
          <ul>
            <li>
              L’inscription sur le Site est ouverte exclusivement aux
              Utilisateurs identifiés, qui attestent être majeurs, en capacité
              juridique d’agir et de contracter avec BLOCKSTO, et devoir fournir
              tout justificatif complémentaire légitime éventuellement exigé par
              BLOCKSTO.
            </li>
            <li>
              Les Utilisateurs renoncent à rechercher la responsabilité de
              BLOCKSTO s’ils ne peuvent pas utiliser le Site du fait de leur
              défaillance dans la fourniture de ces Données.
            </li>
          </ul>
          <b>Article 3.2. Acceptation des CGUS</b>
          <ul>
            <li>
              L’application des présentes CGUS devient pleinement effective dès
              leur acceptation par l’Utilisateur, via le procédé du double clic
              valant signature numérique, admise comme moyen de preuve parfaite
              revêtant la même force que la signature physique.
            </li>
            <li>
              L’acceptation des présentes CGUS est donc exprimée en une seule
              fois, vaut pour l’ensemble, et emporte renonciation à toute
              éventuelle déclaration contraire préalable, écrite ou verbale.
            </li>
            <li>
              Cette acceptation est présumée valide du seul fait de la
              navigation lorsqu’elle est non suivie d’une action spécifique
              quelconque, de l’entrée sur le Site à la sortie de l’Utilisateur,
              à l’exclusion des clauses qui par nature ne trouveraient pas à
              s’appliquer
            </li>
            <li>
              Le fait pour toute personne physique ou morale d’utiliser le Site
              et les Services proposés emporte son adhésion et acceptation
              pleine et entière des présentes CGUS, ce qui est expressément
              reconnu par l’Utilisateur/l’Abonné, qui renonce expressément à se
              prévaloir de tout document contradictoire, qui serait inopposable
              aux Parties.
            </li>
          </ul>
          <b>Article 3.3. Indivisibilité et opposabilité</b>
          <ul>
            <li>
              Les présentes CGUS sont indivisibles ; elles comprennent le
              présent document et ses annexes éventuelles.
            </li>
            <li>
              Toute clause particulière des présentes CGUS expressément notifiée
              comme telle et explicitement consentie prévaut sur ses clauses
              générales en cas de contradiction éventuelle.
            </li>
            <li>
              Les Utilisateurs ne peuvent en aucun cas renoncer volontairement
              et unilatéralement à l’application d’une ou plusieurs clause(s)
              des présentes CGUS. La modification ou la substitution éventuelle
              de la part de BLOCKSTO d’une ou plusieurs clause(s), pour quelque
              motif que ce soit, ne vaut pas renonciation par lui à l’ensemble.
            </li>
            <li>
              Également, dans le cas où une éventuelle procédure judiciaire
              aboutissant à la reconnaissance de l’invalidité d’une ou plusieurs
              clause(s) des présentes CGUS, cette invalidité ne vaudrait en
              principe que pour celle(s) concernée(s) par ladite procédure, à
              moins que l’invalidité de l’ensemble ne soit prévue par la Loi ou
              acquise par décision de justice exécutoire.
            </li>
            <li>
              Toute disposition contraire aux présentes CGUS qui serait invoquée
              par l’Utilisateur sera, à défaut d’acceptation expresse par les
              deux Parties, inopposable à BLOCKSTO, quel que soit le moment où
              elle aura pu être portée à sa connaissance. Hormis toutes
              conditions spécifiques prévues dans le cadre d’un contrat consenti
              entre les Parties, aucun document ne peut prévaloir sur les
              présentes CGUS.
            </li>
          </ul>
          <b>Article 3.4. Interprétation des clauses</b>
          <ul>
            <li>
              Dans le cas éventuel de conflit d’interprétation entre le contenu
              des clauses des présentes CGUS et leurs titres, la priorité sera
              accordée au contenu des stipulations.
            </li>
            <li>
              L’ensemble des délais applicables en vertu des présentes CGUS sont
              considérés comme ouvrés.
            </li>
          </ul>
          <b>Article 3.5. Modification du Site et modification des CGUS</b>
          <ul>
            <li>
              BLOCKSTO se réserve le droit exclusif de modifier le contenu du
              Site et les termes des présentes CGUS, en partie ou en totalité,
              ainsi que de proposer des conditions spéciales, annexe(s) ou
              avenant(s), à tout moment et sans avoir à s’en justifier, dès lors
              que cette ou ces modification(s) est ou sont rendue(s)
              nécessaire(s) par un objectif commercial ou légal légitime
              (notamment en fonction de l'évolution des techniques et/ou de la
              législation), et sans que lesdites modifications n’entraînent de
              modifications substantielles. La ou les modification(s)
              éventuelle(s) ne saurai(en)t être assimilée(s) à une renonciation
              par BLOCKSTO à l’ensemble des CGUS.
            </li>
            <li>
              La modification des présentes CGUS par une décision de justice ne
              peut invalider que la ou les clause(s) invalidée(s), à moins que
              l’invalidité de l’ensemble ne soit prévue par la Loi ou acquise
              par décision de justice exécutoire. Le cas échéant BLOCKSTO
              s’engage à supprimer et remplacer immédiatement ladite clause par
              une clause juridiquement valide.
            </li>
            <li>
              Sauf si les Parties en sont convenues autrement et explicitement
              par un accord formel, les présentes CGUS ne pourront être
              modifiées autrement que dans les formes ci-décrites.
            </li>
          </ul>
          <b>Article 3.6. Acceptation des modifications des CGUS</b>
          <ul>
            <li>
              <p>
                <span
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  Les modifications des présentes CGUS portant sur des
                  obligations essentielles
                </span>
                ou impliquant une diminution des droits des Utilisateurs{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  devront être approuvées explicitement par ces derniers,
                </span>{' '}
                par tous moyens clairs et dénués d’ambiguïté permettant
                d’attester ce consentement par une action volontaire de l’Abonné
                (notamment, par acceptation directe sur le Site via une nouvelle
                case à cocher, un clic sur un bouton inclus dans un courriel
                envoyé par BLOCKSTO, etc.).
                <br />. Les nouvelles conditions seront pleinement applicables
                dès leur acceptation par l’Abonné, et régiront dès lors la
                relation contractuelle nouvelle jusqu’à leur terme.
                <br />. Les Abonnés admettent que dans le cas où ils refusent
                les nouvelles CGUS, leur décision empêche a priori la
                continuation des relations contractuelles, et que le bénéfice
                des Services et des fonctionnalités associées peuvent être
                suspendues pendant un délai maximum de un (1) mois, à compter de
                la notification de BLOCKSTO les invitant à accepter lesdites
                modifications. Pendant ce délai, ils sont susceptibles de
                recevoir un ou plusieurs courriel(s) de relance de la part de
                BLOCKSTO, les invitant encore à accepter les nouvelles CGUS.
                <br />. Les Abonnés admettent que leur compte peut être
                provisoirement désactivé tant qu’ils n’ont pas explicitement
                approuvé les nouvelles CGUS.
                <br />. A défaut d’approbation explicite de la part des Abonnés
                concernés dans le délai précité, ces derniers admettent que leur
                compte peut être purement et simplement clôturé et leur
                abonnement éventuel résilié valablement par BLOCKSTO, qui
                s’engage alors à procéder au remboursement de toutes sommes
                valablement dues au titre du temps d’abonnement éventuellement
                restant à courir et des Services non consommés, calculées au
                prorata du nombre de jours/mois restant jusqu’à la prochaine
                date anniversaire de l’abonnement concerné.
              </p>
            </li>
            <li>
              <p>
                Il est entendu entre les Parties que les modifications des
                présentes CGUS concernant les clauses relatives à la
                tarification des Produits et Services ne seront applicables
                qu’aux contrats futurs et jamais aux contrats en cours. En
                conséquence, les tarifs applicables aux Services déjà souscrits
                ne seront jamais modifiés en cours de contrat, mais peuvent
                l’objet d’une mise à niveau volontaire, incluant le paiement du
                nouveau tarif à partir de la validation de la souscription, et
                la mise à niveau des fonctionnalités ainsi souscrites.
                <br />. Les autres clauses que celles relatives aux tarifs
                applicables ne peuvent être modifiées qu’en respectant les
                termes du présent article.
              </p>
            </li>
            <li>
              <p>
                <span
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  Les modifications des présentes CGUS portant sur des
                  obligations accessoires
                </span>{' '}
                ou n’impliquant pas une diminution des droits des Utilisateurs
                feront l’objet d’une{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  notification préalable de la part de BLOCKSTO à l’attention de
                  ces derniers,
                </span>
                qui n’auront pas besoin d’accomplir quelconque démarche ou
                approuver explicitement ladite modification pour que celle-ci
                soit applicable.
                <br />. Dans ce cas, BLOCKSTO s’engage à respecter un préavis
                raisonnable de minimum quinze (15) jours pour notifier les
                Utilisateurs de la modification des CGUS.
                <br />. Les nouvelles conditions seront pleinement applicables
                et régiront la relation contractuelle nouvelle à compter de leur
                publication sur le Site, et se poursuivront jusqu’à leur terme.
              </p>
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 4. Devoir de collaboration et d’information
          </span>
          <br />
          <b>Article 4.1. Devoir de collaboration et d’information mutuelle</b>
          <ul>
            <li>
              Les Parties s’engagent à collaborer activement et de bonne foi à
              la bonne exécution des présentes CGUS et être engagé par une
              obligation générale d’information mutuelle.
            </li>
            <li>
              L’Utilisateur admet sans réserve son propre devoir de
              collaboration et s’engage à fournir des informations exactes et
              authentiques, et à faire état de ses besoins spécifiques le cas
              échéant.
            </li>
            <li>
              La responsabilité de BLOCKSTO ne saurait donc être recherchée, en
              tout ou partie, pour manquement à ses obligations de conseil,
              résultant du silence de l’Utilisateur sur un ou des élément(s)
              pouvant déterminer son engagement, notamment au regard de l’usage
              qu’il compte en faire, si cet usage se démarque des usages
              standards des Services proposés.{' '}
            </li>
            <li>
              {' '}
              L’Utilisateur s’engage en général, pendant et après l’exécution
              des présentes CGUS, à agir de bonne foi et à mettre en œuvre tous
              les moyens raisonnables pour garantir BLOCKSTO contre toute perte,
              dommage ou réclamation concernant ou non l’usage des Services.
            </li>
            <li>
              L’Utilisateur répond de sa responsabilité résultant de ses propres
              activités, professionnelles ou non, sur le site ou non.
            </li>
            <li>
              L’Utilisateur répond de sa responsabilité résultant de la
              création, la divulgation, la diffusion, le transfert de toute
              fausse publicité concernant BLOCKSTO et ses éventuels partenaires.
            </li>
          </ul>
          <b>Article 4.2. Force probante des correspondances électroniques</b>
          <ul>
            <li>
              En application des articles 1316 et suivants du Code civil et, le
              cas échéant, de l’article L.110-3 du Code de commerce, il est
              admis entre les Parties que, sauf disposition particulière des
              présentes CGUS ou dans les dispositions légales en vigueur, les
              correspondances échangées entre les Utilisateurs/Abonnés entre eux
              et/ou entre les Utilisateurs et BLOCKSTO et/ou entre les
              Utilisateurs/Abonnés et les Destinataires par voie de courrier
              électronique, ou directement sur le Site via la messagerie
              instantanée, ainsi que les informations délivrées par ces moyens,
              font foi entre les Parties et pour BLOCKSTO, tant qu’aucun écrit
              contradictoirement authentifié et signé, venant remettre en cause
              ces informations informatisées, ne soit produit.
            </li>
            <li>
              {' '}
              Les éléments tels que le moment de la réception ou de l’émission,
              ainsi que la qualité des données reçues feront foi par priorité
              telles que figurant sur les systèmes d’information de BLOCKSTO, ou
              telles qu’authentifiées par les procédures informatisées de la
              société sauf à en apporter la preuve écrite et contraire par les
              Utilisateurs.
            </li>
            <li>
              De convention expresse, les données issues du système informatique
              de BLOCKSTO ou de ses partenaires constituent des écrits au sens
              de l’article 1316-1 du Code civil, le lien entre ces données et la
              Partie à laquelle elles se rattachent étant présumé, jusqu'à
              preuve contraire. Elles font donc preuve entre les Parties et leur
              sont opposables de la même manière, dans les mêmes conditions et
              avec la même force probante que tout document qui aurait été écrit
              et signé sur support papier par les Parties.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">Article 5. Indépendance réciproque</span>
          <br />
          <b>Article 5.1. Déclaration d’indépendance réciproque</b>
          <ul>
            <li>
              Les Parties se déclarent réciproquement totalement indépendantes.
              Les présentes CGUS n’ont en aucun cas pour objet ni pour effet
              d’associer et/ou de confondre les activités respectives des
              Parties, et ne constituent en rien un mandat entre les Parties ni
              un contrat de représentation quelconque.
            </li>
            <li>
              Elles conservent chacune la charge et le monopole de toutes
              décisions en matières sociale, comptable, fiscale, et juridique au
              sens large. La défaillance même partielle d’une des Parties en ces
              matières ne saurait en aucun cas entraîner la responsabilité de
              l’autre Partie.
            </li>
            <li>
              De ce fait, les Parties se déclarent également indépendantes au
              regard de toutes relations contractuelles éventuellement
              souscrites auprès de tous tiers et sous leurs propres
              responsabilités respectives. Les Parties répondent, dans les
              limites fixées par la loi et par toute convention, du fait des
              tiers auxquels ils sont éventuellement liés par contrat, de tout
              dommage créé à l’autre Partie dans le cadre de l’exécution dudit
              contrat.
            </li>
          </ul>
          <b>Article 5.2. Indépendance de BLOCKSTO et utilisation d’Internet</b>
          <ul>
            <li>
              L’Utilisateur admet sans réserve décharger BLOCKSTO de toute forme
              de responsabilité pour sa propre utilisation des réseaux sociaux
              et autres plateformes numériques de toutes natures sur Internet.
              Il atteste utiliser Internet et ces réseaux tiers sous sa propre
              responsabilité, relativement à tous dommages éventuels du fait de
              l’inscription, de l’utilisation, et/ou de la publication de
              contenus de toutes natures, et/ou de tous litiges, exclusions ou
              réclamations, liés directement ou non aux réseaux tiers, que ces
              activités soient liées ou non à l’exécution des présentes CGUS.
            </li>
            <li>
              La présence de boutons de réseaux sociaux et/ou d’autres boutons
              permettant d’interagir avec d’autres sites et plateformes
              numériques sur internet à partir du Site n’équivaut pas et ne
              constitue en rien une liaison contractuelle quelconque entre ces
              derniers et BLOCKSTO.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 6. Maintenance et disponibilité du Site et des Services
          </span>
          <br />
          <b>Article 6.1. Accès au Site</b>
          <ul>
            <li>
              BLOCKSTO atteste mettre en œuvre les moyens nécessaires à la
              disponibilité du Site, pour permettre un accès au Site 24 heures
              sur 24, 7 jours sur 7, et à une utilisation optimale au niveau
              technique, sauf en cas de force majeure, et reste à cet égard
              soumis à une simple obligation de moyens.
            </li>
            <li>
              L’Utilisateur admet que la consultation du Site est gratuite, et
              que tous frais supplémentaires supportés par l’Utilisateur dans ce
              cadre, et non imputables à BLOCKSTO, ne peuvent être d’aucune
              manière reprochée à BLOCKSTO ni supportés par ce dernier.
            </li>
            <li>
              L’Utilisateur dégage également BLOCKSTO de toute responsabilité en
              cas de dysfonctionnement, d’éventuelles suspensions de services
              temporaires et/ou d’impossibilité d’accès au Site du fait d’un
              événement échappant à son contrôle (aléas techniques,
              perturbations sur le réseau internet etc.), ponctuel et
              imprévisible pour BLOCKSTO, de sorte que ce dernier n’a pas été en
              mesure de prévenir l’Utilisateur.
            </li>
            <li>
              BLOCKSTO se réserve le droit, à tout moment et sans que sa
              responsabilité ne puisse être engagée, de :
              <br />- suspendre ou limiter l’accès au Site ponctuellement pour
              procéder à des opérations de maintenance ou des mises à jour ;
              <br />- suspendre, interrompre ou limiter l'accès à tout ou partie
              du Site, et/ou en réserver l'accès totalement ou non, à une
              catégorie déterminée d’Utilisateurs ;
              <br />- supprimer toute information pouvant en perturber le
              fonctionnement ou entrant en contravention avec les lois
              nationales ou internationales, ou avec les règles de la
              Nétiquette.
            </li>
          </ul>
          <b>Article 6.2. Utilisation(s) interdite(s) du Site</b>
          <ul>
            <li>
              L’Utilisateur admet accéder au Site et l’utiliser sous sa propre
              responsabilité. Les informations qui figurent sur le Site ne
              peuvent en outre être utilisées à des fins commerciales,
              politiques, publicitaires et pour toute forme de sollicitation
              commerciale (et notamment l'envoi de courriers électroniques non
              sollicités).
            </li>
          </ul>
        </p>
        <p>
          <span className="article">Article 7. Modération de BLOCKSTO</span>
          <ul>
            <li>
              BLOCKSTO assure une surveillance active du contenu du Site dans
              son ensemble, au regard de l’ensemble du droit positif et des
              pratiques standards sur Internet. Les Utilisateurs et Abonnés
              admettent sans réserve que l’ensemble des propos et contenus de
              toute nature éventuellement publiés sur le Site sont peuvent
              toujours être vérifiés par BLOCKSTO, en vertu de son pouvoir
              discrétionnaire et unilatéral de modération et de contrôle
              applicable sur l’ensemble du Site.
            </li>
            <li>
              BLOCKSTO se réserve ainsi le droit, à tout moment et sans avoir à
              s’en justifier, de supprimer toute information pouvant perturber
              le fonctionnement du Site ou violant les présentes CGUS, les lois
              nationales ou internationales, ou les règles de la Nétiquette,
              ainsi que d’exclure les auteurs ou les responsables de la
              publication desdits contenus, de suspendre ou clôturer leur
              compte, et ainsi d’annuler les fonctionnalités souscrites, et sans
              préjudice de toute procédure légale ouverte par le droit en
              vigueur en réparation d’un éventuel dommage.
            </li>
            <li>
              BLOCKSTO peut toutefois communiquer avec l’Utilisateur/Abonné
              concerné dans un tel cas, afin de rechercher une solution
              préalablement à toute sanction ci-dessus décrite. Ce dernier peut
              également communiquer avec BLOCKSTO dans ce cadre, mais ce dernier
              ne s’engage pas à répondre ou à faire bénéficier de quelconques
              Services l’Utilisateur défaillant.
            </li>
            <li>
              Les Utilisateurs comprennent que BLOCKSTO est toujours légitime à
              communiquer aux pouvoirs publics les infractions commises sur le
              Site, et se réserve le droit de faciliter toutes procédures et/ou
              toutes poursuites dans ce cadre, notamment en facilitant l’accès
              aux données concernées.
            </li>
            <li>
              Les Utilisateurs peuvent communiquer avec BLOCKSTO, et participer
              à cette modération en signalant à tout moment tout comportement ou
              tout contenu, via l’adresse de courriel suivante :
              legal@blocksto.fr
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 8. Support Utilisateur dédié aux Abonnés
          </span>
          <br />
          <b>Article 8.1. Règles applicables au Support</b>
          <ul>
            <li>
              BLOCKSTO met en place un Service Utilisateur pour aider les
              Abonnés à utiliser le Site, accessible soit en pied de page du
              Site, soit via leur espace personnel dès lors qu’ils sont inscrits
              et connectés.
            </li>
            <li>
              BLOCKSTO s’engage à prendre en charge les demande de support
              effectuées par ce biais dans les meilleurs délais, du lundi au
              vendredi de 10h à 18h. Les Utilisateurs comprennent et admettent
              que les demandes de tickets ne sont reçues qu’à ces horaires.
            </li>
            <li>
              BLOCKSTO s’engage à répondre à toute demande et à proposer toute
              solution de satisfaction pour aider l’Abonné à s’inscrire et/ou à
              prendre en main et utiliser le Site dans les meilleures
              conditions, et ce dans un délai de quarante-huit (48) heures, sans
              toutefois dépasser un délai raisonnable qui ne saurait excéder
              sept (7) jours.
            </li>
            <li>
              . Sont comprises dans le champ de ce service : <br />- les
              demandes relatives à la prise en main des fonctionnalités du Site
              (bugs basiques éventuels, difficultés de prise en main,
              inscription et utilisation du Site en général) ;
              <br />- les demandes relatives à la création de leur(s) Carnet(s)
              et la gestion de leurs Contenus, ainsi qu’aux conditions
              applicables aux partage de Contenus et/ou de Carnets.
            </li>
            <li>
              Ne sont pas comprises dans le champ de ce service :<br />- les
              demandes relatives aux données personnelles éventuellement
              collectées via le Site, régies par la{' '}
              <Link
                to="https://www.blocksto.com/politique-vie-privee"
                target="_blank"
              >
                Politique Vie Privée
              </Link>
              de BLOCKSTO ;<br />- les éventuelles désaccords entre les
              Utilisateurs ;<br />- les demandes relatives à des conseils en
              matière de ressources techniques en général ;
              <br />- les demandes relatives aux codes, ressources, méthodes,
              modèles, et savoir-faire de BLOCKSTO ;
              <br />- les demandes de conseils en matière fiscale, et juridique
              en général.
            </li>
          </ul>
          <b>Article 8.2. Modération du Service Utilisateurs</b>
          <ul>
            <li>
              . BLOCKSTO se réserve le droit exclusif d’inclure ou non les
              demandes, au cas par cas, dans le champ de son intervention
              personnelle, et de proposer, voire d’imposer un délai
              supplémentaire pour répondre utilement à la demande de
              l’Utilisateur, soit que la réponse nécessite une recherche plus
              approfondie, soit qu’elle nécessite l’intervention d’un
              prestataire tiers.
            </li>
            <li>
              Lorsqu’un prestataire extérieur intervient ainsi sur les
              installations de l’Utilisateur, ledit prestataire supporte la
              responsabilité totale de son intervention, BLOCKSTO ne servant
              alors que d’intermédiaire et n’assurant que la maintenance du Site
              lui-même.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">Article 9. Sécurité informatique</span>
          <br />
          <b>
            Article 9.1 Conditions de sécurité et utilisation normale du Site
          </b>
          <ul>
            <li>
              BLOCKSTO s’engage à fournir et à maintenir le Site dans les
              meilleures conditions de sécurité possibles pour permettre une
              utilisation optimale, et dans la limite de l’utilisation normale
              de celui-ci, telle que décrite dans les présentes CGUS. BLOCKSTO
              ne peut garantir totalement l’absence d’anomalies, d’erreurs ou de
              bugs ponctuels, de même qu’il ne garantit aucune compatibilité
              avec tout matériel ou tout environnement informatique en
              particulier, et exclut ainsi toute responsabilité en cas de
              difficulté ou d’impossibilité d’utiliser le Site et/ou de
              téléchargement de virus, ou de quelconque forme d’attaque
              informatique subie par l’Utilisateur et pouvant présenter un lien
              direct ou indirect avec son utilisation du Site.
            </li>
          </ul>
          <b>Article 9.2. Menaces de sécurité pour BLOCKSTO</b>
          <ul>
            <li>
              Les Utilisateurs s'engagent à ne pas pénétrer les systèmes
              informatiques de BLOCKSTO, ni ceux de ses fournisseurs et
              partenaires, ni tenter de le faire, en utilisant ou non des
              données confidentielles régies par les présentes CGUS et/ou par la
              <Link to="" target="_blank">
                Politique Vie Privée.
              </Link>
            </li>
            <li>
              Notamment, sont strictement interdits sous peine de poursuites
              judiciaires, l’accès frauduleux et/ou le maintien frauduleux sur
              le Site, susceptible d’entraver de quelconque manière que ce soit
              son fonctionnement, l’introduction et/ou la modification de
              données contenues dans ce dernier, ainsi que tout comportement de
              nature à interrompre, suspendre, ralentir et empêcher la
              continuité du Site dans son ensemble, toute intrusion ou tentative
              d'intrusions dans les systèmes informatiques de BLOCKSTO, tout
              détournement de ses ressources système, toute action de nature à
              imposer une charge disproportionnée sur ses infrastructures.
            </li>
            <li>
              BLOCKSTO rappelle que ces faits peuvent constituer des délits
              sanctionnés notamment par le droit pénal et sont susceptibles
              d’entraîner des poursuites judiciaires.
            </li>
          </ul>
          <b>
            Article 9.3. Responsabilité de l’Utilisateur en matière de sécurité
            informatique
          </b>
          <ul>
            <li>
              La responsabilité de BLOCKSTO ne saurait être engagée en cas de
              survenance de problèmes techniques et/ou d’une cyber-attaque
              affectant les locaux, les installations et espaces numériques, les
              logiciels, et le matériel appartenant à ou placés sous la
              responsabilité de l’Utilisateur, et ce malgré toutes les mesures
              de sécurité éventuelles prises par BLOCKSTO.
            </li>
            <li>
              L’Utilisateur déclare avoir pris connaissance des caractéristiques
              et des limites d'Internet rendant potentiellement vulnérables les
              données y circulant, ainsi que la responsabilité de l’émetteur de
              toute communication de contenus informatiques (notamment protégés
              ou sensibles).
            </li>
            <li>
              Les identifiants et mots de passe permettant à l’Abonné de se
              connecter au Site sont strictement confidentiels et placés sous
              son entière responsabilité. BLOCKSTO décline toute responsabilité
              pour tout dommage qui pourrait résulter de leur perte éventuelle
              et/ou de l’utilisation non autorisée par un tiers de l’espace
              personnel de l’Abonné concerné. BLOCKSTO met en place une
              procédure de réinitialisation de mot de passe permettant d’en
              générer un nouveau.
            </li>
            <li>
              Les mots de passe ne sont jamais visibles en clair par BLOCKSTO.
            </li>
            <li>
              L’Utilisateur peut à tout moment informer BLOCKSTO s'il constate
              une faille de sécurité (concernant ou non la communication
              volontaire ou le détournement de son identifiant et/ou mot de
              passe), afin que BLOCKSTO puisse prendre sans délai toute mesure
              adaptée afin d’y faire remédier.
            </li>
          </ul>
          <b>Article 9.4. Responsabilité pour les liens hypertextes</b>
          <ul>
            <li>
              La mise en place d’éventuels liens hypertextes entrants (renvoyant
              vers le Site), y compris à titre de référence professionnelle en
              faveur de BLOCKSTO, et à partir de n’importe quel site et de
              n’importe quel terminal, est a priori libre sous réserve de
              respecter les conditions suivantes :
              <br />
              - la pratique des liens ne doit pas être systématique ni abusive;
              <br />
              - le lien a été préalablement vérifié et ne comporte a priori
              aucun risque informatique ;
              <br />
              - le lien n’est pas associé à une description fausse, obsolète,
              incomplète de BLOCKSTO, du Site et de ses activités ;
              <br />- le lien ne viole en outre aucun droit de propriété
              intellectuelle et ne peut en rien représenter un risque de
              confusion dans l’esprit du public ou un élément de concurrence
              déloyale.
            </li>
            <li>
              Les Utilisateurs admettent que les publications litigieuses
              peuvent être vérifiées par BLOCKSTO, et s’engagent à retirer ledit
              lien sur simple demande écrite de ce dernier, qui se réserve en
              tout état de cause le droit d’agir, en justice ou non, pour
              obtenir la suppression des contenus litigieux et/ou réparation
              d’un éventuel dommage en résultant.
            </li>
            <li>
              La responsabilité de BLOCKSTO est donc strictement exclue pour
              tout dommage résultant d’un quelconque problème technique et/ou de
              faille de sécurité, ou de toute violation des CGUS et/ou de la
              législation en vigueur, provenant d’un tel lien hypertexte.
            </li>
          </ul>
          <b>
            Article 9.5. Sécurité et confidentialité des échanges de Contenus
            depuis les Carnets
          </b>
          <ul>
            <li>
              Le partage des Contenus par les Abonnés à l’attention des autres
              Utilisateurs est strictement sécurisée par chiffrement en entrée
              et en sortie des serveurs de BLOCKSTO, de sorte que seuls l’Abonné
              et le destinataire du Contenu peuvent effectivement le consulter.
            </li>
            <li>
              BLOCKSTO s’engage, pour lui-même ainsi que pour tout son personnel
              (en interne comme externalisé), à une obligation de secret
              renforcée à l’égard des Abonnés et de leurs Contenus, et
              s’interdit strictement d’y accéder, sauf en cas de litige et/ou de
              procédure judiciaire exigeant de les révéler, et ce à des fins de
              preuve éventuelle.
            </li>
            <li>
              A défaut de survenance de ces cas spécifiques, BLOCKSTO ne peut
              que visualiser les types de Contenus et les mouvements effectués
              par les Abonnés (chargement/partage ; fichiers textes, vidéos,
              photos, etc.).
            </li>
          </ul>
        </p>
        <p>
          <span className="article">Article 10. Propriété intellectuelle</span>
          <b>
            Article 10.1. Interdiction générale relative aux actifs
            intellectuels de BLOCKSTO
          </b>
          <ul>
            <li>
              . L’Utilisateur s’engage à respecter les droits de propriété
              intellectuelle éventuellement attachés à BLOCKSTO, pendant et
              après l’exécution des présentes CGUS.
            </li>
            <li>
              Il est admis sans réserve que toute reproduction, représentation,
              utilisation, usage, imitation ou adaptation, diffusion, vente,
              transmission, ou mise à disposition de tiers, totale ou partielle,
              par quelque procédé et sur quelque support que ce soit, des
              éléments constituant et/ou représentant la marque, l’enseigne
              commerciale et le Site (textes, chiffres, codes, noms, dessins,
              images, logos, slogans, marques, enseignes, et tout autre élément
              potentiellement représentatif des actifs intellectuels distinctifs
              de BLOCKSTO), est interdit sans accord écrit, préalable et exprès
              de la part de BLOCKSTO, et susceptible d’entraîner des poursuites
              selon toutes procédures en vigueur.
            </li>
            <li>
              Le fait pour BLOCKSTO de ne pas engager de procédure dès la prise
              de connaissance de toute utilisation non autorisée des éléments
              précités ne vaut pas acceptation par lui desdites utilisations, ni
              renonciation à d’éventuelles poursuites.
            </li>
          </ul>
          <b>Article 10.2. Protection du Site dans son ensemble</b>
          <ul>
            <li>
              BLOCKSTO est titulaire de tous les droits de propriété
              intellectuelle ou d’usage relatifs au Site ou aux éléments
              accessibles sur le Site, notamment sous forme de textes,
              photographies, images, vidéos, icônes, sons, codes, chiffres ou
              bases de données, à l’exception des Contenus qui sont
              explicitement attribués aux Abonnés, et des widgets de réseaux
              sociaux.
            </li>
            <li>
              En particulier, les photos et illustrations graphiques du Site
              sont publiées dans le respect des droits d’auteurs qui y sont
              attachés, soit qu’elles aient été réalisées directement par
              BLOCKSTO, soit qu’elles appartiennent au domaine public, soit
              qu’elles proviennent de sources légales, y compris des
              prestataires de services ou des banques d’images qui garantissent
              le respect de ces droits (notamment « Pixabay », « Freepic », «
              Unsplash »).
            </li>
            <li>
              Également, les textes et les vidéos présents sur le Site sont soit
              écrits/réalisés directement par BLOCKSTO soit utilisés dans le
              respect des droits des auteurs missionnés par ce dernier.
            </li>
            <li>
              BLOCKSTO en conserve la légitimité et/ou la propriété, et se
              réserve la possibilité d’actions en justice concernant toute
              utilisation contraire aux présentes CGUS et aux dispositions
              légales en vigueur
            </li>
            <li>
              L’Utilisateur s’engage, concernant les éléments précités, à ne pas
              les reproduire, représenter, copier, modifier, adapter, publier,
              redistribuer, revendre, transmettre, céder, en totalité ou non,
              sur quelque support que ce soit, par quelque moyen que ce soit, de
              quelque manière que ce soit et à quelconque tiers, ni à les
              utiliser ou exploiter sans l’autorisation écrite et préalable ou
              dans d’autres conditions que celles prévues par les présentes
              CGUS.
            </li>
            <li>
              Par exception, BLOCKSTO autorise leur citation courte ou leur
              republication sous réserve de citer explicitement BLOCKSTO et/ou
              leurs auteurs et d’insérer un lien pointant vers le Site.
            </li>
          </ul>
          <b>- Crédits :</b>
          <br />° Graphisme du Site, logo, habillage et scripts `&gt;` réalisés
          en interne par BLOCKSTO
          <br />° Vidéos témoignages `&gt;` réalisés par les Abonnés satisfaits,
          sur demande de BLOCKSTO, et sous réserve de signer une autorisation
          spécifique de droits à l’image.
          <br />
          <br />
          <b>Article 10.3. Les noms de domaines</b>
          <ul>
            <li>
              Les noms de domaines suivants, tous sous-domaines éventuels
              compris, appartiennent à BLOCKSTO et sont protégés du fait de leur
              enregistrement et de leur exploitation commerciale :
              www.blocksto.fr
            </li>
          </ul>
          <b>Article 10.4. Parasitisme économique et concurrence déloyale</b>
          <ul>
            <li>
              L’exploitation commerciale, par tout tiers non autorisé, de nom(s)
              et de signe(s) distinctif(s) de la marque, du nom de domaine, du
              Site et de l’enseigne commerciale de BLOCKSTO, de même que toute
              action ayant pour objet ou pour effet de lui porter préjudice par
              création d’une quelconque forme de confusion dans l’esprit du
              public, est susceptible d’entraîner des poursuites pour
              concurrence déloyale et/ou parasitisme économique selon toutes
              procédures en vigueur.
            </li>
            <li>
              En particulier, il est admis sans réserve que toute éventuelle
              copie ou reproduction, en partie ou en totalité, des contenus du
              Site, pour les reproduire entièrement ou non sur un autre site
              internet ou tout autre support de diffusion, dans un but
              d’exploitation commerciale, sera considérée comme une concurrence
              déloyale susceptible de poursuites selon toutes procédures en
              vigueur.
            </li>
            <li>
              Également le fait d’utiliser le Site à des fins de prospection
              commerciale ou publicitaires sera considéré comme une concurrence
              déloyale susceptible de poursuites judiciaires.
            </li>
          </ul>
          <b>
            Article 10.5. Propriété intellectuelle sur les Contenus des Carnets
          </b>
          <ul>
            <li>
              BLOCKSTO s’engage absolument à respecter la propriété
              intellectuelle éventuellement attachée aux Contenus insérés dans
              les Carnets des Utilisateurs, et à ne jamais les exploiter,
              diffuser, reproduire, céder, transférer, ou communiquer d’aucune
              manière que ce soit et pour quelque motif que ce soit. De
              principe, BLOCKSTO ne peut pas accéder à ces Contenus, en vertu de
              son obligation de secret applicable aux Carnets des Abonnés.
            </li>
            <li>
              Les Abonnés s’engagent quand à eux à s’abstenir d’y intégrer et de
              manipuler frauduleusement tout contenu de propriété intellectuelle
              dont il ne sont pas eux-mêmes titulaires ou pour lesquelles ils
              n’ont pas obtenu toute autorisation formelle le leur permettant
              valablement. BLOCKSTO dégage totalement sa responsabilité dans le
              cas contraire.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 11. Autorisation de droit à l’image et références
            professionnelles
          </span>
          <br />
          <b>Article 11.1. Utilisation de références anonymisées</b>
          <ul>
            <li>
              Les Utilisateurs admettent pleinement accorder à BLOCKSTO le droit
              exprès d’utiliser, de publier, représenter, reproduire,
              communiquer au public depuis le Site et depuis tout réseau de
              communication électronique connu ou à venir, sur tous sites
              internet ou espaces numériques et supports marketing quelconques,
              en ligne ou non, toutes références{' '}
              <span style={{ textDecoration: 'underline' }}>
                anonymes ou anonymisées
              </span>{' '}
              (pas de photographie ni de vidéo), notamment graphiques
              (logotypes, illustrations, etc.) illustrant en partie le profil
              et/ou certaines activités de l’Utilisateur/Abonné sur le Site, et
              ce à des fins d’exécution des présentes, et/ou à des fins
              promotionnelles et/ou de prospection commerciale
            </li>
          </ul>
          <b>
            Article 11.2. Utilisation de références et des témoignages clients
            non anonymes
          </b>
          <ul>
            <li>
              Les Utilisateurs peuvent ponctuellement laisser un témoignage non
              anonyme à BLOCKSTO, et dans ce cadre autoriser ce dernier, par la
              signature d’une autorisation expresse qui leur sera fournie dans
              ce cadre, à enregistrer, reproduire et représenter leur image
              et/ou leur voix et/ou leurs propos, écrits ou non, dans le cadre
              de sa communication professionnelle, sur tous types de supports, à
              titre gratuit, sans limitation géographique et pour une durée a
              priori illimitée sous réserve de retrait de l’autorisation ainsi
              fournie.
            </li>
            <li>
              Cette autorisation permet également de modifier les Contenus
              concernés en cas de nécessités ou contraintes techniques
              (compressions numériques des fichiers mis en ligne, mise en forme,
              etc.), et peut être retirée à tout moment par l’Utilisateur selon
              les modalités qui y sont décrites.
            </li>
            <li>
              Toutefois, les utilisations ainsi autorisées se limitent au cadre
              de l’exploitation commerciale du Site et des présentes
              stipulations, sauf accord écrit, exprès et préalable consenti
              entre les Parties.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 12. Protection des codes sources
          </span>
          <ul>
            <li>
              Les Utilisateurs admettent que les codes sources permettant
              l’édition et l’utilisation du Site sont a priori inaccessibles
              pour eux, appartiennent à BLOCKSTO et constituent un
              investissement réel et sérieux de la part de ce dernier.
            </li>
            <li>
              Par conséquent, toute action ayant pour objet ou pour effet de
              permettre l’accès à ces codes sources, qui nécessite par nature
              des connaissances techniques, sera considérée comme malveillante
              par BLOCKSTO, qui se réserve le droit d’engager toutes poursuites
              judiciaires en vue de faire cesser l’éventuel préjudice qui en
              résulte pour lui et de le faire réparer le cas échéant.
            </li>
            <li>
              Dès lors, et à compter de la signature des présentes CGUS, les
              Utilisateurs s’engagent à ne pas faire obstacle aux intérêts
              légitimes de BLOCKSTO, y compris via l’accès et l’utilisation non
              autorisée des codes sources.
            </li>
          </ul>
        </p>
      </>
    ),
  },
  {
    title: 'Partie 2. Validation des Inscriptions et souscription aux Services',
    content: (
      <>
        <p>
          <span className="article">
            Article 13. Validation des inscriptions
          </span>
          <br />
          <b>Article 13.1. Utilisation du Site sans inscription</b>
          <ul>
            <li>
              Les Utilisateurs non-inscrits peuvent consulter le Site et ses
              rubriques gratuites.
            </li>
            <li>
              Les Utilisateurs non-inscrits admettent ne pas pouvoir bénéficier
              de l’intégralité des fonctionnalités réservées aux Utilisateurs
              inscrits (les Abonnés), et qu’en aucun cas ils ne peuvent en
              revendiquer le bénéfice à BLOCKSTO qui se décharge de toute
              responsabilité pour tout dommage résultant de l’absence
              d’inscription conforme aux présentes CGUS.
            </li>
            <li>
              Les modalités d’inscription sont les mêmes pour tous les
              Utilisateurs, qui peuvent ensuite choisir de souscrire aux
              Services étendus de BLOCKSTO.
            </li>
          </ul>
          <b>Article 13.2. Données à fournir pour l’inscription</b>
          <ul>
            <li>
              Lors de leur inscription, les Utilisateurs s’engagent à effectuer
              les formalités déclaratives et à remplir les champs obligatoires
              demandés sous leur pleine responsabilité, et s’engage à renseigner
              des informations exactes, complètes et sincères. Ils s'engagent à
              ne pas fournir des informations inexactes, à ne pas utiliser une
              fausse identité et plus généralement des données de nature à
              tromper ou induire en erreur BLOCKSTO, d’autres Utilisateurs ou
              des tiers.
            </li>
            <li>
              Les Utilisateurs renoncent à rechercher la responsabilité de
              BLOCKSTO et à toute poursuite relative à tous dommages éventuels
              résultant de l’impossibilité d’utiliser le Site du fait de sa
              propre défaillance en la matière.
            </li>
            <li>
              L’inscription est ouverte sans qu’il soit nécessaire de renseigner
              de coordonnées bancaires.
            </li>
          </ul>
          <b>
            Article 13.3. Respect de la procédure de création de compte indiquée
            en ligne
          </b>
          <ul>
            <li>
              La procédure d’inscription et de création de compte est gratuite,
              et identique pour toutes les catégories d’Utilisateurs.
            </li>
            <li>
              L’inscription s’effectue exclusivement en ligne en suivant
              impérativement la procédure indiquée directement sur le Site. Les
              Utilisateurs ne peuvent pas revendiquer le bénéfice des Services
              s’il ne s’inscrivent pas selon cette procédure.
            </li>
          </ul>
          <b>Article 13.4. Validation finale des inscriptions</b>
          <ul>
            <li>
              La validation de l’inscription s’opère par le remplissage complet
              des champs à renseigner obligatoirement et d’une case à cocher,
              selon le procédé technique et légal du double clic.
            </li>
            <li>
              L’Abonné admet sans réserve que seul ce procédé permet d’attester
              de sa signature numérique des présentes CGUS et de rendre
              effective la validation de cette inscription.
            </li>
            <li>
              BLOCKSTO se réserve le droit de procéder à toute vérification
              s’avérant nécessaire dans le cadre de la loi, de toute
              responsabilité en cas de défaillance dans le renseignement de ces
              informations et le respect des procédures exigées pour
              l’inscription, notamment mais non limitativement en cas
              d’usurpation d’identité, interdiction bancaire et/ou d’exercer,
              etc.
            </li>
            <li>
              Par conséquent, BLOCKSTO se réserve le droit d'accepter ou de
              refuser de valider une inscription, de façon discrétionnaire et
              sans avoir à s’en justifier, sans recours possible ni indemnité
              pour l’Abonné, en cas de violation des présentes CGUS et/ou de la
              législation en vigueur
            </li>
            <li>
              De la même manière, BLOCKSTO se réserve le droit de mettre un
              terme à l'inscription d'un Abonné à tout moment, dans les
              conditions de l’article 23 des présentes CGUS.
            </li>
            <li>
              Les Abonnés peuvent se désinscrire en quelques clics à tout
              moment, en respectant les termes de l’article 22 des présentes
              CGUS.
            </li>
          </ul>
          <b>Article 13.5. Effets des inscriptions</b>
          <ul>
            <li>
              La validation selon les termes des présentes CGUS emporte la
              création d’un compte personnel, et le bénéfice des fonctionnalités
              associées, qui sont automatiquement attachées au compte ainsi
              créé.
            </li>
            <li>
              BLOCKSTO n’envoie aucun identifiant de connexion ni aucun mot de
              passe à l’Abonné, qui les choisit lui-même lors du processus
              d’inscription, et qu’il peut librement modifier à tout moment via
              son espace personnel. Il est exclusivement et pleinement
              responsable de ses identifiants et mot de passe et n'est en aucun
              cas autorisé à céder son compte ou ses codes d'accès à un autre
              Utilisateur, ni à un tiers.
            </li>
            <li>
              L’inscription valide permet d’obtenir l’accès à son tableau de
              bord vide, permettant uniquement de recevoir et de visualiser les
              Contenus partagés par d’autres Abonnés, et d’accéder gratuitement
              aux informations foncières qui lui sont ainsi envoyées.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 14. Souscription aux Services et achat des Carnets
          </span>
          <br />
          <b>Article 14.1. Modalités de souscription</b>
          <ul>
            <li>
              Les Abonnés souhaitant bénéficier de Services étendus doivent
              acheter un ou plusieurs Carnet(s) d’information du logement, qui
              sont autant de coffre-fort numériques destinés à y intégrer les
              informations du logement rendues obligatoires par la Loi Climat et
              Résilience.
            </li>
            <li>
              Les Abonnés sont libres de commander autant de Carnets qu’ils le
              souhaitent, avec un seul compte en ligne, en fonction du nombre de
              logements ou de biens immobiliers qu’ils détiennent et/ou dont ils
              ont la gestion et/ou compris dans leurs portefeuille. Chaque
              Carnet dispose toutefois des mêmes fonctionnalités
            </li>
            <li>
              Les commandes sont livrables par e-mail, contenant les liens et/ou
              les pièces jointes adéquates en fonction de la commande de
              l’Abonné.
            </li>
            <li>
              Le contenu et le prix exact de ces Carnets sont systématiquement
              précisés préalablement à toute validation de leur souscription.
            </li>
          </ul>
          <b>Article 14.2. Tarifs applicables</b>
          <ul>
            <li>
              BLOCKSTO affiche clairement tous les tarifs applicables et tous
              les éléments permettant aux Utilisateurs de s’inscrire et
              d’utiliser le Site sur la base d’un consentement libre et éclairé.
            </li>
            <li>
              Les Services sont facturés sur la base des tarifs en vigueur au
              moment de l’inscription ou de la souscription, qui sont indiqués
              en euros et TTC (Toutes Taxes Comprises, ce qui inclut la TVA), et
              sont systématiquement rappelés de façon transparente et
              préalablement à la validation de toute commande. Les tarifs
              applicables sont fixés en fonction des Services choisis par
              l’Abonné.
            </li>
            <li>
              Les frais éventuels afférents aux paiements effectués sur le Site
              seront à la charge de l’Abonné. Par conséquent, les paiements
              comprendront, outre le ou les prix affiché(s) sur le devis, tous
              les frais et toutes les taxes de toute nature, selon la
              réglementation française en matière de territorialité de ces
              taxes.
            </li>
            <li>
              Le compte bancaire de BLOCKSTO étant domicilié en France et ne
              présentant aucun caractère particulier, les paiements
              n'occasionnent pas de frais supplémentaire caché. La
              responsabilité de BLOCKSTO est ainsi totalement exclue dans les
              cas éventuels d’application de quelconques frais bancaires
              supplémentaires à la charge de l’Abonné du fait de sa propre
              situation bancaire.
            </li>
          </ul>
          <b>Article 14.3. Achats de nouveaux Carnets</b>
          <ul>
            <li>
              L’Abonné est toujours libre d’acheter de nouveaux Carnets en
              respectant la même procédure de validation de ses commandes. Ce
              dernier admet que toute commande supplémentaire exige de sa part
              de s’acquitter du paiement régulier de son nouvel achat.
            </li>
            <li>
              Dans ce cas, le compte concerné est conservé dans les mêmes
              conditions et agrémenté du nombre de Carnets supplémentaires ainsi
              choisis par l’Abonné lui-même.
            </li>
          </ul>
          <b>Article 14.4. Moyens et délais de paiement</b>
          <ul>
            <li>
              Les Services payants sont payables une seule fois, au comptant,
              sans escompte, par carte bleue exclusivement, et immédiatement à
              la validation de la commande.
            </li>
            <li>
              Les Abonnés dégagent la responsabilité de BLOCKSTO pour tout
              dommage résultant de la difficulté ou de l’impossibilité pour eux
              de procéder à un paiement, pour tous motifs, et notamment du fait
              d’une défaillance du service tiers gérant les transactions
              financières.
            </li>
            <li>
              Il est admis que dans tous les cas BLOCKSTO peut être amenée à
              bloquer valablement une transaction, de manière provisoire, le
              temps de faire effectuer des vérifications de sécurité s’avérant
              nécessaires.
            </li>
            <li>
              Les factures sont envoyées par courriel à l’Abonné par BLOCKSTO
              et/ou Stripe.
            </li>
          </ul>
          <b>Article 14.5. Clause résolutoire de plein droit</b>
          <ul>
            <li>
              Le non-respect des procédés obligatoires de paiement constitue une
              inexécution de l’obligation de payer, qui permet valablement à
              BLOCKSTO de suspendre ou d’annuler une transaction, une commande,
              ou le bénéfice d’un Service quelconque sur le Site, sans avoir à
              s’en justifier, et à considérer ses engagements à l’égard de
              l’Abonné concerné comme résolus de plein droit relativement au
              Service concerné et aux fonctionnalités associées.
            </li>
            <li>
              L’Abonné concerné ne peut alors se prévaloir d’un quelconque droit
              de revendiquer le bénéfice des Services, qui ne peuvent être
              obtenus que par la régularisation de sa situation ou par une
              nouvelle souscription valable.
            </li>
            <li>
              Lorsque certaines données ont été renseignées par l’Abonné à
              BLOCKSTO, celles-ci peuvent être intégralement purgées du système
              informatique de BLOCKSTO.
            </li>
            <li>
              Toutefois et par exception, BLOCKSTO se réserve le droit de
              notifier à l’Abonné concerné le défaut de paiement et de le
              relancer, une ou plusieurs fois selon les cas, afin de l’informer
              de la suspension provisoire de son Service et de l’inviter à
              régulariser sa situation en procédant au paiement manquant, afin
              de profiter à nouveau du bénéfice des fonctionnalités liées à son
              Service.
            </li>
            <li>
              Dans ce cas, le Service court à nouveau pleinement une fois le
              paiement effectif, dans les mêmes conditions que citées
              précédemment.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">Article 15. Droit de rétractation</span>
          <br />
          <ul>
            <li>
              Les Utilisateurs qui décident de s’inscrire sur BLOCKSTO et de
              créer un ou plusieurs Carnet(s) admettent que les Services
              commencent à être exécutés dès la validation de leur inscription,
              et à ce titre ne sont pas susceptibles de rétractation,
              conformément à l’article L 221-28 alinéa 13 du code de la
              consommation.
            </li>
            <li>
              En effet, le bénéfice des Services est délivré par BLOCKSTO sans
              délai après la commande, par un e-mail contenant les accès au Site
              et au compte personnel de l’Abonné, qui peut profiter de
              l’ensemble de ses fonctionnalités immédiatement.
            </li>
            <li>
              Lors de la validation de leur inscription, les Utilisateurs
              renoncent à exercer leur droit de rétractation sur les Services,
              ce qui est attesté par le cochage de la case permettant de valider
              parfaitement l’inscription et/ou la souscription, et implique la
              lecture, la compréhension, et l’acceptation du renoncement exprès
              au droit de rétractation.
            </li>
          </ul>
        </p>
      </>
    ),
  },
  {
    title: 'Partie 3. Utilisation des Carnets',
    content: (
      <>
        <p>
          <span className="article">
            Article 16. Règles d’utilisation des Carnets
          </span>
          <br />
          <b>Article 16.1. Ouverture des Carnets par les Abonnés</b>
          <ul>
            <li>
              Tout Abonné valablement inscrit et connecté (login et mot de
              passe) peut accéder à son Carnet et y insérer des Contenus, quel
              qu’en soit le format. Toutefois, BLOCKSTO ne s’engage pas à rendre
              compatible tous formats qui se révéleraient éventuellement
              incompatibles avec l’univers technique de BLOCKSTO, et de ce fait
              illisibles ou non-partageables. Dans ce cas, BLOCKSTO invite les
              Utilisateurs à convertir leurs Contenus dans d’autres formats
              adéquats ou à préférer directement d’autres formats. Par ailleurs,
              le service Utilisateur peut toujours être contacté sur
              contact@blocksto.fr
            </li>
            <li>
              Dans ce cadre l’Abonné garantit à BLOCKSTO et aux autres
              Utilisateurs :<br />- la légalité, en tous points, des Contenus
              insérés dans son ou ses Carnet(s), entendue comme le respect de la
              Loi et des présentes CGUS ;<br />- qu’il n’intégrera pas et ne
              partagera pas sur et via son ou ses Carnet(s) des informations
              malveillantes, dénigrantes, trompeuses ou de nature à porter
              atteinte aux droits d’un tiers de quelle que nature que ce soit ;
              <br />- qu’il n’usurpe l’identité de personne ;
              <br />- qu’il a le pouvoir, la capacité, l’autorisation et la
              qualité pour ouvrir et gérer son ou ses Carnet(s), ainsi que
              d’être en mesure d’en justifier ;
              <br />- qu’il fera son affaire personnelle du traitement fiscal et
              social et des obligations légales afférentes au titre des revenus
              qu’il pourra percevoir et qui seraient liés directement ou non à
              l’utilisation du Site.
            </li>
            <li>
              En effet, les Abonnés reconnaissent être pleinement responsables
              de la conformité légale et réglementaire tant de leurs activités
              professionnelles que des Contenu(s) intégrés dans leur(s) Carnets
              et partagés depuis ces derniers.
            </li>
            <li>
              BLOCKSTO ne fournit pas de conseils juridiques généraux ou
              spécifiques, dont l’obtention et l’application sont à la charge
              exclusive de l’Abonné.
            </li>
          </ul>
          <b>
            Article 16.2. Partage des Contenus par les Abonnés ayant souscrit à
            un ou des Carnet(s)
          </b>
          <ul>
            <li>
              Les Abonnés sont libres de partager les Contenus insérés dans
              leur(s) Carnet(s), en partie ou en totalité et avec tout
              Destinataire de leur choix, et de choisir le mode de partage parmi
              les options proposées par BLOCKSTO. Ils décident des options de
              partage, le mode d’ouverture et l’étendue du partage lors de la
              création du Carnet concerné, en spécifiant le(s) destinataire(s) -
              (un ou plusieurs autre(s) Abonné(s).
            </li>
            <li>
              Il existe deux types de partage possibles :<br />- le partage
              temporaire `&gt;` l’Abonné décide que leur partage doit être
              temporaire et/ou limité (nombre de Contenus visibles) ;
              <br />- le partage définitif `&gt;` l’Abonné ouvre l’accès à
              l’ensemble des Contenus/Carnets ; dans ce cas, le Destinataire
              dispose de l’ensemble des droits de partage.
            </li>
            <li>
              Les Abonnés peuvent suivre l’ensemble des partages effectués via
              leur tableau de bord, affichant le statut des Contenus concernés
              (partagé, brouillon, ou reçu).
            </li>
          </ul>
          <b>Article 16.3. Utilisation des Contenus par les Destinataires</b>
          <ul>
            <li>
              Le Destinataire peut à tout moment et gratuitement rechercher un
              ou plusieurs Carnet(s), en utilisant la barre de recherche du
              Site, et en indiquant l’identifiant ou le nom complet du ou des
              Abonné(s) qui partage(nt) avec lui ces Contenus.
            </li>
            <li>
              Le Destinataire vérifie dans la liste que le partage est activé,
              et le cas échéant peut y accéder simplement en cliquant dessus.
              Dans ce cas, il peut afficher, lire, et stocker le Contenu
              concerné sur son propre espace en ligne.
            </li>
            <li>
              Lorsque le partage n’est pas activé, le Carnet ou Contenu concerné
              ne s’affiche pas dans la liste / ne peut pas être consulté ni
              stocké par le Destinataire. Dans ce cas, ce dernier doit contacter
              directement l’Abonné en question, et décharge BLOCKSTO de toute
              responsabilité à cet égard.
            </li>
          </ul>
          <b>Article 16.4. Pouvoir de contrôle de BLOCKSTO sur le Site</b>
          <ul>
            <li>
              Il est rappelé que BLOCKSTO dispose d’un droit de contrôle
              unilatéral sur l’ensemble du Site, et par conséquent sur
              l’utilisation des Carnets, ce qui implique la faculté de lire tout
              Contenu susceptible de présenter un danger informatique ou un
              caractère illicite, ou encore une forme non pertinente ou
              contraire à l’esprit et à la vocation du Site et/ou aux présentes
              CGUS, ainsi qu’à la Politique Vie Privée, et ce dès lors qu’il en
              a connaissance.
            </li>
            <li>
              Cette faculté ne peut être reprochée à BLOCKSTO, qui s’engage
              strictement, en dehors de ces cas spécifiques, à une obligation de
              secret renforcée lui interdisant de principe d’exercer cette
              faculté.
            </li>
            <li>
              Cette faculté ne peut être reprochée à BLOCKSTO, qui s’engage
              strictement, en dehors de ces cas spécifiques, à une obligation de
              secret renforcée lui interdisant de principe d’exercer cette
              faculté.
            </li>
            <li>
              Ce pouvoir permet à BLOCKSTO , sans que sa responsabilité civile
              ou pénale de BLOCKSTO ne soit en aucune manière engagée, de
              suspendre le partage d’un Contenu ainsi que l’accès à un ou
              plusieurs Carnet(s) à tout moment et sans avoir à s’en justifier.
              En pareil cas, la suspension ne donnera lieu à aucun
              remboursement, ni à aucun avoir ou dédommagement d’aucune sorte.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 17. Propriété des Carnets et des Contenus
          </span>
          <br />
          <b>Article 17.1. Propriété des Carnets</b>
          <ul>
            <li>
              Les Abonnés comprennent et admettent qu’ils ne sont pas
              propriétaires des Carnets, qui sont des espaces en ligne loués à
              BLOCKSTO dans le but de leur permettre leur gestion sécurisée et
              leur partage avec les Destinataires.
            </li>
            <li>
              Toutefois, les Abonnés ayant valablement souscrits à un ou
              plusieurs Carnet(s) sont pleinement légitimes à en revendiquer le
              bénéfice exclusif dans les conditions ci-décrites.
            </li>
          </ul>
          <b>Article 17.2. Propriété des Contenus</b>
          <ul>
            <li>
              La répartition de la propriété des Contenus est hors de l’emprise
              de BLOCKSTO, et s’effectue sous la pleine responsabilité des
              Abonnés et des Destinataires entre eux.
            </li>
            <li>
              Toutefois, il est entendu que les Contenus appartiennent de
              principe au propriétaire du bien ou logement concerné (qui est
              lui-même a priori l’Abonné).
            </li>
          </ul>
          <b>Article 17.3. Propriété intellectuelle des Contenus</b>
          <ul>
            <li>
              Il est entendu que la répartition des droits de propriété
              intellectuelle attachée aux Contenus dans leur ensemble est
              librement définie par l’Abonné lui-même, qui prend en charge la
              publication de toute mention ou information y afférent, et dégage
              BLOCKSTO de sa responsabilité pour tous dommages éventuels qui
              pourraient résulter de l’utilisation non autorisée de ces droits
              par les Destinataires et/ou les Utilisateurs et/ou tous tiers.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 18. Limitation de responsabilité de BLOCKSTO eu égard aux
            Contenus
          </span>
          <ul>
            <li>
              L’ouverture et l’utilisation des Carnets est effectuée par les
              Abonnés sous leur pleine et entière responsabilité, de même que la
              lecture et l’utilisation de ces derniers par les Destinataires.
            </li>
            <li>
              Il est rappelé et admis sans réserve que BLOCKSTO ne saurait en
              rien être considéré comme l’auteur des Contenus, ni comme le
              détenteur des Carnets. Le rôle et la responsabilité de BLOCKSTO se
              limite à ceux d’un intermédiaire technique qui permet, via le
              Site, le partage et la gestion de Contenus spécifiques strictement
              par les Abonnés, et n'intervient qu'en cette qualité entre les
              Utilisateurs.
            </li>
            <li>
              Les Utilisateurs admettent sans réserve décharger BLOCKSTO de
              toute responsabilité pour tous dommages éventuels résultant de
              leur propre défaillance dans la conduite de leur propre
              utilisation du Site, de leur(s) propre(s) activité(s)
              professionnelle(s) ou non, et enfin de leurs relations
              contractuelles éventuelles, auxquelles BLOCKSTO n’est partie
              d’aucune manière.
            </li>
            <li>
              BLOCKSTO conseille vivement aux Abonnés de se renseigner de façon
              proactive sur le caractère licite des Contenus qu’ils insèrent et
              partagent via le Site.
            </li>
            <li>
              En particulier, l’Abonné s’engage à ne pas insérer dans son ou ses
              Carnet(s), ni partager des Contenus, via BLOCKSTO, lorsqu’ils sont
              contraires aux législations relatives à la lutte contre la
              pédopornographie et l’apologie du terrorisme. Dans de tels cas,
              portant préjudice ou non à BLOCKSTO, ce dernier dégage toute
              responsabilité du fait de l’existence de ces contenus et des
              intentions frauduleuses qui y sont liées, desquels il ne saurait
              en aucun cas être considéré comme complice, et se réserve le droit
              d’engager ou de faciliter toute procédure légale et/ou judiciaire.
            </li>
            <li>
              La même interdiction est valable pour tout Contenu illicite, mais
              les infractions précitées sont considérées comme particulièrement
              graves et feraient l’objet d’un traitement spécifique.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 19. Personnalisation des comptes
          </span>
          <ul>
            <li>
              L’utilisation des Carnets peut se faire sans personnalisation
              particulière, à l’exception des Contenus qui sont nécessairement
              individualisés.
            </li>
            <li>
              Toutefois, les Abonnés peuvent à tout moment personnaliser leur
              compte de façon visuelle ou dans leurs fonctionnalités :<br />-
              ajout de photographie/logo/bannière
              <br />- ajout de rubriques spécifiques
              <br />- ajout de fonctionnalités étendues, etc.
            </li>
            <li>
              La personnalisation des comptes exige de respecter la procédure
              indiquée sur le Site et le paiement éventuel du tarif annoncé
              systématiquement avant la validation de cette modification, le cas
              échéant. Le paiement s’effectue dans ce cas dans les mêmes
              conditions que les autres paiements, décrits dans les présentes
              CGUS.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 20. Limitation de responsabilité de BLOCKSTO
          </span>
          <br />
          <b>Article 20.1. Statut de tiers de BLOCKSTO</b>
          <ul>
            <li>
              BLOCKSTO garantit la sécurité informatique du Site et des
              processus de partage des Contenus, mais ne répond pas des dommages
              résultant du renseignement d’informations erronées, fausses ou
              obsolètes par les Utilisateurs eux-mêmes.
            </li>
            <li>
              BLOCKSTO assure un rôle d’intermédiaire technique en permettant
              techniquement le partage des Contenus sur le Site, dans les règles
              de l’art et de la profession. Sa responsabilité est limitée à ses
              obligations de prestataire de services informatiques, conformément
              à la loi et aux bonnes pratiques de ce secteur d’activité.
            </li>
          </ul>
          <b>Article 20.2. Conditions spécifiques et offres spéciales</b>
          <ul>
            <li>
              BLOCKSTO peut établir valablement des conditions spécifiques
              provisoires, et à durée limitée, pour permettre la souscription à
              des offres spéciales, pour certaines catégories d’Utilisateurs, à
              tout moment et sans avoir à s’en justifier. Les conditions
              spéciales dérogent aux conditions générales en cas de conflit.
            </li>
          </ul>
          <b>
            Article 20.3. Arbitrage et pouvoir de contrôle unilatéral de
            BLOCKSTO
          </b>
          <ul>
            <li>
              L’absence de responsabilité de BLOCKSTO pour le partage des
              Contenus n’empêche pas ce dernier de disposer et d’appliquer à
              tout moment et de façon discrétionnaire, son pouvoir de contrôle
              sur l’ensemble des activités sur le Site
            </li>
            <li>
              Par conséquent, toute défaillance quelconque dans le respect de la
              législation et/ou des présentes CGUS de la part des Utilisateurs,
              est susceptible de justifier l’application de ce pouvoir
              unilatéral de BLOCKSTO, afin de :
              <br />- suspendre ou faire cesser le partage d’un ou de plusieurs
              Contenu(s) ;
              <br />- suspendre ou faire cesser une inscription en particulier ;
              <br />- clôturer l’accès au Site pour un ou plusieurs
              Utilisateur(s) ;
              <br />- suspendre ou annuler un paiement et/ou le bénéfice du ou
              de Service(s) correspondant ;
              <br />- recevoir et traiter toute demande en réclamation d’un
              Utilisateur à l’égard d’un autre Utilisateur, instruire et
              répondre cette demande afin de proposer une solution amiable à ce
              désaccord ;
              <br />- demander voire exiger la fourniture de pièces
              complémentaires éventuelles ;
              <br />- initier ou favoriser la saisine éventuelle d’instances
              privées et/ou publiques pour trancher le désaccord ;
              <br />- faire prévaloir ses propres droits.
            </li>
          </ul>
        </p>
      </>
    ),
  },
  {
    title: 'Partie 4. Durée et fin du contrat',
    content: (
      <>
        <p>
          <span className="article">
            Article 21. Durée du contrat relativement aux inscriptions sur
            BLOCKSTO
          </span>
          <ul>
            <li>
              Les présentes CGUS entrent en vigueur dans toutes leurs
              stipulations à compter de leur signature électronique par
              l’Abonné, et sont conclus de principe pour une durée indéterminée,
              sans besoin d’accomplir aucune formalité particulière afin de
              renouveler leur inscription.
            </li>
            <li>
              Les engagements relatifs à l’inscription des Utilisateurs et à
              l’ouverture d’un compte sur BLOCKSTO prennent effet dès la
              validation formelle de cette inscription sur le Site, après
              acceptation formelle des présentes CGUS, et jusqu’à leur
              résiliation formelle, selon les termes de l’article 22 ou 23 des
              présentes CGUS, selon que cette résiliation prend la forme d’une
              désinscription volontaire ou d’une clôture forcée par BLOCKSTO.
            </li>
            <li>
              Les Abonnés peuvent en sus disposer d’autant de Carnets qu’ils le
              souhaitent, et de les créer, les gérer et/ou les supprimer à leur
              guise tant qu’ils sont inscrits sur BLOCKSTO.
            </li>
            <li>
              Les Abonnés admettent que dans le cas où ils suppriment leur
              compte totalement, ils perdent l’accès à leurs Carnets, et ils ne
              peuvent conserver ni retrouver aucune des données anciennement
              renseignées dans leur compte BLOCKSTO.
            </li>
            <li>
              Dans tous les cas, toutes les stipulations des CGUS qui peuvent
              être raisonnablement interprétées comme survivant à la pleine
              exécution, à l’expiration, à la résiliation ou à l’annulation des
              CGUS survivront à cette exécution, cette expiration, cette
              résiliation ou annulation.
            </li>
            <li>
              Les Abonnés peuvent se désinscrire volontairement du Site à tout
              moment, sous réserve d’exécuter l’ensemble de leurs obligations
              consenties entre eux et à l’égard de BLOCKSTO, incluant tous
              paiements valablement dus en vertu des présentes CGUS.
            </li>
            <li>
              La demande peut être faite directement sur le Site, via leur
              tableau de bord (en cliquant sur le bouton « Supprimer mon compte
              » (ou tout autre nom équivalent revêtant le même sens de façon non
              équivoque), et en suivant la procédure indiquée, qui intègre
              systématiquement une infobulle récapitulative qui rappelle à
              l’Abonné les conséquences irréversibles de sa désinscription.
            </li>
            <li>
              L’Abonné peut également exporter ses Contenus avant de valider sa
              désinscription.
            </li>
            <li>
              Dans ce cas, BLOCKSTO procède automatiquement à la réalisation de
              la désinscription, et s’engage à la notifier sans délai et par
              courriel à l’Abonné.
            </li>
            <li>
              Sous réserve de l’inexécution d’obligations contractuelles de la
              part de l’Abonné empêchant la résiliation, la réception de cette
              notification emporte la résiliation des présentes CGUS, la
              suppression totale du compte et des Contenus associé(s), et la
              cessation des relations contractuelles entre l’Utilisateur
              concerné et BLOCKSTO. Après cette date, l’abonnement est annulé et
              sans effet.
            </li>
            <li>
              L’Abonné admet sans réserve que la désinscription volontaire
              ci-dessus décrite emporte renoncement au bénéfice des Services
              associés à son compte, la suppression et la perte définitive de
              son accès aux Contenus (qui peuvent être purgées du système
              informatique de BLOCKSTO), et qu’une nouvelle inscription est
              nécessaire pour retrouver le plein bénéfice d’une nouvelle
              inscription.
            </li>
            <li>
              Les Contenus qui n’ont pas été exporté avant la désinscription ne
              pourront pas être récupérés.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 23. Clôture de compte anticipée par BLOCKSTO
          </span>
          <br />
          <b>Article 23.1. Suspension de compte par BLOCKSTO</b>
          <ul>
            <li>
              BLOCKSTO se réserve le droit de suspendre un compte et/ou un
              Service et/ou un Carnet et/ou une souscription à tout moment. La
              suspension peut être levée en cas de mise en conformité de la part
              de l’Abonné concerné. Dans le cas contraire, elle justifie clôture
              du compte et la résiliation unilatérale de la part de BLOCKSTO
              (voir article suivant).
            </li>
            <li>
              BLOCKSTO exclue totalement sa responsabilité, y compris à l’égard
              des tiers, relativement à tous dommages potentiels résultant de la
              suspension ou la clôture effectuée selon ces modalités.
            </li>
            <li>
              BLOCKSTO se réserve également le droit d’agir devant toutes
              instances adéquates pour obtenir réparation de tous dommages
              éventuels. Les dommages ouvrant droit à la résiliation et à toutes
              procédures s’entendent de tous préjudices, tous frais nécessaires
              au recouvrement de toutes créances et tous frais de procédures
              compris.
            </li>
            <li>
              BLOCKSTO envoie à l’Abonné concerné un ou plusieurs courriel(s)
              notifiant ses défaillances et le mettant en demeure de se
              conformer à ses obligations et le prévenant de la future clôture
              forcée de son compte dans le cas contraire.
            </li>
            <li>
              Cette clôture intervient dans un délai maximum de{' '}
              <span style={{ textDecoration: 'underline' }}>
                trente (30) jours
              </span>{' '}
              à compter de la constatation de ladite défaillance, lorsque cette
              dernière concerne{' '}
              <span style={{ textDecoration: 'underline' }}>
                la gestion erronée des Contenus
              </span>{' '}
              des Carnets, et de{' '}
              <span style={{ textDecoration: 'underline' }}>
                dix (10) jours
              </span>{' '}
              lorsque la défaillance concerne la{' '}
              <span style={{ textDecoration: 'underline' }}>
                violation grave des présentes et/ou de la Loi
              </span>
              , en particulier lorsqu’un préjudice ou un préjudice potentiel est
              ressenti par un autre Utilisateur, BLOCKSTO ou un tiers.
            </li>
          </ul>
          <b>Article 23.2. Clôture de compte forcée par BLOCKSTO</b>
          <ul>
            <li>
              BLOCKSTO se réserve le droit de résilier le contrat avant son
              terme, voire d’agir devant toute(s) instance(s) adéquate(s) pour
              obtenir réparation de tous dommages éventuels, s’il justifie d’un
              des motifs suivants :<br />- défaut de paiement dans les
              conditions précisées dans les présentes CGUS ;<br />- violations
              du droit en vigueur par l’Abonné, ainsi que les défaillances et
              retards persistants dans l’exécution des obligations
              contractuelles ici consenties, portant préjudice direct ou
              indirect à BLOCKSTO ;<br />- violations graves du droit en vigueur
              susceptibles de mettre en cause la responsabilité de BLOCKSTO en
              tant qu’éditeur de contenus numériques au sens de la loi ;
              <br />- tout comportement irrespectueux ou agressif à l’égard de
              BLOCKSTO et de toutes les personnes qui le représentent ;<br />-
              défaillances graves et/ou répétées malgré les diligences de
              BLOCKSTO.
            </li>
            <li>
              Les dommages ouvrant droit à la résiliation et à toutes procédures
              s’entendent de tous préjudices, tous frais nécessaires au
              recouvrement de toutes créances et tous frais de procédures
              compris.
            </li>
            <li>
              A compter de la fin du délai de trente (30) jours ou de dix (10)
              jours, selon les cas, spécifiés dans l’article 23.1 des présentes
              CGUS, et en cas de défaillance persistante de l’Abonné après mise
              en demeure formelle de la part de BLOCKSTO de se conformer à ses
              obligations, BLOCKSTO envoie à ce dernier concerné un courrier
              postal ou électronique notifiant la clôture forcée de son compte,
              et la résiliation des présentes CGUS, qui prend effet dès sa
              réception (le cachet de la Poste faisant foi, le cas échéant).
            </li>
            <li>
              L’Abonné comprend que dans un tel cas, BLOCKSTO est également
              susceptible d’introduire tout recours ouvert en réparation de tous
              préjudices éventuels.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 24. Suspension des obligations pour cause de force majeure
          </span>
          <ul>
            <li>
              Aucune des Parties ne sera tenue pour responsable de la
              défaillance ou des retards dans l'exécution d'une ou plusieurs de
              leurs obligations découlant des présentes CGUS, lorsque ces
              défaillances ou retards résultent de la survenance d'un cas de
              force majeure au sens du droit en vigueur, c’est-à-dire résultant
              de circonstances imprévisibles, irrésistibles et indépendantes de
              la volonté des Parties, malgré leurs efforts raisonnables, et dans
              un délai maximum de quarante-cinq (45) jours à partir de sa
              survenance.
            </li>
            <li>
              Il est admis entre les parties aux présentes CGUS que sont
              incluses dans ces cas de force majeure d’où résultent un dommage,
              et sans que cette liste soit exclusive : les catastrophes
              naturelles, les incendies, les inondations, la foudre, les
              surtensions électriques, les grèves, les arrêts de fourniture
              électrique, les défaillances des réseaux de télécommunications,
              les guerres civiles ou étrangères, les émeutes ou mouvements
              populaires, les attentats, les restrictions réglementaires liées à
              la fourniture de services de télécommunication, la perte de
              connexion due aux opérateurs publics et privés dont dépend
              BLOCKSTO.
            </li>
            <li>
              Dans ce cas, les Parties admettent que la suspension des
              obligations est non fautive et dure pendant toute la durée
              d’existence des circonstances de force majeure. À l’expiration de
              cette période les obligations réciproques redeviennent applicables
              dans les mêmes conditions.
            </li>
            <li>
              Si la situation de force majeure subie par l’une ou l’autre des
              Parties ou les deux, excède les quarante-cinq (45) jours, les
              Parties sont fondées à considérer leurs obligations réciproques
              comme éteintes et les présentes CGUS rompues, sans préjudice de
              toute action judiciaire aboutissant à une solution contraire.
            </li>
            <li>
              Dans la mesure du possible, les Parties s’engagent réciproquement
              à se notifier la constatation d’un tel cas dans un délai de dix
              (10) jours à partir de sa survenance, et à se présenter si
              possible et à leur propre initiative tous documents justificatifs
              de cet état.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">Article 25. Cession du Contrat</span>
          <ul>
            <li>
              Il est admis entre les Parties que les présentes CGUS ne peuvent
              être librement cédées ou transférées, en partie ou en totalité,
              sans l’accord exprès, écrit, et préalable de l’autre Partie.
            </li>
            <li>
              Toutefois, et par dérogation à ce qui précède, les Parties sont
              autorisées à réaliser les opérations unilatérales destinées à
              céder en partie ou en totalité les présentes CGUS à leurs sociétés
              apparentées.
            </li>
            <li>
              Également, les Parties peuvent réaliser librement les opérations
              suivantes :<br />- changement de participation, d’actionnariat, ou
              de contrôle ;<br />- fusions, absorptions, cession de fonds de
              commerce, cession d’activité et toute autre opération impliquant
              un transfert de patrimoine.
            </li>
            <li>
              Dans ces cas, la cession est valable sous réserve de notifier
              cette opération à l’autre Partie, sans délai et par écrit, et que
              la Partie cédante se porte fort du respect des présentes CGUS par
              le(s) cessionnaire(s).
            </li>
            <li>
              Toute cession ou tout transfert réalisé(e) en violation du présent
              article sera considéré(e) comme nul(le) et sans effet, et
              susceptible d’entraîner toute poursuite en réparation d’un
              éventuel dommage en résultant. L’autre Partie est alors légitime à
              considérer les présentes CGUS comme résiliées de plein droit et
              sans indemnité.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 26. Droit applicable et aspects internationaux
          </span>
          <br />
          <b>Article 26.1 Droit applicable à l’utilisation de BLOCKSTO</b>
          <ul>
            <li>
              De convention expresse entre les Parties, l’activité du Site
              entier (toutes utilisations comprises, depuis n’importe quel pays
              et par un Utilisateur ou Abonné de n’importe quelle nationalité),
              ainsi que les présentes CGUS, sont soumises et régies
              exclusivement par le droit français, et doivent être interprétées
              au regard du droit français.
            </li>
            <li>
              Les présentes CGUS sont rédigées initialement en langue française.
              En cas de traduction en une ou plusieurs langue(s), seul le texte
              français ferait foi en cas de litige.
            </li>
            <li>
              Aucune dérogation à cette stipulation ne peut être alléguée, y
              compris pour tout conflit de lois, et aucun élément d’extranéité
              ne pourra ainsi être invoqué pour l’application d’une quelconque
              règle de droit étranger
            </li>
            <li>
              Les Utilisateurs soumis à un droit autre que le droit français
              admettent devoir se renseigner sur la législation applicable à
              leur pays, et endosser intégralement la responsabilité de la
              légalité de leur situation.
            </li>
          </ul>
          <b>Article 26.2. Restriction de l’accessibilité du Site</b>
          <ul>
            <li>
              Dans les cas où l’accès au Site et aux Services n’est pas garanti
              aux Utilisateurs de certains pays du fait de législations
              étrangères, de difficultés de connexion, ou de quelconque
              restriction indépendante de la volonté de BLOCKSTO, les dommages
              éventuels résultant de cette restriction ne sauraient en aucune
              manière entraîner la responsabilité de ce dernier.
            </li>
          </ul>
        </p>
        <p>
          <span className="article">
            Article 27. Désaccords, médiation et litiges
          </span>
          <br />
          <b>Article 27.1. Réclamations dirigée contre BLOCKSTO</b>
          <ul>
            <li>
              Toute réclamation doit être adressée à BLOCKSTO{' '}
              <span style={{ textDecoration: 'underline' }}>
                par écrit impérativement.
              </span>
              <br />
              <b>
                Les réclamations non écrites ne seront pas prises en compte.
              </b>
            </li>
            <li>
              Les réclamations peuvent être effectuées :<br />- soit par
              courrier aux coordonnées indiquées dans les Mentions Légales
              <br /> soit par courriel sur legal@blocksto.fr
              <br />
              <b>27.1.1 - Conditions de réclamation</b>
              <ul>
                <li>
                  Différentes solutions de réparation, d’échange, de
                  remboursement ou de satisfaction sont mises en place par
                  BLOCKSTO.
                </li>
                <li>
                  Afin de se protéger des réclamations abusives, BLOCKSTO se
                  réserve un droit de vérification systématique de la validité
                  des réclamations qui lui sont adressées, aussi bien de leurs
                  contenus que des éventuels justificatifs fournis.
                </li>
                <li>
                  L’Utilisateur doit toujours dans un premier temps envoyer sa
                  requête à BLOCKSTO afin de lui notifier la situation, son
                  mécontentement, et sa requête, dans des termes clairs et non
                  équivoques, en rappelant toutes les informations utiles
                  relatives à son inscription, et à ses souscriptions
                  éventuelles.
                </li>
                <li>
                  La demande doit comporter précisément les motifs de la
                  contestation, en justifiant d’un motif légitime résultant
                  d’une défaillance de BLOCKSTO dans l’exécution de ses
                  obligations contractuelles et/ou d’un manquement à la
                  législation en vigueur.
                </li>
              </ul>
              <b>27.1.2 - Effets des réclamations</b>
              <ul>
                <li>
                  Lorsque BLOCKSTO constate la véracité des faits et des
                  justificatifs présentés et la validité de la demande de
                  l’Utilisateur, il s’engage à y répondre en proposant une
                  solution de satisfaction sous trente (30) jours à partir de la
                  réception de la demande :<br />- soit le même Service exempt
                  de tout défaut et conforme à la souscription initiale,
                  <br />- soit un Service identique, de même valeur et de mêmes
                  caractéristiques, ou de valeur supérieure.
                </li>
                <li>
                  En cas de bug technique quelconque, BLOCKSTO s’engage dans un
                  premier temps à lé verifier et le faire réparer par tous
                  moyens.
                </li>
                <li>
                  Si la réparation ou le remplacement est impossible, BLOCKSTO
                  s’engage à effectuer le remboursement du/des Service(s)
                  concerné(s), ainsi que le remboursement des frais
                  supplémentaires éventuellement occasionnés, dans un délai de
                  trente (30) jours à compter de la réception de la demande, et
                  en utilisant les données bancaires utilisées par l’Abonné pour
                  son paiement initial le cas échéant, sauf si l’Abonné exige
                  d’être remboursé sur d’autres coordonnées bancaires, et sur
                  fourniture par ce dernier de ces coordonnées bancaires
                  différentes.
                </li>
              </ul>
            </li>
          </ul>
          <b>Article 27.2. Réclamations entre Utilisateurs</b>
          <ul>
            <li>
              Les Abonnés reconnaissent qu’ils sont responsables de leur
              utilisation du Site dans son ensemble, ainsi que de leur(s)
              compte(s) et de leur(s) Carnet(s) éventuel(s), dès leur ouverture,
              ainsi que de leurs propres conditions de partage à l’égard des
              autres Utilisateurs, et déchargent BLOCKSTO de toute
              responsabilité eu égard à la nature des Contenus concernés et de
              toutes conséquences que leur partage pourrait avoir pour
              l’Utilisateur concerné.
            </li>
            <li>
              BLOCKSTO se réserve le droit de rejeter toute éventuelle
              réclamation qui serait introduite dans ce cadre et n’assume que la
              charge des éventuels dysfonctionnements techniques n’ayant pas
              permis le partage sécurisé des Contenus conformément aux présentes
              et selon la formule de souscription choisie par l’Abonné.
            </li>
            <li>
              Toutefois, BLOCKSTO se réserve également le droit, au titre de son
              pouvoir de contrôle, de faciliter toute réclamation d’un
              Utilisateur lorsqu’il constate une violation manifeste du droit en
              vigueur et des présentes CGUS de la part de l’Abonné.
            </li>
            <li>
              Le droit légitime de BLOCKSTO d’arbitrer éventuellement un
              désaccord mineur entre les Utilisateurs est totalement
              discrétionnaire, sans que cette possibilité ne constitue une
              obligation de réparer lui-même les dommages éventuellement subis
              par ces derniers.
            </li>
          </ul>
          <b>Article 27.3. Compétence juridictionnelle</b>
          <ul>
            <li>
              Les Parties élisent domicile en leur siège social et domicile
              respectifs pour l’exécution des présentes CGUS.
            </li>
            <li>
              A défaut de résolution amiable des conflits éventuels, et de
              convention expresse entre les Parties, les litiges relatifs à
              l’utilisation du Site et à la conclusion des commandes, aux
              présentes Conditions Générales d’Utilisation des Services,
              concernant leur validité, leur interprétation, leur exécution,
              leurs conséquences et leurs suites, seront soumis aux tribunaux
              français compétents selon les règles applicables au cas d’espèce,
              sans préjudice de l’application d’une règle entraînant la
              compétence d’une autre juridiction, à savoir :
              <br />- soit la juridiction du lieu du siège social de BLOCKSTO
              (Paris) concernant des litiges impliquant des Utilisateurs
              agissant strictement sous le statut de commerçant professionnel ;
              <br />- soit toute juridiction applicable en vertu du droit en
              vigueur.
            </li>
          </ul>
        </p>
      </>
    ),
  },
];

export default data;
