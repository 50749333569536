import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import './index.css';

const Header = () => {
  const { isAuthenticated, disconnect } = useContext(AuthContext);
  const navigate = useNavigate();

  const clickConnectButton = () => {
    if (isAuthenticated) {
      disconnect();
    } else {
      navigate('/connection');
    }
  };

  return (
    <div className="header">
      <div className="logo">
        <Link to="/home">
          <img
            className="logo"
            src="./images/logo.png"
            alt="Blocksto Logo Image"
          />
          <span className="logo-text">BLOCKSTO</span>
        </Link>
      </div>
      <div className="menus">
        <Link to="/about">
          <span className="menu">Pourquoi Blocksto ?</span>
        </Link>
        <a className="button" onClick={clickConnectButton}>
          <span className="menu bold">
            {!isAuthenticated ? 'Connexion' : 'Se déconnecter'}
          </span>
        </a>
      </div>
    </div>
  );
};

export default Header;
