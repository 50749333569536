import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../../contexts/data';
import { fetchApi } from '../../../utils/backendApi';
import Table from '../../table';
import { Document } from '../../../types';

const DocumentConsultBlock = () => {
  const { selectedCategoryId, selectedNotebookId } = useContext(DataContext);
  const [data, setData] = useState<Document[]>([]);
  const columns = [
    { id: 'title', name: 'Nom', width: 35 },
    { id: 'createdDate', name: "Date d'ajout", width: 25 },
    { id: 'filesize', name: 'Taille du fichier', width: 25 },
    { id: 'actions', name: 'Actions', width: 15 },
  ];
  const actions = [
    {
      id: 'send',
      func: (id: string) => {
        console.log(`share ${id}`);
      },
    },
    {
      id: 'delete',
      func: (id: string) => {
        const call = async () => {
          let result = await fetchApi(
            `document/${id}`,
            'DELETE',
            undefined,
            true,
          );
          if (!result.removedDocument) {
            console.log('Delete Document Failed');
            return;
          }
          result = await fetchApi(
            `document?notebookId=${selectedNotebookId}&categoryId=${selectedCategoryId}`,
            'GET',
            undefined,
            true,
          );
          setData(result.documents);
        };
        call();
      },
    },
  ];

  useEffect(() => {
    const call = async () => {
      const res = await fetchApi(
        `document?notebookId=${selectedNotebookId}&categoryId=${selectedCategoryId}`,
        'GET',
        undefined,
        true,
      );
      setData(res.documents);
    };
    call();
  }, []);

  return (
    <div className="documentsBlock">
      <Table
        forWhat="document"
        data={data}
        columns={columns}
        actions={actions}
        additionalShowId=""
      />
    </div>
  );
};

export default DocumentConsultBlock;
