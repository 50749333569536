import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { UiContext } from '../../contexts/ui';
import { DataContext } from '../../contexts/data';
import {
  chooseFileMessage,
  createDocumentSuccessMessage,
  pinataUploadFailMessage,
} from '../../constant';
import { Notebook } from '../../types';
import { fetchApi, pinFileToIPFS } from '../../utils/backendApi';
import { PrimaryInput } from '../Input';
import { PrimaryDropdown } from '../Input/dropdown';
import './styles/document.css';

const DocumentAddPanel = () => {
  const { selectHouseContentPanel, selectConsultBlock, defineCurrentPath } =
    useContext(UiContext);
  const { selectNotebookId, selectCategoryId } = useContext(DataContext);
  const [title, setTitle] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [filename, setFilename] = useState<string>('');
  const [filesize, setFilesize] = useState<number>(0);
  const [notebookIndex, setNotebookIndex] = useState<number>(0);
  const [notebooks, setNotebooks] = useState<Notebook[]>([]);
  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const [categories, setCategories] = useState<{ id: string; title: string }[]>(
    [],
  );
  const [additionalInfo, setAdditionalInfo] = useState<string>('');
  const [file, setFile] = useState<File>();

  const navigate = useNavigate();

  const handleClick = async () => {
    if (!file) {
      toast.warn(chooseFileMessage);
      return;
    }
    const result = await pinFileToIPFS(file);
    if (typeof result === 'object' && !result.success) {
      toast.warn(pinataUploadFailMessage);
      return;
    }
    const data = await fetchApi(
      'document',
      'PUT',
      {
        title,
        path: `https://gateway.pinata.cloud/ipfs/${result}`,
        additionalInfo,
        type,
        filename,
        filesize,
        notebookId: notebooks[notebookIndex].id,
        categoryId: categories[categoryIndex].id,
      },
      true,
    );
    if (data.message) {
      return toast.warn(data.message);
    }
    toast.success(createDocumentSuccessMessage);
    selectHouseContentPanel('consultPanel');
    selectConsultBlock('document');
    defineCurrentPath(
      `notebook:${notebooks[notebookIndex].address}/category:${categories[categoryIndex].title}`,
    );
    selectCategoryId(categories[categoryIndex].id);
    selectNotebookId(notebooks[notebookIndex].id);
  };

  useEffect(() => {
    const call = async () => {
      const [categoriesData, notebooksData] = await Promise.all([
        fetchApi('category', 'GET', undefined, true),
        fetchApi('notebook', 'GET', undefined, true),
      ]);
      setCategories([...categoriesData.categories]);
      setNotebooks(notebooksData.notebooks);
    };
    call();
  }, []);

  return (
    <div className="documentAddPanel">
      <div className="formGroup">
        <PrimaryInput
          label="Nom du document"
          value={title}
          onChange={(value) => setTitle(value.toString())}
        />
        <PrimaryDropdown
          label="Dans quel carnet"
          values={notebooks.map((notebook) => notebook.address)}
          onSelect={(value) => setNotebookIndex(value)}
        />
        <PrimaryDropdown
          label="Catégorie"
          values={categories.map((category) => category.title)}
          onSelect={(value) => setCategoryIndex(value)}
        />
        <PrimaryInput
          label="Informations complémentaires"
          type="textarea"
          value={additionalInfo}
          onChange={(value) => setAdditionalInfo(value.toString())}
        />
        <PrimaryInput
          label="Télécharger un fichier"
          type="file"
          value=""
          onChange={(value) => {
            if (typeof value === 'object') {
              setFile(_.get(value, '0'));
              setType(
                _.get(value, '0.name', '')
                  .split('.')
                  [
                    _.get(value, '0.name', '').split('.').length - 1
                  ].toLowerCase(),
              );
              setFilename(_.get(value, '0.name', ''));
              setFilesize(_.get(value, '0.size', 0));
            }
          }}
        />
        <div className="button add" role="button" onClick={() => handleClick()}>
          Valider
        </div>
      </div>
    </div>
  );
};

export default DocumentAddPanel;
