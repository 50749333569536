import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Share } from '../../../types';
import { fetchApi } from '../../../utils/backendApi';
import Table from '../../table';

const DraftShareBlock = () => {
  const [data, setData] = useState<Share[]>([]);
  const [sharableId, setSharableId] = useState<number>(-1);
  const columns = [
    { id: 'title', name: 'Nom du fichier', width: 35 },
    { id: 'to', name: 'À', width: 25 },
    { id: 'date', name: 'Date', width: 25 },
    { id: 'actions', name: 'Actions', width: 15 },
  ];
  const actions = [
    { id: 'info', func: (id: string) => {} },
    {
      id: 'delete',
      func: (id: string) => {
        const call = async () => {
          let result = await fetchApi(`share/${id}`, 'DELETE', undefined, true);
          if (!result.removedShare) {
            console.log('Remove Share Failed!');
            return;
          }

          result = await fetchApi(`share/drafts`, 'GET', undefined, true);
          const draftsData: [] = result.drafts;

          const draftModifiedData: Share[] = modifyData(draftsData);
          setData(draftModifiedData);
        };
        call();
      },
    },
    {
      id: 'send',
      func: (id: string) => {
        const call = async () => {
          let result = await fetchApi(
            `share`,
            'POST',
            { id, isDraft: false },
            true,
          );
          if (!result.share) {
            console.log('Update Share Failed!');
            return;
          }

          result = await fetchApi(`share/drafts`, 'GET', undefined, true);
          const draftsData: [] = result.drafts;

          const draftModifiedData: Share[] = modifyData(draftsData);
          setData(draftModifiedData);
        };
        call();
      },
    },
  ];

  const modifyData = (draftsData: any[]): Share[] => {
    return draftsData.map((draft) => ({
      id: _.get(draft, 'id'),
      type: _.get(draft, 'document.type'),
      title: _.get(draft, 'document.filename'),
      to: `${_.get(draft, 'to.firstname')} ${_.get(draft, 'to.lastname')}`,
      from: `${_.get(draft, 'from.firstname')} ${_.get(
        draft,
        'from.lastname',
      )}`,
      date: _.toString(_.get(draft, 'sharedDate')).substring(0, 10),
      additionalInfo: _.get(draft, 'addtionalInfo'),
      documentId: _.get(draft, 'document.id'),
    }));
  };

  useEffect(() => {
    const call = async () => {
      const res = await fetchApi(`share/drafts`, 'GET', undefined, true);
      const draftsData: [] = res.drafts;

      const draftModifiedData: Share[] = modifyData(draftsData);
      setData(draftModifiedData);
    };

    call();
  }, []);

  return (
    <div className="draftBlock">
      <Table
        forWhat="draft"
        data={data}
        columns={columns}
        actions={actions}
        additionalShowId=""
      />
    </div>
  );
};

export default DraftShareBlock;
