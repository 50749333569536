export const buildingTypes = ['Appartement', 'Maison'];
export const notebookTypes = ['Appartement', 'Maison'];
export const condoms = ['No', 'Yes'];
export const ecdlevels = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
export const STRIPE_PUBLISHABLE_KEY =
  'pk_live_51M1vRdLAKzSFK2IUvcEP0YHQonxqdZFp9vo3EsoryWu3RN4rn3oJIg9p0FRQK1yMOIRCrCDa8kuA0G5fsDnGKWJx00ZxpYne97';

// Messages
export const connectMessage = 'Welcome to Blocksto!';
export const connectErrorMessage =
  'Identifiant Blocksto ou Mot de passe Blocksto Incorrect!';
export const sentEmailMessage =
  "L'e-mail d'activation a été envoyé ! Veuillez vérifier votre boîte e-mail.";
export const createNotebookSuccessMessage = 'Bloc-notes créé avec succès !';
export const paymentErrorMessage = 'Error happened in payment!';
export const missingCardNumberMessage = 'Please fill in the card number input!';
export const chooseFileMessage = 'Veuillez choisir le fichier à télécharger !';
export const pinataUploadFailMessage =
  'Échec du téléchargement du fichier Pinata !';
export const createDocumentSuccessMessage = 'Document créé avec succès !';
export const sharedDocumentSuccessMessage = 'Document partagé avec succès !';
export const draftedDocumentSuccessMessage = 'Document rédigé avec succès !';
export const shareDocumentErrorMessage = 'Échec du partage du document !';
export const draftedDocumentErrorMessage = 'Échec du brouillon du document !';
export const sharedNotebookSuccessMessage = 'Notebook partagé avec succès !';
export const shareNotebookErrorMessage = 'Échec de partage du cahier!';
